import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AssetModel} from '../../models/api/asset.model';
import {AppConstants} from '../../app.constants';
import {SortDirection} from '@angular/material/sort';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {TableActionTypeModel} from '../../models/ui/table-action-type.model';
import {EColumnDataType, EColumnSize, EColumnType, IActionClickEvent, ITableColumn} from '@relayter/rubber-duck';
import {CursorArray} from '../../api/api-cursor';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {PaginatorService} from '../paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {TableSortOptions} from '../../api/table-sort-options';
import {NewCursor} from '../../api/new-api-cursor';
import {SearchIndexTableConfig} from '../../classes/table-config/search-index.table-config';
import {AssetApiService} from '../../api/services/asset.api.service';
import {DataFilterUtil, EDataFilterContext} from '../data-filter/data-filter.util';

@Component({
    selector: 'link-asset-view',
    templateUrl: 'link-asset-view.component.html',
    styleUrls: ['link-asset-view.component.scss'],
    providers: [PaginatorService, AdvancedFiltersDataService],
    standalone: false
})
export class LinkAssetViewComponent extends RLTableComponent implements OnInit, OnDestroy {
    @Output() public linkAsset: EventEmitter<AssetModel> = new EventEmitter<AssetModel>();

    public readonly PAGE_INDEX_DEFAULT: number = AppConstants.PAGE_INDEX_DEFAULT;

    public tableId = 'link-asset-table';
    private onDestroySubject = new Subject<void>();

    public tableColumns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'files.thumbnail.url',
            type: EColumnType.THUMBNAIL,
            format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
            clickable: true
        },{
            title: 'Filename',
            key: 'name',
            size: EColumnSize.LARGE,
            type: EColumnType.DEFAULT
        },{
            title: 'Type',
            key: 'type',
            format: (value) => value ? value.replace(new RegExp('^[.]+'), '').toLocaleUpperCase() : '',
            type: EColumnType.DEFAULT
        }];
    public actions: TableActionTypeModel[] = [AppConstants.TABLE_ACTION_TYPES.ADD];
    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public pageSizeOptions =
        AppConstants.PAGE_SIZE_OPTIONS.map((option) => new DropdownItem(`${option}`, option));
    public sortColumn: ITableColumn = {
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
        sortDuplicates: false,
        dataType: EColumnDataType.STRING
    };

    public sortOrder: SortDirection = 'asc';
    public items: AssetModel[];
    private cursorArray: CursorArray;
    public disableNextPage = true;

    public assetsSubscription: Subscription;

    public dataFilterContextAsset = EDataFilterContext.ASSET;
    public dataFilters: DataFilterModel[] = [];
    constructor(private assetService: AssetApiService,
                private paginatorService: PaginatorService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.dataFilters = DataFilterUtil.setupDefaultDataFilters(EDataFilterContext.ASSET);

        this.cursorArray = new CursorArray(this.pageIndex, true);

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;

                this.pageSize = pagination.pageSize;
                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.getAssets();
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(filterValues => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;

                    this.setPageIndex();
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public getAssets(): void {
        if (this.assetsSubscription) this.assetsSubscription.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        // Use new api service, so use NewCursor
        const tableSortOptions = new TableSortOptions(!this.searchValue ? [this.sortColumn] : [], this.sortOrder);
        const newCursor = new NewCursor(tableSortOptions, cursor._id, cursor.value ? [cursor.value] : []);
        this.assetsSubscription = this.assetService.getAssets(this.pageSize, 0, this.searchValue, tableSortOptions, this.filterValues, newCursor)
            .pipe()
            .subscribe({
                next: (res: ARPagedResponseDataModel<AssetModel>) => {
                    this.items = res.items;

                    if (res.items.length > 0) {
                        this.disableNextPage = !res.hasNext;
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * Responds to input on the asset name search field
     */
    public onAssetSearchChanged(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;

            this.setPageIndex();
        }
    }

    /**
     * On table action clicked
     */
    public handleTableRowAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.ADD) {
            const asset = this.items.find((item) => item._id === event.item._id);
            this.linkAsset.emit(asset);
        }
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, false);
        }
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, SearchIndexTableConfig.SEARCH_INDEX_SCORE_COLUMN.sortProperty,
                this.items[this.items.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sortColumn.sortProperty, this.items[this.items.length - 1]);
        }
    }
}
