import {DestroyRef, inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {UserSettingsStorageService} from './user-settings-storage.service';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum ELastViewedItemsContext {
    CAMPAIGN = 'CAMPAIGN',
    PRODUCT = 'PRODUCT',
    ASSET = 'ASSET',
    MASTER_PAGE = 'MASTER_PAGE',
    PROJECT = 'PROJECT'
}
export interface ILastViewedItemsState {
    applied: boolean;
    itemIds: string[];
}

@Injectable({
    providedIn: 'root'
})
export class LastViewedItemsService {
    private static readonly LOCAL_STORAGE_LAST_VIEWED_ITEMS: string = 'last_viewed_items';
    private destroyRef: DestroyRef = inject(DestroyRef);

    private states = Object.keys(ELastViewedItemsContext).reduce((acc, context) => {
        acc[context] = new ReplaySubject<ILastViewedItemsState>(null).pipe(takeUntilDestroyed(this.destroyRef));
        return acc;
    }, {});

    constructor(private userSettingsStorageService: UserSettingsStorageService) {}

    public addItem(context: ELastViewedItemsContext, itemId: string): void {
        this.getState(context)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                const existingItemIds = result?.itemIds || [];
                const itemIds = existingItemIds.filter((i) => i !== itemId);
                itemIds.unshift(itemId);
                itemIds.length = Math.min(itemIds.length, 10); // store maximum 10 ids
                this.states[context].next({itemIds, applied: result.applied});
                this.storeItems(context, itemIds);
            });
    }

    public setApplied(context: ELastViewedItemsContext, applied: boolean): void {
        this.getState(context)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                this.states[context].next({itemIds: result.itemIds, applied});
                this.storeApplied(context, applied);
            });
    }

    /**
     * Get all states for all context from local storage
     * This needed to be called once when first loaded
     */
    public setInitialStates(): void {
        const storageConfig = this.userSettingsStorageService.loadSettings(LastViewedItemsService.LOCAL_STORAGE_LAST_VIEWED_ITEMS);
        Object.values(ELastViewedItemsContext).forEach((context) => {
            const result = storageConfig ? storageConfig[context] : undefined;
            this.states[context].next({itemIds: result?.itemIds, applied: result?.applied});
        });
    }

    private storeItems(context: ELastViewedItemsContext, itemIds: string[]): void {
        let storageConfig = this.userSettingsStorageService.loadSettings(LastViewedItemsService.LOCAL_STORAGE_LAST_VIEWED_ITEMS);
        if (!storageConfig) storageConfig = {};
        if (!storageConfig[context]) storageConfig[context] = {};
        storageConfig[context].itemIds = itemIds;
        this.userSettingsStorageService.storeSettings(LastViewedItemsService.LOCAL_STORAGE_LAST_VIEWED_ITEMS, storageConfig);
    }

    private storeApplied(context: ELastViewedItemsContext, applied: boolean): void { // store items in local storage
        let storageConfig = this.userSettingsStorageService.loadSettings(LastViewedItemsService.LOCAL_STORAGE_LAST_VIEWED_ITEMS);
        if (!storageConfig) storageConfig = {};
        if (!storageConfig[context]) storageConfig[context] = {};
        storageConfig[context].applied = applied;
        this.userSettingsStorageService.storeSettings(LastViewedItemsService.LOCAL_STORAGE_LAST_VIEWED_ITEMS, storageConfig);
    }

    public getState(context: ELastViewedItemsContext): Observable<ILastViewedItemsState> {
        return this.states[context].pipe(distinctUntilChanged());
    }
}
