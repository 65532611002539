import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'emptyValueFormatter',
    standalone: false
})
export class EmptyValuePipe implements PipeTransform {
    private static privatePipe = new EmptyValuePipe();

    public static readonly defaultValues = {
        DELETED_USER: 'Deleted user',
        DELETED_ASSET: 'Deleted asset',
        DELETED_BRIEFING_ITEM: 'Deleted briefing item',
    };

    public transform(value: any, defaultValue = '-'): string {
        return value === null || value === undefined || value === '' || value?.length === 0 ? defaultValue : value;
    }

    public static transform(value: any, defaultValue = '-'): string {
        return EmptyValuePipe.privatePipe.transform(value, defaultValue);
    }
}
