import {Component, DestroyRef, inject, Input, OnChanges, Pipe, PipeTransform, SimpleChanges} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {FormControl, FormGroup} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {PaginatorService} from './paginator.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';

export interface IPaginationEvent {
    pageSize: number;
    pageIndex: number;
}

class PaginatorForm {
    pageSize: FormControl<number>;
}

@Component({
    selector: 'paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    standalone: false
})
export class PaginatorComponent implements OnChanges {
    private destroyRef = inject(DestroyRef);
    @Input() public viewId: string;

    @Input() public pageSizeOptions: DropdownItem<number>[] = AppConstants.PAGE_SIZE_OPTIONS
        .map((option) => new DropdownItem(`${option}`, option));

    @Input() public loading: boolean;
    @Input() public disableNextPage = false;
    @Input() public disablePageSizeOptions: boolean;

    public pageSize: number;
    public pageIndex: number = 0;
    public formGroup: FormGroup<PaginatorForm>;
    private pageSizeSubscription: Subscription;

    constructor(private paginatorService: PaginatorService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.viewId?.firstChange) {
            // set up the formGroup once
            this.formGroup = new FormGroup<PaginatorForm>({
                pageSize: new FormControl({value: null, disabled: this.isPageSizeDisabled()})
            });
        }

        if (changes.viewId) {
            this.paginatorService.getStoredPageSize(this.viewId).subscribe((pageSize: number) => {
                this.pageSize = pageSize;
                this.formGroup.patchValue({pageSize: this.pageSize});
            });

            this.paginatorService.getPageIndex(this.viewId).subscribe((pageIndex) => this.pageIndex = pageIndex);

            if (this.pageSizeSubscription) this.pageSizeSubscription.unsubscribe();

            this.pageSizeSubscription = this.formGroup.controls.pageSize.valueChanges
                .pipe(
                    filter((v) => !!v),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe((pageSize: number) => {
                    if (this.pageSize !== pageSize) {
                        this.pageSize = pageSize;
                        this.paginatorService.setPageIndex(this.viewId, 1); // reset pageIndex to 1 when pageSize changes
                        this.paginatorService.setPageSize(this.viewId, pageSize);
                        this.paginatorService.storePageSize(this.viewId, pageSize);
                    }
                });
        }

        if (this.isPageSizeDisabled()) {
            this.formGroup?.controls.pageSize.disable({emitEvent: false});
        } else {
            this.formGroup?.controls.pageSize.enable({emitEvent: false});
        }

    }

    private isPageSizeDisabled(): boolean {
        return this.loading || this.disablePageSizeOptions;
    }

    public isNextPageDisabled(): boolean {
        return this.loading || this.disableNextPage;
    }

    public onArrowClick(value: 1 | -1): void {
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex + value);
    }
}

@Pipe({
    name: 'numberOfPages',
    standalone: false
})
export class NumberOfPagesPipe implements PipeTransform {
    public transform(totalItems: number, pageSize: number): number | string {
        const numberOfPages = Math.ceil(totalItems / pageSize);
        return isNaN(numberOfPages) ? '...' : numberOfPages;
    }
}
