import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {filter, distinctUntilChanged, startWith} from 'rxjs/operators';
import {
    ActivatedRoute,
    NavigationEnd,
    Router
} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RelayterPageTitleStrategy} from '../../router/relayter-page-title-strategy';

@Component({
    selector: 'rl-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
    standalone: false
})

export class HeaderComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private relayterPageTitleStrategy = inject(RelayterPageTitleStrategy);

    public locationName: string;

    public ngOnInit(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            // when load the page without any event, we need initialize first
            startWith(this.activatedRoute),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            const { data } = this.relayterPageTitleStrategy.getTitleAndData(this.activatedRoute.snapshot);
            this.locationName = data.join(' - ');
        });
    }
}
