import {Pipe, PipeTransform} from '@angular/core';
import {ICardMediaType} from '@relayter/rubber-duck';
import {PublicationModel} from '../models/api/publication.model';

@Pipe({
    name: 'publicationMedia',
    standalone: false
})
export class PublicationMediaPipe implements PipeTransform {

    public transform(publication: PublicationModel): ICardMediaType {
        return publication.getThumbnail();
    }
}
