import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AssignToPackageService} from '../assign-to-package.service';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {Subscription} from 'rxjs';
import {PackageModel} from '../../../../../../../../models/api/package.model';
import {AppConstants} from '../../../../../../../../app.constants';
import {PackagesService} from '../../../../../../../../api/services/packages.service';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';
import {DataFieldModel} from '../../../../../../../../models/api/data-field.model';
import {EDataFieldFormatter, EDataFieldTypes} from '../../../../../../../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PackageSetupDataFieldModel} from '../../../../../../../../models/api/package-setup.model';

class AssignToPackageMaterialDataForm {
    [key: string]: FormControl<string|number>
}

@Component({
    selector: 'assign-to-package-material-data-form-component',
    templateUrl: 'assign-to-package-material-data-form.component.html',
    styleUrls: ['assign-to-package-material-data-form.component.scss'],
    standalone: false
})

export class AssignToPackageMaterialDataFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public readonly permissions = AppConstants.PERMISSIONS;
    public static readonly CONTROL_PREFIX = 'campaignPackage_';

    @Input() public form: FormGroup;
    @Input() public campaignId: string;

    public campaignPackage: PackageModel;
    public dataFields: PackageSetupDataFieldModel[];

    public formGroup: FormGroup;
    public controlName: string;

    public campaignPackageSubscription: Subscription;

    constructor(private assignToPackageService: AssignToPackageService,
                private packagesService: PackagesService,
                private userIsAllowedToPipe: UserIsAllowedToPipe) {
    }

    public ngOnInit(): void {
        this.assignToPackageService.campaignPackage$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (campaignPackage) => {
                this.campaignPackage = campaignPackage;
                this.getCampaignPackageDetails();
            },
            error: Toaster.handleApiError});
    }

    private initForm(): void {
        this.controlName = this.campaignPackage ? `${AssignToPackageMaterialDataFormComponent.CONTROL_PREFIX}${this.campaignPackage._id}` : null;
        // remove control if campaign package is changed
        Object.keys(this.form.controls).forEach(key => {
            if (key.startsWith(AssignToPackageMaterialDataFormComponent.CONTROL_PREFIX) && key !== this.controlName) {
                this.form.removeControl(key);
            }
        });

        if (!this.controlName) {
            return;
        }

        if (!this.form.contains(this.controlName)) {
            this.formGroup = new FormGroup<AssignToPackageMaterialDataForm>({});
            this.form.addControl(this.controlName, this.formGroup);

            if (this.dataFields?.length) {
                for (const dataField of this.dataFields) {
                    this.formGroup.addControl(dataField.fieldName, new FormControl());
                }
            }
        } else {
            this.formGroup = this.form.get(this.controlName) as FormGroup;
        }
    }

    /**
     * Get campaign package details from the api
     */
    private getCampaignPackageDetails(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_DETAILS)) {
            return;
        }

        if (this.campaignPackageSubscription) {
            this.campaignPackageSubscription.unsubscribe();
        }

        if (!this.campaignId || !this.campaignPackage) {
            this.dataFields = [];
            this.initForm();
            return;
        }

        this.campaignPackageSubscription =
            this.packagesService.getPackageForCampaign(this.campaignId, this.campaignPackage._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (campaignPackage: PackageModel) => {
                        this.dataFields = campaignPackage.packageSetup.materialDataFields as DataFieldModel[];
                        this.dataFields.forEach((dataField: DataFieldModel) => {
                            if (dataField.dataType.type === EDataFieldTypes.NUMBER) {
                                dataField.dataType.formatter = EDataFieldFormatter.NONE;
                            }
                        })
                        this.initForm();
                    },
                    error: Toaster.handleApiError
                });
    }
}
