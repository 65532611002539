import { Pipe, PipeTransform } from '@angular/core';
import {AppConstants} from '../app.constants';

@Pipe({
    name: 'contentTypeDisplayPipe',
    standalone: false
})
export class ContentTypeDisplayPipe implements PipeTransform {

    public transform(contentType: string): string {
        switch (contentType) {
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM:
                return 'Briefing item';
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET:
                return 'Asset';
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.LAYOUT_NOTE:
                return 'Layout note';
            default:
                throw new Error(`ContentTypeDisplayPipe: Unhandled contentType ${contentType}`);
        }
    }

}
