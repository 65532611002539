import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {InformationDetailModel} from './information-detail.model';
import {ProductModel} from '../../models/api/product.model';
import {AssetModel} from '../../models/api/asset.model';
import {DataFieldModel} from '../../models/api/data-field.model';

@Component({
    selector: 'information-details-view-component',
    templateUrl: 'information-details-view.component.html',
    styleUrls: ['information-details-view.component.scss'],
    standalone: false
})
export class InformationDetailsViewComponent implements OnChanges {

    public readonly dateFormats = RLDatePipe.dateFormats;
    public informationDetails: InformationDetailModel[] = [];

    @Input() public title: string;
    @Input() public item: ProductModel|AssetModel;
    @Input() public dataFields: DataFieldModel[];
    @Input() public sidebar = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            this.informationDetails = InformationDetailModel.createInformationDetails(this.item, this.dataFields);
        }
    }
}
