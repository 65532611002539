import {autoserialize} from 'cerialize';

export class WorkflowConfigurationFilterOptionModel {
    @autoserialize public _id: string;
    @autoserialize public displayName: string;
    @autoserialize public key: string;

    constructor(title: string, value: string) {
        this.displayName = title;
        this.key = value;
    }

    get title(): string {
        return this.displayName;
    }

    get value(): string {
        return this.key;
    }

}
