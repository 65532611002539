import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable, ReplaySubject} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {DataFieldModel} from '../../models/api/data-field.model';
import {EDataFieldCollectionName} from '../../app.enums';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiService} from './api.service';
import {WorkflowConfigurationFilterOptionModel} from '../../models/api/workflow-configuration-filter-option.model';
import {IDataFieldFilterOptions} from './data-fields.service';
import {TableSortOptions} from '../table-sort-options';
import {NewCursor} from '../new-api-cursor';
import {QueryParams} from '../../classes/query-params';

@Injectable({
    providedIn: 'root'
})
export class DataFieldsApiService extends ApiService<DataFieldModel> {
    // TODO: Move all api calls from the datafields.service.ts to here
    private campaignItemDataFields$: ReplaySubject<DataFieldModel[]>;

    constructor() {
        super([ApiConstants.API_GROUP_DATA_FIELDS], DataFieldModel);
    }

    /**
     * Get all the campaignItem data fields using a Subject to prevent doing unnecessary requests.
     * @return {ReplaySubject<DataFieldModel[]>}
     */
    public getCampaignItemDataFields(): ReplaySubject<DataFieldModel[]> {
        if (this.campaignItemDataFields$) {
            return this.campaignItemDataFields$;
        }
        this.campaignItemDataFields$ = new ReplaySubject<DataFieldModel[]>(1);
        this.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM).subscribe((dataFields: DataFieldModel[]) => {
            this.campaignItemDataFields$.next(dataFields);
        });
        return this.campaignItemDataFields$;
    }

    public getDataFields(collectionName: EDataFieldCollectionName, limit?: number, offset?: number,
                         tableSortOptions?: TableSortOptions, cursor?: NewCursor): Observable<ARPagedResponseDataModel<DataFieldModel>> {
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, tableSortOptions, cursor);
        request.additionalQueryParams.addParam('collectionName', collectionName);

        return this.apiRequestService.find(request);
    }

    public getAllDataFields(collectionName: EDataFieldCollectionName): Observable<DataFieldModel[]> {
        return this.findAll(new QueryParams().addParam('collectionName', collectionName));
    }

    public getDataFieldValues(dataFieldId: string,
                              filterOptions: IDataFieldFilterOptions = {},
                              limit?: number,
                              offset?: number,
                              search?: string,
                              variantKey?: string): Observable<ARPagedResponseDataModel<WorkflowConfigurationFilterOptionModel>> {

        const request = new ApiPagedRequest([...this.groups, dataFieldId, ApiConstants.API_METHOD_VALUES],
            WorkflowConfigurationFilterOptionModel, limit, offset, null, null, search, filterOptions);

        request.additionalQueryParams.addParam('includeTotals', false)
            .addParam('variantKey', variantKey);
        return this.apiRequestService.find(request);
    }
}
