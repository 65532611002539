import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'attachment-progress-bar',
    templateUrl: './attachment-progress-bar.component.html',
    styleUrls: ['./attachment-progress-bar.component.scss'],
    standalone: false
})
export class AttachmentProgressBarComponent {
    @Input() public icon: string = 'nucicon_paperclip';
    @Input() public text: string = '';
    @Input() public progress: number;
    @Output() public onDelete: EventEmitter<null> = new EventEmitter<null>();
  constructor() { }

  public onDeleteClicked(): void {
      this.onDelete.emit();
  }

}
