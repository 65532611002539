import {Component, EmbeddedViewRef, ViewContainerRef} from '@angular/core';

@Component({
    selector: 'standard-full-screen-container',
    templateUrl: './full-screen-container.component.html',
    styleUrls: ['./full-screen-container.component.scss'],
    standalone: false
})
export class FullScreenContainerComponent {

    constructor(private vcf: ViewContainerRef) {
    }

    public setView(view: EmbeddedViewRef<any>) {
        this.vcf.insert(view);
    }

}
