import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../../../classes/toaster.class';
import {
    WorkflowConfigurationActionModel,
    EItemActionName,
    ETransitionTriggerActionName,
    EWorkflowConfigurationActionType
} from '../../../../../../models/api/workflow-configuration-action.model';
import {StickyNoteModel} from '../../../../../../models/api/sticky-note.model';
import {CampaignItemsService} from '../../../../../../api/services/campaign-items.service';
import {BUTTON_TYPE, NucDialogConfigModel} from '@relayter/rubber-duck';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {CustomWorkflowPreviewDataService} from './custom-workflow-preview-data.service';
import {forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';
import {
    PublicationItemSelectionService,
    UpdatedWorkflowLayoutItemData
} from '../custom-workflow-item-selection/publication-item-selection.service';
import {UserService} from '../../../../../../api/services/users.service';
import {UserModel} from '../../../../../../models/api/user.model';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {StickyNotesDataService} from './preview-sticky-notes-sidebar/sticky-notes-data.service';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../../../../../models/api/data-field.model';
import {WorkflowLayoutItem} from '../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {
    CustomWorkflowItemSelectionComponent,
    EItemSelectionType
} from '../custom-workflow-item-selection/custom-workflow-item-selection.component';
import {PublicationItemsService} from '../../../../../../api/services/publication-items.service';
import {
    CustomWorkflowBriefingChangesDataService
} from './preview-briefing-changes/custom-workflow-briefing-changes-data.service';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {
    CampaignItemInlineFormComponent
} from '../../../../../../forms/campaign-item-form/campaign-item-inline-form/campaign-item-inline-form.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {EFullscreenEvents, EFullscreenModalEvents} from '../../../../../../directives/fullscreen-form-directive/fullscreen-form.directive';
import {EWorkflowConfigurationActionOptionName} from '../../../../../../models/api/workflow-configuration-option.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AppConstants} from '../../../../../../app.constants';
import {WorkflowFiltersUtil} from '../workflow-filters.util';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {EPublicationItemContext} from '../../../../../../api/services/publications.service';
import {IEditItem} from '../custom-workflow.service';

export enum EComponentActions {
    ShowStickyNotes = 'SHOW_STICKY_NOTES',
    ShowBriefingChanges = 'SHOW_BRIEFING_CHANGES' // this is for briefing changes sidebar
}

interface IAllowedActions {
    showStickyNotes: WorkflowConfigurationActionModel;
    uploadItemFilesAction: WorkflowConfigurationActionModel;
}

export interface IShowStickyNotesActionOptions {
    display: { name: EWorkflowConfigurationActionOptionName.DISPLAY; value: string[] };
}

export enum ERightPanel {
    STICKY = 'STICKY',
    ITEM_DETAILS = 'ITEM_DETAILS',
    BRIEFING_CHANGES = 'BRIEFING_CHANGES'
}

@Component({
    selector: 'rl-custom-workflow-preview-component',
    templateUrl: './custom-workflow-preview.component.html',
    styleUrls: ['./custom-workflow-preview.component.scss'],
    providers: [CustomWorkflowPreviewDataService, StickyNotesDataService, CustomWorkflowBriefingChangesDataService],
    standalone: false
})
export class CustomWorkflowPreviewComponent extends CustomWorkflowBaseComponent {
    public currentItemSelectionType = EItemSelectionType.ITEM_SELECTION;

    public campaignItemDataFields: DataFieldModel[] = [];
    public publicationItems: PublicationItemModel[];
    public workflowLayoutItem: WorkflowLayoutItem;
    public loading = false;

    public showBriefingChangesAction: WorkflowConfigurationActionModel;

    public stickyNotes: StickyNoteModel[];
    public allowedComponentActions: IAllowedActions;

    private loggedInUser: UserModel;
    public disableAction: Record<string, boolean>;
    public isHorizontal = false;

    public selectionOpen = true;
    public layoutOpen = false;

    private selectedStickyNote: StickyNoteModel;

    public ERightPanel = ERightPanel;
    public rightPanelSubject = new Subject<ERightPanel>();
    public selectedRightPanel: ERightPanel;
    public showBriefingForm: boolean = false;

    @ViewChild(CampaignItemInlineFormComponent) public editBriefingForm: CampaignItemInlineFormComponent;
    @ViewChild(CustomWorkflowItemSelectionComponent, {static: true}) public itemSelection: CustomWorkflowItemSelectionComponent;

    public editCampaignItem: CampaignItemModel;
    public editCampaignItemPubItemId: string;
    public editCampaignItemEvents: Subject<EFullscreenEvents> = new Subject<EFullscreenEvents>();
    public fullscreenModalEventSubject: Subject<EFullscreenModalEvents> = new Subject<EFullscreenModalEvents>();
    public isFullscreenSubject: Subject<boolean> = new Subject<boolean>();
    public campaignDetailsSubscription: Subscription;

    public isFullscreen: boolean;
    public pinButtonEnabled: boolean = true;

    private getStickyNotesSubscription: Subscription;

    @Input() headerVisible: boolean;
    @Output() public handleHeader = new EventEmitter<void>();

    constructor(private userService: UserService,
                private campaignItemsService: CampaignItemsService,
                private previewDataService: CustomWorkflowPreviewDataService,
                public stickyNotesDataService: StickyNotesDataService,
                private dataFieldService: DataFieldsApiService,
                public publicationItemSelectionService: PublicationItemSelectionService,
                public customWorkflowBriefingChangesDataService: CustomWorkflowBriefingChangesDataService,
                public publicationItemsService: PublicationItemsService,
                public userSettingsStorageService: UserSettingsStorageService) {
        super();
    }

    protected setupData(): void {
        this.setRightPanel(null);
        this.stickyNotesDataService.setSelectedStickyNote(null);
        this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange(null);
        this.getCampaignItemDataFields();
        this.getAvailableStickyNotesInStep();
    }

    protected refreshData(transitionItemId?: string): void {
        this.publicationItemSelectionService
            .setUpdatedWorkflowLayoutItem(new UpdatedWorkflowLayoutItemData(this.workflowLayoutItem, transitionItemId));
        if (transitionItemId) {
            this.stickyNotesDataService.announceRefreshStickyNotes();
            this.stickyNotesDataService.setSelectedStickyNote(null); // closes the sticky detail view
            this.customWorkflowBriefingChangesDataService.announceRefreshBriefingChanges();
            this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange(null);
            if (!this.publicationItems || this.publicationItems?.find((pubItem) => {
                return this.editCampaignItemPubItemId === pubItem._id;
            })) {
                this.editCampaignItem = null;
                this.editCampaignItemPubItemId = null;
                this.showBriefingForm = false;
            }
            this.getAvailableStickyNotesInStep();
        } else {
            const publicationItemRequests: Observable<PublicationItemModel>[] = [];
            if (this.publicationItems) {
                for (const publicationItem of this.publicationItems) {
                    publicationItemRequests.push(this.publicationsService.getPublicationItem(
                        this.publication._id, publicationItem._id, EPublicationItemContext.PREVIEW));
                }
            }
            forkJoin(publicationItemRequests)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((publicationItems: PublicationItemModel[]) => {
                    this.updatePublicationItems(publicationItems);
                })

            if (this.publicationItems) {
                this.customWorkflowService.setItemActionUpdate(this.publicationItems.map(item => item._id));
            }
        }
    }

    public handleAction(action: WorkflowConfigurationActionModel, tableItems: PublicationItemModel[] | CampaignItemModel[],
                        origin?: CdkOverlayOrigin, data?: Record<string, any>): void {
        if (action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
            if (Array.isArray(tableItems) && tableItems.find((pubItem) => {
                return pubItem._id === this.editCampaignItemPubItemId;
            })) {
                this.customWorkflowService.canClose().pipe(
                    take(1),
                    takeUntilDestroyed(this.destroyRef)
                )
                    .subscribe((canClose: boolean) => {
                        if (canClose) {
                            super.handleAction(action, tableItems, origin, data);
                        }
                    });
            } else {
                super.handleAction(action, tableItems, origin, data);
            }
        } else {
            super.handleAction(action, tableItems, origin, data);
        }
    }

    public setRightPanel(item: ERightPanel): void {
        this.rightPanelSubject.next(item);
        if (this.selectedRightPanel !== ERightPanel.STICKY) this.stickyNotesDataService.setSelectedStickyNote(null);
        if (this.selectedRightPanel !== ERightPanel.BRIEFING_CHANGES)
            this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange(null);
    }

    public goToLinkedBriefingItem(): void {
        if (this.publicationItems[0].campaignItems.length) {
            this.customWorkflowService.setEditCampaignItemId({
                campaignItemId: this.publicationItems[0].campaignItems[0]._id,
                pubItemId: this.publicationItems[0].campaignItems[0]._id
            });
        } else {
            this.customWorkflowService.setEditCampaignItemId(null);
        }
    }

    public initComponent(): void {
        super.initComponent();

        this.loggedInUser = this.userService.getLoggedInUser();
        this.updateDisabledActions();

        this.isFullscreen = this.userSettingsStorageService.loadSettings('campaignItemWorkflowForm') !== 'false';
        this.isFullscreenSubject.subscribe((result) => this.isFullscreen = result)

        this.fullscreenModalEventSubject.subscribe((result) => {
            if (result !== EFullscreenModalEvents.FULLSCREEN_TOGGLE) {
                this.closeEditBriefing(null);
            }
        });

        this.publicationItemSelectionService.selectedWorkflowLayoutItem$.pipe(
            distinctUntilChanged((prev, next) => prev?.title === next?.title),
            tap(() => {
                this.loading = true;
                this.updateDisabledActions();
            }),
            switchMap((workflowLayoutItem) => {
                this.workflowLayoutItem = workflowLayoutItem;
                const publicationItemRequests: Observable<PublicationItemModel>[] = [];
                if (this.workflowLayoutItem) for (const publicationItem of this.workflowLayoutItem.publicationItems) {
                    publicationItemRequests.push(this.publicationsService.getPublicationItem(
                        this.publication._id, publicationItem._id, EPublicationItemContext.PREVIEW));
                }
                return publicationItemRequests.length > 0
                    ? forkJoin((publicationItemRequests))
                    : of([]);
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((publicationItems) => {
            this.updatePublicationItems(publicationItems);
            if (this.editCampaignItem &&
                this.editBriefingForm.isFormPinned() &&
                publicationItems.length
                && this.pinButtonEnabled
            ) {
                this.goToLinkedBriefingItem();
            }
        });

        this.rightPanelSubject.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((item: ERightPanel) => this.selectedRightPanel = item);

        this.customWorkflowService.activeComponent$.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {

                // if component is changed always close the edit briefing form.
                // this prevents the form from staying open if you did not edit the form and switched to a step that
                // also has the preview component as first component
                this.showBriefingForm = false;
                this.editCampaignItemPubItemId = null;
                this.editCampaignItem = null;
            });

        this.stickyNotesDataService.selectedStickyNote$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((stickyNote) => {
                this.selectedStickyNote = stickyNote;
                if (this.selectedStickyNote) this.setRightPanel(ERightPanel.STICKY);
            });

        this.customWorkflowBriefingChangesDataService.selectedBriefingChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((briefingChange) => {
                if (briefingChange) this.setRightPanel(ERightPanel.BRIEFING_CHANGES);
            });

        this.stickyNotesDataService.refreshStickyNotes$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getStickyNotes());

        this.customWorkflowBriefingChangesDataService.refreshBriefingChanges$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getBriefingChanges());

        this.publicationItemSelectionService.itemSelectionType$
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((itemSelectionType) => {
                this.currentItemSelectionType = itemSelectionType;
                if (this.selectionOpen || this.layoutOpen) {
                    this.selectionOpen = this.currentItemSelectionType === EItemSelectionType.ITEM_SELECTION;
                    this.layoutOpen = this.currentItemSelectionType === EItemSelectionType.LAYOUT_SELECTION;
                }
            });

        this.customWorkflowService.editCampaignItemId$.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result: IEditItem) => {
                if (result?.pinButtonEnabled !== undefined) this.pinButtonEnabled = !!result.pinButtonEnabled;
                if (result?.campaignItemId) {
                    if (result.campaignItemId === this.editCampaignItem?._id && result.forceToggle) {
                        this.editBriefingForm?.beforeCloseForm();
                    } else {
                        if (this.editBriefingForm?.isFormTouched()) {
                            const dialogConfig = new NucDialogConfigModel('Edit briefing item.', 'There are unsaved changes that will be lost.');
                            const dialog = this.dialogService.openDialog(dialogConfig);
                            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
                            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                                dialog.close();
                                this.editCampaignItem = null;
                                this.showBriefingForm = true;
                                this.getCampaignItemDetailsForEditing(result.campaignItemId);
                                this.editCampaignItemPubItemId = result.pubItemId;
                            });
                        } else {
                            this.editCampaignItem = null;
                            this.showBriefingForm = true;
                            this.getCampaignItemDetailsForEditing(result.campaignItemId);
                            this.editCampaignItemPubItemId = result.pubItemId;
                        }
                    }
                } else {
                    this.editCampaignItem = null;
                    this.getCampaignItemDetailsForEditing(null);
                    this.editCampaignItemPubItemId = null;
                }
            });

        this.customWorkflowService.updateItemAction$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => this.refreshData());
    }

    protected updateActions(): void {
        super.updateActions();

        this.iterableActions = this.iterableActions
            .filter((action) => !(action.name === EItemActionName.DOWNLOAD || action.name === ETransitionTriggerActionName.UPLOAD_ITEM_FILES));

        this.previewDataService.setActions(this.allowedActions);
        this.showBriefingChangesAction = this.findActionByName(EComponentActions.ShowBriefingChanges);

        this.allowedComponentActions = {
            showStickyNotes: this.findActionByName(EComponentActions.ShowStickyNotes),
            uploadItemFilesAction: this.findActionByName(ETransitionTriggerActionName.UPLOAD_ITEM_FILES)
        };

        this.updateDisabledActions();
    }

    private getAvailableStickyNotesInStep(): void {
        if (this.getStickyNotesSubscription) this.getStickyNotesSubscription.unsubscribe(); // unsubscribe if a previous request is still pending
        let filters = WorkflowFiltersUtil.fromActiveFilters(this.activeFilters);
        if (this.allowedComponentActions?.showStickyNotes) filters = filters.concat(this.allowedComponentActions.showStickyNotes.fromStatusFilter);
        if (this.activeVariant) filters.push({queryParam: 'variant', value: this.activeVariant._id});

        this.getStickyNotesSubscription = this.publicationsService.getStickyNotesForPublication(this.publication._id,
            this.step?._id,
            filters,
            null,
            AppConstants.PAGE_SIZE_MAX,
            0,
            null,
            null,
            null)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((results: ARPagedResponseDataModel<StickyNoteModel>) => {
            let totalItems: string = results.total.toString();
            if (results.hasNext) totalItems = totalItems + '+';
            this.stickyNotesDataService.setTotalItemsInList(totalItems)
        })
    }

    private updatePublicationItems(publicationItems: PublicationItemModel[]): void {
        this.loading = false;
        this.publicationItems = publicationItems;
        if (this.publicationItems?.length > 0) {
            const size = this.publicationItems.reduce((totalSize, item) => {
                if (item.template?.staticContent) {
                    totalSize.width += item.template.staticContent.pageSize.width;
                    totalSize.height = Math.max(totalSize.height, item.template.staticContent.pageSize.height);
                }
                return totalSize;
            }, {width: 0, height: 0});

            this.isHorizontal = size.width > size.height;
        } else {
            this.setRightPanel(null);
        }

        this.previewDataService.setPublicationItems(this.publicationItems);

        this.updateDisabledActions();
        this.getStickyNotes();
        this.getBriefingChanges();
        this.customWorkflowBriefingChangesDataService.setSelectedBriefingChange(null);
    }

    private updateDisabledActions(): void {
        this.disableAction = {};
        this.allowedActions?.forEach(action => {
            this.disableAction[action.name] = this.loading || !this.publicationItems || this.publicationItems.length === 0;
            if (action.name === EItemActionName.SIGN_OFF) {
                // Sign off disabled in layout view, when there is more than 1 item in the workflow layout
                const disableSignOff = this.publicationItems?.length === 1 ? !!this.publicationItems[0].signOff?.signOffUsers
                    .find(signOffUser => signOffUser.user._id === this.loggedInUser._id) : true;
                this.disableAction[action.name] = this.disableAction[action.name] || disableSignOff;
            }
        });
    }

    private getBriefingChanges(): void {
        if (!this.publicationItems || this.publicationItems.length === 0 || !this.showBriefingChangesAction) {
            return;
        }

        // Get all briefing changes
        const requests: Observable<ARPagedResponseDataModel<void>>[] = [];
        for (const publicationItem of this.publicationItems) {
            const lastFileVersion = publicationItem.getVariantFilesRevisions(this.activeVariant?._id) ? publicationItem
                    .getVariantFilesRevisions(this.activeVariant?._id)[publicationItem.getVariantFilesRevisions(this.activeVariant?._id).length - 1]
                : null;
            if (lastFileVersion) {
                requests.push( // prepare the call
                    this.publicationItemsService.getContentBriefingChanges(this.publication._id, publicationItem._id, lastFileVersion.date)
                        .pipe(map((result) => {
                            publicationItem.changedCampaignItems = result.items;
                            return null;
                        }))
                );
            }
        }

        forkJoin(requests) // do the api call
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                error: Toaster.handleApiError
            });
    }

    /**
     * Gets sticky notes for the currently selected workflow layout item if allowed, otherwise refreshes the sticky notes view with null stickies
     */
    private getStickyNotes(): void {
        this.stickyNotes = null;
        this.previewDataService.setStickyNotes(this.stickyNotes);

        if (!this.publicationItems || this.publicationItems.length === 0 || !this.allowedComponentActions?.showStickyNotes) {
            return;
        }

        const filters = [];
        filters.push(...this.allowedComponentActions.showStickyNotes?.fromStatusFilter);

        if (this.activeVariant) {
            filters.push({queryParam: 'variant', value: this.activeVariant._id});
        }

        // Get all sticky notes for publication items in this workflow layout item
        const requests: Observable<ARPagedResponseDataModel<StickyNoteModel>>[] = [];
        for (const publicationItem of this.publicationItems) {
            requests.push(this.publicationsService.getStickyNotesForPublicationItem(this.publication._id, publicationItem._id, filters));
        }

        forkJoin(requests)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results: ARPagedResponseDataModel<StickyNoteModel>[]) => {
                    this.stickyNotes = results.reduce((items, response) =>
                        items.concat(...response.items), []);
                    this.previewDataService.setStickyNotes(this.stickyNotes);
                },
                error: Toaster.handleApiError
            });
    }

    public onClickViewSelection(): void {
        this.selectionOpen = !this.selectionOpen;
        this.layoutOpen = false;
        if (this.selectionOpen) {
            this.publicationItemSelectionService.setItemSelectionType(EItemSelectionType.ITEM_SELECTION);
        }
    }

    public onClickViewLayout(): void {
        this.layoutOpen = !this.layoutOpen;
        this.selectionOpen = false;
        if (this.layoutOpen) {
            this.publicationItemSelectionService.setItemSelectionType(EItemSelectionType.LAYOUT_SELECTION);
        }
    }

    public onToggleRightPanel(panel: ERightPanel): void {
        const rightPanelToSelect = this.selectedRightPanel === panel ? null : panel;
        if (rightPanelToSelect !== ERightPanel.STICKY) {
            this.getAvailableStickyNotesInStep();
        }
        this.setRightPanel(rightPanelToSelect);
    }

    private getCampaignItemDataFields(): void {
        this.dataFieldService.getCampaignItemDataFields()
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (dataFields) => this.campaignItemDataFields = dataFields,
                error: Toaster.handleApiError
            });
    }

    private getCampaignItemDetailsForEditing(itemId: string): void {
        this.editCampaignItem = null;
        this.campaignDetailsSubscription = this.campaignItemsService.getDetails(this.campaign._id, itemId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (campaignItemDetails: CampaignItemModel) => {
                    this.editCampaignItem = campaignItemDetails;
                    this.customWorkflowService.setEditCampaignItemDetails(this.editCampaignItem);
                },
                error: Toaster.handleApiError
            });
    }

    public closeEditBriefing(result: CampaignItemModel | boolean): void {
        if (result instanceof CampaignItemModel && result) {
            this.stickyNotesDataService.announceUpdatedLinkedCampaignItem(result);
            this.customWorkflowBriefingChangesDataService.announceRefreshBriefingChanges();
        }
        if (typeof result === 'boolean' && result) {
            this.showBriefingForm = false;
            this.editCampaignItem = null;
            this.editCampaignItemPubItemId = null;
        }
        if (!this.pinButtonEnabled || (!this.editBriefingForm?.isFormPinned() || this.isFullscreen)) {
            this.showBriefingForm = false;
            this.customWorkflowService.setEditCampaignItemId(null);
            this.editCampaignItemEvents.next(EFullscreenEvents.CLOSE);
        }
    }

    protected requestClose(): void {
        if (this.editBriefingForm?.isFormTouched()) {
            const dialogConfig = new NucDialogConfigModel('Edit briefing item.', 'There are unsaved changes that will be lost.');
            const dialog = this.dialogService.openDialog(dialogConfig);
            dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
                dialog.close();
                this.closeSubscription.next(false);
            });
            dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
                dialog.close();
                this.closeEditBriefing(null);
                this.closeSubscription.next(true);
            });
        } else {
            this.closeSubscription.next(true);
        }
    }

    protected activeVariantChanged(): void {
        this.stickyNotesDataService.setSelectedStickyNote(null);
        this.getStickyNotes();
        this.getAvailableStickyNotesInStep();
    }
}
