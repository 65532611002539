import {AfterViewInit, Component, inject, Inject, OnInit, ViewChild} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    ESelectionMode,
    FullModalActionModel,
    FullModalService,
    ITableColumn,
    NUC_FULL_MODAL_DATA,
    TabBarComponent
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {forkJoin} from 'rxjs';
import {EDataFieldCollectionName, EDataFieldTypes, EFormStatus} from '../../app.enums';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {PaginatorService} from '../paginator/paginator.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {Toaster} from '../../classes/toaster.class';
import {DataFieldModel} from '../../models/api/data-field.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {BriefingTableData} from '../briefing-table/briefing-table.data';
import {CampaignService, ECampaignJobTypes, ICopyMasterBriefingToCampaignJobData} from '../../api/services/campaigns.service';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {FormArray, FormControl, FormControlStatus, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MasterBriefingDataProvider} from './master-briefing.data-provider';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';
import {EDataFilterContext} from '../data-filter/data-filter.util';

export interface IImportFromMasterBriefingComponentData {
    campaignId: string;
}
@Component({
    selector: 'import-from-master-briefing-component',
    templateUrl: './import-from-master-briefing.component.html',
    styleUrls: ['./import-from-master-briefing.component.scss'],
    providers: [
        PaginatorService,
        AdvancedFiltersDataService
    ],
    standalone: false
})
export class ImportFromMasterBriefingComponent extends RLTableComponent implements OnInit, AfterViewInit {
    @ViewChild(TabBarComponent) public tabBar: TabBarComponent;

    public readonly campaignId: string;
    public readonly tableId = 'import-from-master-briefing-table';
    public readonly ESelectionMode = ESelectionMode;

    public columns: ITableColumn[];

    public dataFilterContextCampaignItem = EDataFilterContext.CAMPAIGN_ITEM;
    public dataFilters: DataFilterModel[] = [];
    public requestOptions: IDataFieldFilterOptions;

    private dataFieldComponentUtil: DataFieldsComponentUtil;
    public dataFields: DataFieldModel[];

    public variants: VariantModel[];
    public selectedVariantKey: string;
    public variantEnabled: boolean;

    private importButton: ButtonConfig;

    public autocompleteValues: IDropdownItem[] = [];

    public tabs: TabBarItemModel[] = [new TabBarItemModel('Selection', 0), new TabBarItemModel('Data Field options', 1)]
    public activeTab: TabBarItemModel = this.tabs[0] as TabBarItemModel;
    private prevNextModalAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Next'));

    public dataFieldCopyOptionsForm = new FormArray([], [this.optionsValidator()]);
    public masterBriefingDataProvider = new MasterBriefingDataProvider(this.tableSortOptions);

    private optionsValidator(): ValidatorFn {
        return (controls: any): ValidationErrors | null => {
            const rawValues = controls.getRawValue();
            rawValues.forEach((option: Record<string, any>, index: number) => {
                const duplicateDataFields = !!rawValues.find((value: Record<string, any>, i: number) => {
                    return value.dataField && option.dataField && value.dataField._id === option.dataField._id && index !== i;
                });
                const control = controls.at(index);

                if (duplicateDataFields) {
                    control.setErrors({duplicateDataFields: {value: 'Duplicate field not allowed.'}});
                } else {
                    control.setErrors(null);
                }
            })
            return;
        };
    }

    constructor(private paginatorService: PaginatorService,
                private dataFieldsApiService: DataFieldsApiService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                private campaignItemsApiService: CampaignItemsApiService,
                private variantService: VariantService,
                userSettingsStorageService: UserSettingsStorageService,
                private fullModalService: FullModalService,
                private campaignService: CampaignService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IImportFromMasterBriefingComponentData) {
        super(userSettingsStorageService);
        this.dataFieldComponentUtil = inject(DataFieldsComponentUtil);
        this.campaignId = this.modalData.campaignId;
    }

    public ngAfterViewInit(): void {
        this.tabBar.activeTabChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.prevNextModalAction.button.text = this.tabBar.activeTabIndex === 0 ? 'Next' : 'Previous';
        })
    }

    public ngOnInit(): void {
        this.setModalActions();
        this.getVariantsAndDataFields();
        this.listenToAdvancedFilter();
        this.listenToPagination();

        this.requestOptions = {campaignId: 'null'};

        this.masterBriefingDataProvider.selectionChanged$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.importButton.disabled = !this.masterBriefingDataProvider.hasValue || this.dataFieldCopyOptionsForm.status !== EFormStatus.VALID;
            });

        this.dataFieldCopyOptionsForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((newStatus: FormControlStatus) => {
            this.importButton.disabled = newStatus !== EFormStatus.VALID || !this.masterBriefingDataProvider.hasValue;
        });
    }

    private setModalActions(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        cancelAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullModalService.close(false, true));
        this.importButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Import', false, false, true);
        const importAction = new FullModalActionModel(this.importButton);
        importAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.scheduleImportJob())

        this.prevNextModalAction.observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.activeTab = this.activeTab === this.tabs[0] ? this.tabs[1] : this.tabs[0]);
        this.fullModalService.setModalActions([cancelAction, this.prevNextModalAction, importAction]);
    }

    private getVariantsAndDataFields(): void {
        forkJoin([
            this.variantService.getVariants(this.campaignId),
            this.dataFieldsApiService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantData, dataFields]) => {
                    this.variants = variantData.items;
                    this.variantEnabled = this.variants.length && dataFields.some(field => field.enableVariants);
                    if (this.variants?.length > 0) {
                        this.selectedVariantKey = this.variants[0].key;
                    }
                    this.dataFields = dataFields;
                    this.updateColumns();
                },
                error: Toaster.handleApiError
            });
    }

    private listenToAdvancedFilter(): void {
        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (filterValues) => {
                    if (this.masterBriefingDataProvider.filterValues !== filterValues) {
                        this.masterBriefingDataProvider.filterValues = filterValues;
                        this.setPageIndex();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.masterBriefingDataProvider.pageIndex = pagination.pageIndex;
                this.masterBriefingDataProvider.pageSize = pagination.pageSize;

                if (this.masterBriefingDataProvider.pageIndex === 1) {
                    this.masterBriefingDataProvider.resetCursorArray();
                }
                this.masterBriefingDataProvider.retrieveData();
            });
    }

    private updateColumns(): void {
        this.columns = [
            ...BriefingTableData.PermanentColumns,
            ...this.dataFieldComponentUtil.getDataFieldsColumnSelection(this.dataFields, false, this.selectedVariantKey),
            ...BriefingTableData.DefaultColumns
        ];

        // Update sort columns (variant could be changed)
        if (this.tableSortOptions.columns.find(column => !!column.variantKey)) {
            this.tableSortOptions.columns = this.tableSortOptions.columns
                .map(sortColumn => this.columns.find(column => column.key === sortColumn.key));
        }
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(1);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onTableSortOptionsChange(): void {
        this.setPageIndex();
    }

    public selectVariant(variantKey: string): void {
        if (this.selectedVariantKey !== variantKey) {
            this.selectedVariantKey = variantKey;
            this.updateColumns();
            // Update data when sorting on variant fields enabled
            if (this.tableSortOptions.columns.find(column => !!column.variantKey)) {
                this.setPageIndex();
            }
        }
    }

    private scheduleImportJob(): void {
        this.importButton.loading = true;

        const dataFieldCopyOptions = this.dataFieldCopyOptionsForm.getRawValue().map((option) => {
            const jobDataOption = {
                dataFieldId: option.dataField._id,
                behavior: option.behavior
            };
            if (option.value !== undefined) {
                switch (option.dataField.getDataType()) {
                    case EDataFieldTypes.LIST:
                        jobDataOption['value'] = option.value.map((item) => item.value);
                        break;
                    default:
                        jobDataOption['value'] = option.value;
                        break;
                }

            }
            return jobDataOption;
        });

        const jobData = {
            campaignId: this.campaignId,
            campaignItemIds: this.masterBriefingDataProvider.selected.map((briefing) => briefing._id),
            dataFieldCopyOptions
        } as ICopyMasterBriefingToCampaignJobData;

        this.campaignService.postJob(ECampaignJobTypes.COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB, jobData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result); // for later monitor the job
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.importButton.loading = false;
                }
            });
    }

    public onSearchTermChanged(search: string): void {
        this.autocompleteValues = [];
        if (search) { // we search master briefing
            this.campaignItemsApiService.autocomplete(search, null).subscribe({
                next: (values) => {
                    this.autocompleteValues = values.map((v) => {
                        return new DropdownItem(v, v);
                    });
                },
                error: Toaster.handleApiError
            });
        }
    }

    public onSearchSelectionChanged(values: IDropdownItem<string>[]): void {
        const value = values.map((v) => v.getValue()).join('|');
        if (this.masterBriefingDataProvider.searchValue !== value) {
            this.masterBriefingDataProvider.searchValue = value;
            this.setPageIndex();
        }
    }

    public deleteOption(index: number) {
        this.dataFieldCopyOptionsForm.removeAt(index);
    }

    protected addOption() {
        const optionGroup = new FormGroup({
            dataField: new FormControl('', {validators: [Validators.required]}),
            behavior: new FormControl( '', {validators: [Validators.required]})
        });
        this.dataFieldCopyOptionsForm.push(optionGroup);
    }
}
