import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {ChangelogModel} from '../../../models/api/changelog.model';
import {ChangelogService} from '../../../api/services/changelog.service';
import {Toaster} from '../../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {finalize, takeUntil} from 'rxjs/operators';
import {EChangeLogCollectionName, EStickyNoteStatus} from '../../../app.enums';
import {Subject} from 'rxjs';
import {StickyNoteModel} from '../../../models/api/sticky-note.model';
import {EmptyValuePipe} from '../../../pipes/empty-value.pipe';

class NoteChangeLogModel {
    public userFullName: string;
    public createdAt: string;
    public name: string;
    public value: string;
    public message: string;

    constructor(userFullName: string, createdAt: string, name: string, value?: string, message?: string) {
        this.userFullName = userFullName;
        this.createdAt = createdAt;
        this.name = name;
        if (value) this.value = value;
        if (message) this.message = message;
    }
}

enum EChangelogField {
    MESSAGE = 'message',
    STATUS = 'status'
}

@Component({
    selector: 'rl-note-changes',
    templateUrl: './note-changes.component.html',
    styleUrls: ['./note-changes.component.scss'],
    standalone: false
})
export class NoteChangesComponent implements OnChanges, OnDestroy {
    public readonly EChangelogField = EChangelogField;
    private static readonly CHANGELOG_FIELDS = [EChangelogField.STATUS, EChangelogField.MESSAGE];

    @Input() public note: StickyNoteModel;

    public readonly DELETED_USER = EmptyValuePipe.defaultValues.DELETED_USER;

    public changelogs: NoteChangeLogModel[];
    public loading = false;
    public dateFormats = RLDatePipe.dateFormats;
    private onDestroySubject = new Subject<void>();

    public readMore: boolean[] = [];
    public readonly viewMoreLength = 70;

    /**
     * @constructor
     */
    constructor(private changelogService: ChangelogService) {}

    /**
     * Lifecycle method called on changes
     * Gets the changelog for the campaignItemId
     */
    public ngOnChanges(): void {
        if (this.note) {
            this.loading = true;
            this.changelogService.getChangelog(this.note._id, EChangeLogCollectionName.NOTE)
                .pipe(
                    finalize(() => this.loading = false),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(
                (result: ARPagedResponseDataModel<ChangelogModel>) => this.convertChangelog(result.items),
                (error) => Toaster.handleApiWarning(error));
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private convertChangelog(items: ChangelogModel[]): void {
        const changes = items.reduce((acc, item) => {
            const itemChanges = item.changes.filter(change => NoteChangesComponent.CHANGELOG_FIELDS.includes(change.name as EChangelogField));
            for (const itemChange of itemChanges) {
                acc.push(itemChange.name === EChangelogField.MESSAGE ?
                    new NoteChangeLogModel(item.userFullName, item.createdAt, itemChange.name, null, itemChange.newValue) :
                    new NoteChangeLogModel(item.userFullName, item.createdAt, itemChange.name, itemChange.newValue)
                );
            }
            return acc;
        }, [] as NoteChangeLogModel[]);

        // Always add created changelog
        // Find first text edit, otherwise use original message
        const firstEdit = items.find(item => item.changes.find(change => change.name === EChangelogField.MESSAGE));
        const message = !firstEdit ? this.note.message : firstEdit.changes.find(change => change.name === EChangelogField.MESSAGE).oldValue;

        const createChangelog = new NoteChangeLogModel(
            EmptyValuePipe.transform(this.note.createdBy?.fullName, this.DELETED_USER),
            this.note.createdAt,
            EChangelogField.STATUS,
            EStickyNoteStatus.CREATED,
            message);
        changes.push(createChangelog);

        this.changelogs = changes;
    }
}
