import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {DataFieldFilterModel} from '../../models/ui/data-field-filter.model';
import {FormControl, FormGroup} from '@angular/forms';
import {DataFieldModel} from '../../models/api/data-field.model';
import {DataFilterModel, EDataFilterModels} from '../../models/ui/data-filter.model';
import {EBooleanText, EDataFieldTypes} from '../../app.enums';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';
import {FileTypeUtil} from '../../classes/file-type.util';

export enum EDataFilterContext {
    PRODUCT,
    ASSET,
    CAMPAIGN_ITEM
}

export class DataFilterUtil {
    public static booleanDropdownItems = [
        new DropdownItem(EBooleanText.TRUE, 'true'),
        new DropdownItem(EBooleanText.FALSE, 'false'),
        new DropdownItem(EBooleanText.EMPTY, 'undefined')
    ];

    public static createDataFilters(currentFilters: DataFilterModel[] = [], dataFields: DataFieldModel[] = [],
                                    disabledFilters?: string[], variantKey?: string,
                                    requestOptions: IDataFieldFilterOptions = {}): DataFilterModel[] {
        // add data fields
        const dataFieldFilters = [];
        if (dataFields?.length > 0) {
            const dataFieldDataFilters = dataFields.map((dataField) => {
                const items = dataField.dataType.enumeration?.items.map(item => new DropdownItem(item, item));
                const propertyPath = dataField.enableVariants && variantKey ? `dataFields.${dataField.fieldName}.${variantKey}` :
                    `dataFields.${dataField.fieldName}`;
                return new DataFieldFilterModel(dataField.name, propertyPath, dataField.dataType.type,
                    items, dataField._id, dataField.dataType.enumeration, requestOptions, dataField.enableAutocomplete);
            });

            // Preserve existing filters, add new, remove deleted
            dataFieldFilters.push(...currentFilters.filter(filter =>
                (filter.modelName === EDataFilterModels.DATA_FILTER || dataFieldDataFilters.find(newFilter => newFilter.property === filter.property))
            ));
            dataFieldFilters.push(...dataFieldDataFilters.filter(filter =>
                !currentFilters.find(oldFilter => oldFilter.property === filter.property)));
        } else {
            dataFieldFilters.push(...currentFilters);
        }

        // Disable filters
        if (disabledFilters?.length) for (const disabledFilter of disabledFilters) {
            const dataFieldFilter = dataFieldFilters.find(dataFieldFilter => dataFieldFilter.property === disabledFilter);
            if (dataFieldFilter) dataFieldFilter.disabled = true;
        }
        return dataFieldFilters;
    }

    public static createFilterFormGroup(filters: DataFilterModel[] = [], formGroupValue: Record<string, any> = {},
                                        routeFilters: Record<string, any> = {}): FormGroup<Record<string, any>> {
        // add data fields
        // add form and form controls, convert and set value
        const filterFormGroup = new FormGroup<Record<string, any>>({});
        for (const dataFilter of filters) {
            // get the value from the url params if present
            const value = routeFilters[dataFilter.property] || formGroupValue[dataFilter.property];

            if (!DataFilterUtil.valueIsEmpty(value)) {
                // value used in the formControl
                let formControlValue = value;

                if (dataFilter.type === EDataFieldTypes.LIST || (dataFilter.type === EDataFieldTypes.ENUM && dataFilter.enumeration?.multiSelect)) {
                    // handling array
                    if (Array.isArray(value)) {
                        if (dataFilter.type === EDataFieldTypes.LIST) {
                            formControlValue = value.map(v => new DropdownItem(v, v, !!dataFilter.disabled));
                        } else {
                            formControlValue = value;
                        }
                    } else if (typeof value === 'string') {
                        formControlValue = value.split(',');
                    }
                } else if (dataFilter.type === EDataFieldTypes.ENUM) {
                    if (value instanceof DropdownItem) {
                        formControlValue = new DropdownItem(value.getTitle(), value.getValue(), !!dataFilter.disabled);
                    } else if (typeof value === 'string') {
                        formControlValue = value;
                    }
                } else if (dataFilter.type === EDataFieldTypes.BOOLEAN) {
                    formControlValue = value;
                }
                filterFormGroup.addControl(dataFilter.property, new FormControl({value: formControlValue, disabled: dataFilter.disabled}));
            } else {
                filterFormGroup.addControl(dataFilter.property, new FormControl({value: null, disabled: dataFilter.disabled}));
            }
        }

        // when initialized and values are retrieved from url params always call setFilterValues
        // so listeners can get data.
        return filterFormGroup;
    }

    public static getFilterValues(formGroupValue: Record<string, any>): Record<string, any> {
        const filterValues = {};
        Object.keys(formGroupValue).forEach((key) => {
            // Skip the null, undefined, empty (trimmed) values & empty arrays
            if (DataFilterUtil.valueIsEmpty(formGroupValue[key])) {
                return;
            }

            filterValues[key] = formGroupValue[key];

            // trim string values
            if (typeof formGroupValue[key] === 'string') {
                filterValues[key] = formGroupValue[key].trim();
            }

            // convert DropdownItem to string
            if (formGroupValue[key] instanceof DropdownItem) {
                filterValues[key] = formGroupValue[key].getValue();
            }

            // convert array of DropDownItem to string array (dropdown multiselect)
            if (Array.isArray(formGroupValue[key])) {
                // Flatten all values of the array values, for example filter on assigned pages (filter is an array of campaign items values)
                filterValues[key] = formGroupValue[key].reduce((flatValues, value) => {
                    value = (value instanceof DropdownItem) ? value.getValue() : value;
                    Array.isArray(value) ? flatValues.push(...value) : flatValues.push(value);
                    return flatValues;
                }, []);
            }

            // Convert Date to ISO format
            if (formGroupValue[key] instanceof Date) {
                filterValues[key] = formGroupValue[key].toISOString();
            }
        });

        return filterValues;
    }

    public static countActiveFilters(filterValues: Record<string, any> = {}): number {
        let activeFilters = 0;
        for (const value of Object.values(filterValues)) {
            if (Array.isArray(value)) {
                activeFilters += value.length;
            } else if (!DataFilterUtil.valueIsEmpty(value)) {
                activeFilters++;
            }
        }
        return activeFilters;
    }

    /**
     * Checks if value is null, undefined, empty string or an empty array.
     * @private
     */
    private static valueIsEmpty(value: any) {
        return (value === undefined || value === null ||
            (typeof value === 'string' && value.trim().length === 0) || (Array.isArray(value) && value.length === 0));
    }

    public static setupDefaultDataFilters(context: EDataFilterContext, dataOptions?: {allowedAssets: string[]}): DataFilterModel[] {
        const dataFilters: DataFilterModel[] = [];
        switch(context) {
            case EDataFilterContext.PRODUCT:
                // No defaults
                break;
            case EDataFilterContext.CAMPAIGN_ITEM:
                // No defaults
                break;
            case EDataFilterContext.ASSET:
                dataFilters.push(new DataFilterModel('RIN', 'rin', EDataFieldTypes.STRING));

                const items = dataOptions?.allowedAssets || FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES);
                const dropdownItems = items.map(item => new DropdownItem(item, item));
                dataFilters.push(new DataFilterModel('File type', 'type', EDataFieldTypes.ENUM, dropdownItems, {multiSelect: true, items}));
                break;
        }
        return dataFilters;
    }
}
