import {Pipe, PipeTransform} from '@angular/core';
import {ValidationErrors} from '@angular/forms';

@Pipe({
    name: 'displayFormError',
    standalone: false
})
export class DisplayFormErrorPipe implements PipeTransform {

  public transform(errors: ValidationErrors): string {
      let errorMessage: string;
      for (const errorKey in errors) {
          if (errorMessage) {
              errorMessage += `\n ${errors[errorKey].value}`;
          } else {
              errorMessage = errors[errorKey].value;
          }
      }
    return errorMessage;
  }

}
