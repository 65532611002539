import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'join',
    standalone: false
})
export class JoinPipe implements PipeTransform {

    public transform(values: string[], separator: string = ', '): string {
        return values.join(separator);
    }
}
