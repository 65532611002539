import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EDataFieldCollectionName} from '../../app.enums';
import {ESelectionMode, IActionClickEvent, ITableAction, ITableColumn, ITableItemPatchModel, TableComponent} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {unlockedBadgeAnimation} from '../../animations/unlocked-badge-animation';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {forkJoin} from 'rxjs';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {CampaignItemsService} from '../../api/services/campaign-items.service';
import {Toaster} from '../../classes/toaster.class';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {ARLogger, ARUtils} from '@relayter/core';
import {TableInlineEditingUtil} from '../../classes/table-inline-editing.util';
import {PaginatorService} from '../paginator/paginator.service';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {SelectionModel} from '@angular/cdk/collections';
import {VariantModel} from '../../models/api/variant.model';
import {DataFieldModel} from '../../models/api/data-field.model';
import {VariantService} from '../../api/services/variant.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {BriefingTableData} from './briefing-table.data';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';
import {BriefingDataProvider} from './briefing.data-provider';
import {EDataFilterContext} from '../data-filter/data-filter.util';

export interface IBriefingTableOptions {
    inlineEditing?: boolean;
}

@Component({
    selector: 'briefing-table',
    templateUrl: './briefing-table.component.html',
    styleUrls: ['./briefing-table.component.scss'],
    animations: [unlockedBadgeAnimation.unlockBadge],
    providers: [AdvancedFiltersDataService, PaginatorService],
    host: {
        '[class.no-table]': '!briefingDataProvider?.data.length'
    },
    standalone: false
})
export class BriefingTableComponent extends RLTableComponent implements OnInit, OnChanges {
    public readonly EDataFilterContextCampaignItem = EDataFilterContext.CAMPAIGN_ITEM;

    public tableLocked = true;
    public unlockTableTooltipText = 'Unlock table';

    @Output() public actionClicked: EventEmitter<IActionClickEvent> = new EventEmitter();
    @Output() public itemsChanged: EventEmitter<CampaignItemModel[]> = new EventEmitter();

    public columns: ITableColumn[];
    public ESelectionMode = ESelectionMode;

    @Input() public campaignId: string;
    @Input() public publicationId: string;
    @Input() public actions: ITableAction[] = [];
    @Input() public tableId: string;
    @Input() public briefingTableOptions: IBriefingTableOptions = {};

    public defaultColumns: ITableColumn[] = BriefingTableData.DefaultColumns;
    @Input() public additionalColumns: ITableColumn[] = [];
    @Input() public stickyHeader = true;
    @Input() public isLoading: boolean = false;
    @Input() public selectionMode: ESelectionMode = ESelectionMode.EXPAND;
    @Input() public selection: SelectionModel<CampaignItemModel>;
    @Input() public assignableItems = false;

    public viewId: string;

    public autocompleteValues: IDropdownItem[] = [];

    @ViewChild(TableComponent) public tableComponent: TableComponent;

    // Variants
    public variants: VariantModel[] = [];
    public variantEnabled = false;
    public selectedVariantKey: string;
    public requestOptions: IDataFieldFilterOptions;
    public dataFields: DataFieldModel[];
    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    public briefingDataProvider = new BriefingDataProvider(this.tableSortOptions);

    constructor(private userIsAllowedToPipe: UserIsAllowedToPipe,
                private variantService: VariantService,
                private campaignItemApiService: CampaignItemsApiService,
                private campaignItemService: CampaignItemsService,
                private dataFieldsService: DataFieldsApiService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        // Init data provider
        this.briefingDataProvider.campaignId = this.campaignId;
        this.briefingDataProvider.publicationId = this.publicationId;
        this.briefingDataProvider.assignableItems = this.assignableItems;

        this.briefingDataProvider.dataChanged$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.itemsChanged.next(this.briefingDataProvider.data));

        this.viewId = this.tableId;
        this.defaultColumns = [...this.defaultColumns, ...this.additionalColumns];
        this.requestOptions = {campaignId: this.campaignId};

        forkJoin([
            this.variantService.getVariants(this.campaignId),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantData, dataFields]) => {
                    this.variants = variantData.items;
                    this.variantEnabled = this.variants.length && dataFields.some(field => field.enableVariants);
                    if (this.variants?.length > 0) {
                        this.selectedVariantKey = this.variants[0].getValue();
                    }
                    this.dataFields = dataFields;
                    this.updateColumns();
                },
                error: Toaster.handleApiError
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (filterValues: Record<string, any>) => {
                    this.briefingDataProvider.filterValues = filterValues;
                    this.resetPageIndex();
                }
            );

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.briefingDataProvider.pageSize) {
                    this.briefingDataProvider.resetCursorArray(pagination.pageIndex);
                }

                this.briefingDataProvider.pageIndex = pagination.pageIndex;
                this.briefingDataProvider.pageSize = pagination.pageSize;

                this.getCampaignItems();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isLoading) {
            this.briefingDataProvider.loading = this.isLoading;
        }
        if (changes.selection) {
            this.briefingDataProvider.selection = this.selection;
        }
    }

    public onSearchTermChanged(search: string): void {
        this.autocompleteValues = [];
        if (search) {
            this.campaignItemApiService.autocomplete(search, this.campaignId).subscribe({
                next: (values) => {
                    this.autocompleteValues = values.map(v => {
                        return new DropdownItem(v, v);
                    });
                },
                error: Toaster.handleApiError
            });
        }
    }

    public onSearchSelectionChanged(values: IDropdownItem<string>[]): void {
        const value = values.map(v => v.getValue()).join('|');
        if (this.briefingDataProvider.phraseValue !== value) {
            this.briefingDataProvider.phraseValue = value;
            this.resetPageIndex();
        }
    }

    protected resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.viewId, 1); // reset pageIndex
    }

    public onItemEdited(patchObj: ITableItemPatchModel): void {
        const {property, value} = TableInlineEditingUtil.getPropertyAndValue(patchObj);
        const patchColumn = this.columns.find(column => column.key === property);
        const itemToUpdate = this.briefingDataProvider.data.find((item) => item._id === patchObj._id);
        // store previous value to reset in case of error
        let valueBackup = property.split('.').reduce((o, p) => o && o[p], itemToUpdate);
        // Variants are objects, and we don't want a reference but a copy of the value
        if (patchColumn.variantKey && ARUtils.isObject(valueBackup)) {
            valueBackup = Object.assign({}, valueBackup);
        }
        // modify front-end object to show user changes
        TableInlineEditingUtil.fillObjectFromPath(property, value, itemToUpdate, patchColumn?.variantKey);

        // create a patchBody containing only the property to update. Example: {dataFields: {some-prop: 'some value'}}
        const updateBody = TableInlineEditingUtil.fillObjectFromPath(property, value, {}, patchColumn?.variantKey);
        this.campaignItemService.updateCampaignItem(this.campaignId, patchObj._id, updateBody)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    ARLogger.debug('CampaignBriefingComponent: Campaign item edited inline');
                },
                error: (error) => {
                    // Reset to previous value in case of error
                    TableInlineEditingUtil.fillObjectFromPath(property, valueBackup, itemToUpdate);
                    Toaster.handleApiError(error);
                }
            });
    }

    public getCampaignItems(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEMS)) {
            this.briefingDataProvider.retrieveData();
        }
    }

    public hasNextItemForCampaignItem(campaignItemId: string): boolean {
        const itemIndex = this.briefingDataProvider.data.findIndex(item => item._id === campaignItemId);
        // Not found
        if (itemIndex === -1) {
            return false;
        }

        // Item before last item
        if (itemIndex < (this.briefingDataProvider.data.length - 1)) {
            return true;
        }

        // Item is last item
        if (itemIndex === (this.briefingDataProvider.data.length - 1)) {
            return !this.briefingDataProvider.disableNextPage;
        }

        return false;
    }

    public setSelectedVariantKey(variantKey: string): void {
        if (this.selectedVariantKey !== variantKey) {
            this.selectedVariantKey = variantKey;
            this.updateColumns();
            // Update data when sorting on variant fields enabled
            if (this.tableSortOptions.columns.find(column => !!column.variantKey)) {
                this.resetPageIndex();
            }
        }
    }

    private updateColumns(): void {
        this.columns = [
            ...BriefingTableData.PermanentColumns,
            ...this.dataFieldsComponentUtil.getDataFieldsColumnSelection(this.dataFields, true, this.selectedVariantKey),
            ...this.defaultColumns
        ];

        // Update sort columns (variant could be changed)
        if (this.tableSortOptions.columns.find(column => !!column.variantKey)) {
            this.tableSortOptions.columns = this.tableSortOptions.columns
                .map(sortColumn => this.columns.find(column => column.key === sortColumn.key));
        }
    }
}
