<div class="list-view">
    <nuc-table [items]="stickyNotes$ | async"
               [columns]="columns"
               [loading]="loading"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="1"
               [emptyStateTitle]="(filterValues?.length > 0 || searchValue) ? 'No item matches your search criteria.' : 'Create the first note in preview.'"
               (sortOptionChange)="onSortOptionChanged($event)"
               (itemClicked)="clickedStickyNote$.next($event.item)">

        <ng-container button-bar-left>
            <nuc-input class="search"
                       [(data)]="searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="refreshData()"
                       placeholder="Search notes">
            </nuc-input>
            <data-filter [filters]="dataFilters"></data-filter>
        </ng-container>

        <ng-container button-bar-left-after>
            <nuc-button-secondary
                *ngIf="permissions.POST_PUBLICATION_JOB | userIsAllowedTo"
                text="Export notes"
                (click)="openNoteExportModal()">
            </nuc-button-secondary>
            <nuc-dropdown *ngIf="publicationVariants?.length"
                          class="variant variant-highlight"
                          placeholder="Select variant"
                          (dataChange)="setVariant($event)"
                          [data]="selectedVariantKey"
                          icon="nucicon_variant"
                          [items]="publicationVariants">
            </nuc-dropdown>
        </ng-container>

        <paginator button-bar-right
                   [viewId]="viewId"
                   [loading]="loading"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</div>

<div class="detail-view">

    <p class="section-header text-center">Note Details</p>

    <ng-container *ngIf="selectedStickyNote$ | async as selectedStickyNote; else noStickyNoteSelected">
        <div class="detail-row">
            <img src="/assets/images/icon_profile.svg"/>
            <p class="value">{{selectedStickyNote.createdBy?.fullName | emptyValueFormatter:DELETED_USER}}</p>
        </div>

        <div class="detail-row">
            <i class="nucicon_comments_show"></i>
            <p class="value sticky-note-message">{{selectedStickyNote.message}}</p>
        </div>

        <div class="left-buttons">
            <nuc-button-secondary
                *ngIf="deleteAction"
                [loading]="actionLoading"
                [nucTooltip]="TOOLTIPS.DELETE"
                (click)="stickyNoteActionClicked(selectedStickyNote, deleteAction)"
                icon="nucicon_trash_fill">
            </nuc-button-secondary>

            <nuc-button-secondary
                *ngIf="markDuplicateAction"
                [loading]="actionLoading"
                [nucTooltip]="TOOLTIPS.DUPLICATE"
                (click)="stickyNoteActionClicked(selectedStickyNote, markDuplicateAction)"
                icon="nucicon_duplicate">
            </nuc-button-secondary>

            <nuc-button-secondary
                *ngFor="let action of editStatusActions"
                [loading]="actionLoading"
                (click)="stickyNoteActionClicked(selectedStickyNote, action)"
                [text]="action.title">
            </nuc-button-secondary>
        </div>

        <hr *ngIf="tabBarItems && tabBarItems.length > 0">

        <nuc-tab-bar *ngIf="tabBarItems && tabBarItems.length > 0"
                     [tabs]="tabBarItems"
                     [disabledTabs]="disabledTabBarItems"
                     [(activeTab)]="selectedTab"></nuc-tab-bar>

        <div class="content-container" [ngSwitch]="selectedTab.index">
            <div *ngSwitchCase="TAB_INDEXES.COMMENTS">
                <rl-sticky-comment [component]="component"
                                   [publicationId]="publication._id"
                                   [stickyNote]="selectedStickyNote"
                                   [showCommentsAction]="showCommentsAction"
                                   [addCommentAction]="addCommentAction"
                                   [confirmOnDelete]="true">
                </rl-sticky-comment>
            </div>

            <div *ngSwitchCase="TAB_INDEXES.CHANGELOG">

                <campaign-item-changelog *ngIf="selectedStickyNote.campaignItem"
                                         [campaignItem]="selectedStickyNote.campaignItem">
                </campaign-item-changelog>

                <div *ngIf="!selectedStickyNote.campaignItem">
                    <p>No linked briefing item.</p>
                </div>

            </div>

            <div *ngSwitchCase="TAB_INDEXES.NOTE_CHANGES">

                <rl-note-changes [note]="selectedStickyNote"></rl-note-changes>

            </div>
        </div>
    </ng-container>
</div>

<ng-template #noStickyNoteSelected>
    <ng-container *ngIf="stickyNotes$ | async as stickyNotes">
        <p *ngIf="stickyNotes?.length > 0; else noStickyNotes" class="grey">No note selected</p>
    </ng-container>
</ng-template>
<ng-template #noStickyNotes>
    <p>No notes</p>
</ng-template>
