import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileTypeUtil} from '../../classes/file-type.util';
import {Toaster} from '../../classes/toaster.class';

@Component({
    selector: 'rl-file-component',
    templateUrl: 'file.component.html',
    styleUrls: ['file.component.scss'],
    standalone: false
})

export class FileComponent {

    @Output() public onFileChanged = new EventEmitter<File>();

    @Input() public fileTypeCategories: string[];
    @Input() public subtitle: string;

    public get fileTypeCategoriesString() {
        return this.fileTypeCategories?.reduce((acc, fileType) => {
            acc.push(...FileTypeUtil.FILE_TYPE_CATEGORIES[fileType].extensions);
            return acc;
        }, []).join('/');
    }

    public onFilesChanged(files: File[]): void {
        if (!files || !files.length) {
            return;
        }

        const file = files[0];
        if (this.fileTypeCategories) {
            const filteredFiles = FileTypeUtil.filterFiles([file], this.fileTypeCategories);
            if (filteredFiles.length === 0) {
                return Toaster.warn(`Please provide a file of the correct type (${this.fileTypeCategoriesString})`);
            }
        }

        this.onFileChanged.emit(file);
    }
}
