import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, takeUntil} from 'rxjs/operators';
import {ITableAction} from '@relayter/rubber-duck';

@Component({
    selector: 'searchable-list',
    templateUrl: './searchable-list.component.html',
    styleUrls: ['./searchable-list.component.scss'],
    standalone: false
})
export class SearchableListComponent implements OnInit, OnDestroy {

    @Input() public action: ITableAction;
    @Input() public options: IDropdownItem[] = [];
    public searchInput = new UntypedFormControl();

    @Output() public optionClicked: EventEmitter<string> = new EventEmitter<string>();
    public filteredOptions: Observable<IDropdownItem[]>;
    private onDestroySubject = new Subject<void>();

    public ngOnInit(): void {
        this.filteredOptions = this.searchInput.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(300),
            map(value => this._filter(value)),
            takeUntil(this.onDestroySubject)
        );
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private _filter(value: string): IDropdownItem[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.getTitle().toLowerCase().includes(filterValue));
    }

    public clicked(option: IDropdownItem): void {
        this.optionClicked.next(option.getValue());
    }

}
