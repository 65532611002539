import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../app.enums';
import {FormArray, FormControl} from '@angular/forms';
import {take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {PropertySetting, PropertySettingsModel} from './property-settings.model';
import {EPropertySettingsContext, PropertySettingsService} from './property-settings.service';
import {merge, Subject, Subscription} from 'rxjs';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../models/api/data-field.model';
import {AppConstants} from '../../app.constants';

const DATE_CREATED = 'Date created';
const DATE_MODIFIED = 'Date modified';

@Component({
    selector: 'rl-property-settings-component',
    templateUrl: './property-settings.component.html',
    styleUrls: ['./property-settings.component.scss'],
    standalone: false
})
export class PropertySettingsComponent implements OnInit, OnDestroy {
    public VARIANT_ICON_COLOR = AppConstants.VARIANT_ICON_INFO_COLOR;
    @Input() public context: EPropertySettingsContext;
    public relayterFieldsFormArray: FormArray<FormControl<PropertySetting>>;
    public dataFieldsFormArray: FormArray<FormControl<PropertySetting>>;
    public dataSubscription: Subscription;
    public relayterFields: PropertySetting[];
    public dataFields: DataFieldModel[];
    private dataFieldCollectionName: EDataFieldCollectionName;
    private onDestroySubject = new Subject<void>();

    constructor(private propertySettingsService: PropertySettingsService,
                private dataFieldsService: DataFieldsApiService) {}

    public ngOnInit(): void {
        switch (this.context) {
            case EPropertySettingsContext.PRODUCT:
                this.relayterFields = [
                    new PropertySetting(DATE_CREATED, 'createdAt', EDataFieldTypes.DATE),
                    new PropertySetting(DATE_MODIFIED, 'updatedAt', EDataFieldTypes.DATE)
                ];
                this.dataFieldCollectionName = EDataFieldCollectionName.PRODUCT;
                break;
            case EPropertySettingsContext.BRIEFING:
                this.relayterFields = [
                    new PropertySetting('Briefing item ID', 'briefingItemId', EDataFieldTypes.STRING),
                    new PropertySetting(DATE_CREATED, 'createdAt', EDataFieldTypes.DATE),
                    new PropertySetting(DATE_MODIFIED, 'updatedAt', EDataFieldTypes.DATE)
                ];
                this.dataFieldCollectionName = EDataFieldCollectionName.CAMPAIGN_ITEM;
                break;
            case EPropertySettingsContext.ASSET:
                this.relayterFields = [
                    new PropertySetting('Name', 'name', EDataFieldTypes.STRING),
                    new PropertySetting('RIN', 'rin', EDataFieldTypes.STRING),
                    new PropertySetting('Type', 'type', EDataFieldTypes.STRING),
                    new PropertySetting('Resolution', 'resolution', EDataFieldTypes.STRING),
                    new PropertySetting('Files size', 'size', EDataFieldTypes.STRING),
                    new PropertySetting('Print size', 'printSizeName', EDataFieldTypes.STRING),
                    new PropertySetting('File info', 'fileInfo', EDataFieldTypes.STRING),
                    new PropertySetting(DATE_CREATED, 'createdAt', EDataFieldTypes.DATE),
                    new PropertySetting(DATE_MODIFIED, 'updatedAt', EDataFieldTypes.DATE)];
                this.dataFieldCollectionName = EDataFieldCollectionName.ASSET;
                break;
            case EPropertySettingsContext.PROJECT:
                this.relayterFields = [
                    new PropertySetting('Name', 'name', EDataFieldTypes.STRING),
                    new PropertySetting('Tags', 'tags', EDataFieldTypes.LIST),
                    new PropertySetting('Deadline', 'deadline', EDataFieldTypes.DATE),
                    new PropertySetting(DATE_CREATED, 'createdAt', EDataFieldTypes.DATE),
                    new PropertySetting(DATE_MODIFIED, 'updatedAt', EDataFieldTypes.DATE)];
                break;
        }
        if (this.dataFieldCollectionName) {
            this.dataSubscription = this.dataFieldsService.getAllDataFields(this.dataFieldCollectionName).pipe(
                withLatestFrom(this.propertySettingsService.getSettings(this.context))
            )
            .subscribe(([dataFields, selection]) => {
                this.dataFields = dataFields;
                this.initForm(selection);
            });
        } else {
            this.propertySettingsService.getSettings(this.context).pipe(
                take(1)
            ).subscribe((selection: PropertySettingsModel) => {
                this.initForm(selection);
            });
        }
    }

    private initForm(settings: PropertySettingsModel): void {
        this.relayterFieldsFormArray = new FormArray<FormControl<PropertySetting>>(this.relayterFields.map((relayterField) => {
            const selected = settings.relayterFields.find((customProperty) => customProperty.propertyName === relayterField.propertyName);
            return new FormControl<PropertySetting>(selected);
        }));

        this.dataFieldsFormArray = new FormArray<FormControl<PropertySetting>>([])
        if (this.dataFields?.length) {
            this.dataFieldsFormArray = new FormArray<FormControl<PropertySetting>>(this.dataFields?.map((dataField) => {
                const selected = settings.dataFields.find((customProperty) => customProperty.propertyName === `dataFields.${dataField.fieldName}`);
                return new FormControl<PropertySetting>(selected);
            }));
        }

        merge(this.dataFieldsFormArray.valueChanges, this.relayterFieldsFormArray.valueChanges)
            .pipe((takeUntil(this.onDestroySubject)))
            .subscribe(() => this.onSettingsChanged());
    }

    private onSettingsChanged(): void {
        const selectedRelayterFields = [];
        const selectedDataFields = [];

        this.relayterFieldsFormArray.value.forEach((selected, index) => {
            if (selected) {
                selectedRelayterFields.push(this.relayterFields[index]);
            }
        });

        this.dataFieldsFormArray?.value?.forEach((selected, index) => {
            if (selected) {
                const dataField = this.dataFields[index];
                const propertySetting = new PropertySetting(dataField.name, `dataFields.${dataField.fieldName}`, dataField.dataType.type);
                selectedDataFields.push(propertySetting);
            }
        });

        const settings = new PropertySettingsModel(selectedRelayterFields, selectedDataFields);
        this.propertySettingsService.storeSettings(settings, this.context);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
