import {Pipe, PipeTransform} from '@angular/core';
import {AppConstants} from '../app.constants';
import {EStickyNoteStatus} from '../app.enums';
@Pipe({
    name: 'getNotePinImagePipe',
    standalone: false
})
export class GetNotePinImagePipe implements PipeTransform {
    private static readonly PIN_PATH = `${AppConstants.ASSETS_IMAGES_PATH}/`;

    public transform(status: EStickyNoteStatus, message = '', selected = false): string {
        let icon = 'icon_pin_editing.svg';
        if (message) {
            icon = 'icon_pin_text_change.svg';
        } else {
            switch (status) {
                case EStickyNoteStatus.COMPLETED:
                    icon = 'icon_pin_completed.svg';
                    break;
                case EStickyNoteStatus.APPROVED:
                    icon = 'icon_pin_approved.svg';
                    break;
                case EStickyNoteStatus.DENIED:
                    icon = 'icon_pin_denied.svg';
                    break;
                case EStickyNoteStatus.REJECTED:
                    icon = 'icon_pin_rejected.svg';
                    break;
                case EStickyNoteStatus.PROCESSED:
                    icon = 'icon_pin_processed.svg';
                    break;
                case EStickyNoteStatus.CREATED:
                    icon = 'icon_pin_created.svg';
                    break;
                case EStickyNoteStatus.DUPLICATE:
                    icon = 'icon_pin_duplicated.svg';
                    break;
                case EStickyNoteStatus.DELETED:
                    icon = 'icon_pin_deleted.svg';
                    break;
            }
        }

        if (selected) {
            icon = icon.replace('.svg', '_selected.svg');
        }

        return `${GetNotePinImagePipe.PIN_PATH}${icon}`;
    }
}
