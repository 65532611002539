import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'loading-bullets',
    templateUrl: 'loading-bullets.component.html',
    styleUrls: ['loading-bullets.component.scss'],
    standalone: false
})
export class LoadingBulletsComponent implements OnInit {
    @Input() numberOfBullets = 3;
    public bullets: void[];

    public ngOnInit(): void {
        this.bullets = new Array(this.numberOfBullets);
    }
}
