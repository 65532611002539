import {autoserialize, autoserializeAs} from 'cerialize';
import {WorkflowConfigurationStepModel} from './workflow-configuration-step.model';
import {
    WORKFLOW_CONFIGURATION_DESTRUCTIVE_RECIPE_TASK_NAMES,
    WorkflowConfigurationTransitionModel,
    WorkflowConfigurationTransitionRecipeTaskModel
} from './workflow-configuration-transition.model';
import {WorkflowConfigurationComponentModel} from './workflow-configuration-component.model';
import {WorkflowConfigurationFilterModel} from './workflow-configuration-filter.model';
import {WorkflowConfigurationActionModel} from './workflow-configuration-action.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {LayoutModel} from './layout.model';
import {ITableItem} from '@relayter/rubber-duck';
import {WorkflowConfigurationIdentifierSettingModel} from './workflow-configuration-identifier-setting.model';

export class WorkflowConfigurationModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public numberOfComponents: number;
    @autoserialize public numberOfSteps: number;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserializeAs(LayoutModel) public layout: LayoutModel;
    @autoserializeAs(WorkflowConfigurationComponentModel) public components: WorkflowConfigurationComponentModel[];
    @autoserializeAs(WorkflowConfigurationActionModel) public actions: WorkflowConfigurationActionModel[];
    @autoserializeAs(WorkflowConfigurationStepModel) public steps: WorkflowConfigurationStepModel[];
    @autoserializeAs(WorkflowConfigurationTransitionModel) public transitions: WorkflowConfigurationTransitionModel[];
    @autoserializeAs(WorkflowConfigurationFilterModel) public filters: WorkflowConfigurationFilterModel[];
    @autoserializeAs(WorkflowConfigurationIdentifierSettingModel) public identifierSetting: WorkflowConfigurationIdentifierSettingModel;
    @autoserialize public sequentialNumbering: boolean;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }

    public getTransitionById(transitionId: string): WorkflowConfigurationTransitionModel {
        return this.transitions.find(transition => transition._id === transitionId);
    }

    public findFirstDestructiveRecipeTask(transitionId: string): WorkflowConfigurationTransitionRecipeTaskModel {
        const transition = this.transitions.find(trans => trans._id === transitionId);
        return transition?.recipe.find(recipeTask => !!WORKFLOW_CONFIGURATION_DESTRUCTIVE_RECIPE_TASK_NAMES[recipeTask.name]);
    }

    public hasDestructiveTransitionRecipeTask(transitionId: string): boolean {
        return !!this.findFirstDestructiveRecipeTask(transitionId);
    }
}
