import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {TemplateTypeModel} from '../../models/api/template-type.model';

@Injectable({providedIn: 'root'})
export class TemplateTypesApiService extends ApiService<TemplateTypeModel> {
    constructor() {
        super([ApiConstants.API_GROUP_TEMPLATE_TYPES], TemplateTypeModel);
    }
}
