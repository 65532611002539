import {autoserialize, autoserializeAs, deserializeAs, serialize, serializeAs} from 'cerialize';
import {SizeModel} from './size.model';
import {PointModel} from './point.model';
import {TemplateMarginsModel} from './template-margins.model';
import {TemplateTypeModel} from './template-type.model';
import {IdSerializer} from '../../classes/id-serializer';
import {TemplateAreaModel} from './template-area.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {MasterPageModel} from './master-page.model';
import {ITableItem} from '@relayter/rubber-duck';
import {DesignLibraryModel} from '../../modules/static-content-rulesets/models/api/design-library.model';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {EChannel} from '../../app.enums';
import {FormGroup} from '@angular/forms';
import {ITemplateForm} from '../../pages/relayter/templates/static-content-template-form/static-content-template-form.component';
import {VariantModel} from './variant.model';
import {UserModel} from './user.model';
import {AnimatedContentRulesetModel} from './animated-content-ruleset.model';
import {AfterEffectsProjectFileModel} from './after-effects-project-file.model';
import {IAnimatedContentTemplateFormGroup} from '../../forms/animated-content-template-form/animated-content-template-form.component';

export enum EEngineType {
    INDESIGN = 'INDESIGN',
    SVG = 'SVG',
    AFTER_EFFECTS = 'AFTER_EFFECTS'
}

export enum EPageType {
    Single = 'Single',
    Spread = 'Spread'
}

export class TemplateConstants {
    static readonly DEFAULT_WIDTH = 210;
    static readonly DEFAULT_HEIGHT = 297;
    static readonly DEFAULT_MARGIN = 10;
}

export class AnimatedContentTemplateModel {
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserializeAs(AnimatedContentRulesetModel) animatedContentRuleset: AnimatedContentRulesetModel;
}

export class AnimatedContentTemplateDetailModel {
    @autoserializeAs(AfterEffectsProjectFileModel) public afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserializeAs(AnimatedContentRulesetModel) public animatedContentRuleset: AnimatedContentRulesetModel;
}

export class StaticContentTemplateVariantPresetModel {
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
    @autoserializeAs(DesignLibraryModel) public designLibrary: DesignLibraryModel;
    @autoserializeAs(StaticContentRulesetModel) public ruleset: StaticContentRulesetModel;
}

export class TemplateVariantPresetModel {
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(StaticContentTemplateVariantPresetModel) public staticContent: StaticContentTemplateVariantPresetModel;
    @autoserializeAs(AnimatedContentTemplateDetailModel) public animatedContent: AnimatedContentTemplateDetailModel;
}

export class StaticContentTemplateModel {
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserialize public numberOfPages: number;
    @autoserialize public designLibrary: string;
    @autoserialize public ruleset: string;
    // not populated values
    @autoserialize public masterPage: string;
}

export class TemplateModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserialize public engineType: EEngineType;
    @autoserialize public channel: EChannel;
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserializeAs(UserModel) public createdBy: UserModel;

    @autoserializeAs(StaticContentTemplateModel) staticContent: StaticContentTemplateModel;
    @autoserializeAs(AnimatedContentTemplateModel) animatedContent: AnimatedContentTemplateModel;

    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserialize public itemsGenerated: number;

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    static defaultPageSize(): SizeModel {
        return new SizeModel(TemplateConstants.DEFAULT_WIDTH, TemplateConstants.DEFAULT_HEIGHT);
    }
}

export class StaticContentTemplateDetailModel {
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserialize public numberOfPages: number;
    @autoserializeAs(DesignLibraryModel) public designLibrary: DesignLibraryModel;
    @autoserializeAs(StaticContentRulesetModel) public ruleset: StaticContentRulesetModel;
    // difference between TemplateDetailModel and TemplateModel
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
}

export class TemplateDetailModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserialize public engineType: EEngineType;
    @autoserialize public channel: EChannel;
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserializeAs(UserModel) public createdBy: UserModel;

    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserialize public itemsGenerated: number;

    @autoserializeAs(StaticContentTemplateDetailModel) staticContent: StaticContentTemplateDetailModel;
    @autoserializeAs(AnimatedContentTemplateDetailModel) animatedContent: AnimatedContentTemplateDetailModel;

    @autoserializeAs(TemplateVariantPresetModel) variantPresets: TemplateVariantPresetModel[];

    public static defaultTemplate(): TemplateDetailModel {
        const staticTemplate = new StaticContentTemplateDetailModel();
        staticTemplate.pageSize = new SizeModel(TemplateConstants.DEFAULT_WIDTH, TemplateConstants.DEFAULT_HEIGHT);
        staticTemplate.margins = new TemplateMarginsModel(TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN,
            TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN);
        staticTemplate.numberOfPages = 1;
        staticTemplate.areas = [new TemplateAreaModel(
            new PointModel(TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN),
            new SizeModel(
                TemplateConstants.DEFAULT_WIDTH - 2 * TemplateConstants.DEFAULT_MARGIN,
                TemplateConstants.DEFAULT_HEIGHT - 2 * TemplateConstants.DEFAULT_MARGIN),
            1,
            1,
            0,
            0,
            undefined
        )];

        const template = new TemplateDetailModel();
        template.staticContent = staticTemplate;
        template.engineType = EEngineType.INDESIGN;
        template.channel = EChannel.PRINT;
        template.variantPresets = [];
        return template;
    }
}

export class StaticContentTemplateVariantPresetBodyModel {
    @serialize public masterPage: string;
    @serialize public designLibrary: string;
    @serialize public ruleset: string;
}

export class TemplateVariantPresetBodyModel {
    @serialize public variant: string;
    @serializeAs(StaticContentTemplateVariantPresetBodyModel) public staticContent: StaticContentTemplateVariantPresetBodyModel;
    @serializeAs(AnimatedContentTemplateModel) public animatedContent: AnimatedContentTemplateBodyModel;
}

export class StaticContentTemplateBodyModel {
    @serializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;
    @serializeAs(SizeModel) public pageSize: SizeModel;
    @serializeAs(TemplateAreaModel) public areas: TemplateAreaModel[];
    @serialize public numberOfPages: number;
    @serialize public designLibrary: string;
    @serialize public ruleset: string;
    @serialize public masterPage: string;
}

export class AnimatedContentTemplateBodyModel {
    @autoserialize afterEffectsProjectFile: string;
    @autoserialize animatedContentRuleset: string;
}

export class TemplateBodyModel {
    @serialize public name: string;
    @serialize public tags: string[];
    @serialize public engineType: EEngineType;
    @serialize public channel: EChannel;
    @serialize public templateType: string;
    @serializeAs(StaticContentTemplateBodyModel) staticContent: StaticContentTemplateBodyModel;
    @serializeAs(AnimatedContentTemplateBodyModel) animatedContent: AnimatedContentTemplateBodyModel;

    @serialize public variantPresets: TemplateVariantPresetBodyModel[];

    public static fromStaticContentTemplateFormGroup(formGroup: FormGroup<ITemplateForm>, variants: VariantModel[]): TemplateBodyModel {
        const formValue = formGroup.getRawValue();
        const preset = formValue.preset;

        const staticTemplateBody = new StaticContentTemplateBodyModel();
        staticTemplateBody.numberOfPages = preset.numberOfPages.value;
        staticTemplateBody.designLibrary = preset.designLibrary;
        staticTemplateBody.ruleset = preset.ruleset;
        const size = formValue.size;
        staticTemplateBody.pageSize = new SizeModel(size.width, size.height);
        staticTemplateBody.margins = new TemplateMarginsModel(size.margins.marginTop, size.margins.marginBottom, size.margins.marginStart,
            size.margins.marginEnd);
        staticTemplateBody.areas = formValue.areas.map((area) => {
            if (!area._id) delete area._id;
            return area as TemplateAreaModel;
        });

        const body = new TemplateBodyModel();
        body.name = preset.name;
        body.tags = preset.tags?.map((v) => v.getValue()) || [];
        body.engineType = preset.engineType;
        body.channel = preset.channel;
        body.templateType = preset.templateType?.length > 0 ? preset.templateType[0].getValue() : null;
        body.staticContent = staticTemplateBody;

        if (body.engineType === EEngineType.INDESIGN) {
            staticTemplateBody.masterPage = preset.masterPage;
        }

        body.variantPresets = formValue.variantPresets.map((variantPreset) => {
            const variantPresetBody = new TemplateVariantPresetBodyModel();
            variantPresetBody.variant = variants.find(variant => variant.key === variantPreset.variant)?._id;
            variantPresetBody.staticContent = new StaticContentTemplateVariantPresetBodyModel();
            if (body.engineType === EEngineType.INDESIGN) { // only allow master page for indesign
                variantPresetBody.staticContent.masterPage = variantPreset.masterPage;
            }
            variantPresetBody.staticContent.designLibrary = variantPreset.designLibrary;
            variantPresetBody.staticContent.ruleset = variantPreset.ruleset;
            return variantPresetBody;
        });

        return body;
    }

    public static fromAnimatedContentTemplateFormGroup(formGroup: FormGroup<IAnimatedContentTemplateFormGroup>,
                                                       variants: VariantModel[]): TemplateBodyModel {
        const formValue = formGroup.getRawValue();
        const variantPresetsBody = formValue.variantsPresets.map((variantPreset) => {
            const variantPresetBody = new TemplateVariantPresetBodyModel();
            variantPresetBody.variant = variants.find(variant => variant.getValue() === variantPreset.variant)?._id;
            variantPresetBody.animatedContent = new AnimatedContentTemplateBodyModel();
            variantPresetBody.animatedContent.afterEffectsProjectFile = variantPreset.afterEffectsProjectFile;
            variantPresetBody.animatedContent.animatedContentRuleset = variantPreset.animatedContentRuleset;

            return variantPresetBody;
        })

        const animatedContentTemplateBody = new AnimatedContentTemplateBodyModel();
        animatedContentTemplateBody.animatedContentRuleset = formValue.preset.animatedContentRuleset;
        animatedContentTemplateBody.afterEffectsProjectFile = formValue.preset.afterEffectsProjectFile;

        const body = new TemplateBodyModel();
        body.name = formValue.name;
        body.engineType = formValue.engineType;
        body.channel = formValue.channel;
        body.tags = formValue.tags?.map((v) => v.getValue().toString()) || [];
        body.animatedContent = animatedContentTemplateBody;
        body.variantPresets = variantPresetsBody;

        return body;
    }
}
