import {Pipe, PipeTransform} from '@angular/core';
import {AssetModel} from '../models/api/asset.model';
import {ICardMediaType} from '@relayter/rubber-duck';

export enum EAssetDisplayProperties {
    TITLE,
    SUBTITLE,
    SOURCE_URL,
    PRINT_SIZE_NAME,
    RESOLUTION,
    THUMBNAIL_URL,
    FILE_INFO,
    DATE_CREATED,
    DATE_MODIFIED,
    THUMBNAIL_MEDIA
}

@Pipe({
    name: 'assetDisplay',
    standalone: false
})
export class AssetDisplayPipe implements PipeTransform {
    public transform(assetInput: AssetModel | AssetModel[], property: EAssetDisplayProperties): string | ICardMediaType {
        const asset: AssetModel = Array.isArray(assetInput) ? assetInput[0] : assetInput;

        switch (property) {
            case EAssetDisplayProperties.TITLE:
                return asset.getTitle();
            case EAssetDisplayProperties.SUBTITLE:
                // Could be used for unknown collection view item
                return asset?.getSubtitle();
            case EAssetDisplayProperties.SOURCE_URL:
                return asset.getSourceURL();
            case EAssetDisplayProperties.PRINT_SIZE_NAME:
                return asset.printSizeName;
            case EAssetDisplayProperties.RESOLUTION:
                return asset.resolution;
            case EAssetDisplayProperties.THUMBNAIL_URL:
                // Could be used for unknown collection view item
                return asset?.getThumbnailMedia().url;
            case EAssetDisplayProperties.THUMBNAIL_MEDIA:
                return asset?.getThumbnailMedia();
            case EAssetDisplayProperties.FILE_INFO:
                return asset.fileInfo;
            case EAssetDisplayProperties.DATE_CREATED:
                return asset.createdAt.toISOString();
            case EAssetDisplayProperties.DATE_MODIFIED:
                return asset.updatedAt.toISOString();
            default:
                throw new Error(`AssetDisplayPipe: Unhandled property ${property} for asset ${asset}`);
        }
    }
}
