import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AssetModel} from '../../models/api/asset.model';
import {AssetApiService, EAssetJobType} from '../../api/services/asset.api.service';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {JobModel} from '../../models/api/job.model';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {map, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SizeModel} from '../../models/api/size.model';

export interface IExportImageModalData {
    image: AssetModel;
}

enum EFileType {
    PNG = 'PNG',
    JPEG = 'JPEG'
}

enum ESizeType {
    ORIGINAL = 'ORIGINAL',
    CUSTOM = 'CUSTOM'
}
@Component({
    selector: 'export-image-modal-view',
    templateUrl: 'export-image-modal-view.component.html',
    styleUrls: ['export-image-modal-view.component.scss'],
    standalone: false
})

export class ExportImageModalViewComponent implements OnInit, OnDestroy {
    public formGroup: UntypedFormGroup;
    public image: AssetModel;
    public aspectRatio: number;

    public fileType = EFileType;
    public selectedType: EFileType;
    public pngType: string;
    public jpegType: string;

    public sizeType = ESizeType;
    public selectedSize: ESizeType;
    public originalSize: string;
    public customSize: string;

    public dataItems = [' ']; // TODO: Update this when radiobutton without text is possible in design system;

    private downloadButton: ButtonConfig;

    private onDestroySubject = new Subject<void>();

    constructor(private assetService: AssetApiService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IExportImageModalData) {
        this.image = modalData.image;
        this.aspectRatio = this.image.width / this.image.height;
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public initButtons(): void {
        this.downloadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Export', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const download = new FullModalActionModel(this.downloadButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, false));
        download.observable.subscribe(() => this.onDownloadButtonClicked());

        const actions = [cancel, download];
        this.fullModalService.setModalActions(actions);
    }

    public initForm(): void {
        this.formGroup = new UntypedFormGroup({
            width: new UntypedFormControl({value: this.image.width, disabled: true}, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
            height: new UntypedFormControl({value: this.image.height, disabled: true}, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
        });

        this.formGroup.valueChanges.pipe(
            startWith(this.formGroup.value),
            pairwise(),
            map(([previousValue, newValue]) => {
                let height: number;
                let width: number;

                if (previousValue.width !== newValue.width) {
                    height = Math.round(newValue.width / this.aspectRatio);
                    width = newValue.width;
                }

                if (previousValue.height !== newValue.height) {
                    width = Math.round(newValue.height * this.aspectRatio);
                    height = newValue.height;
                }

                return width && height ? new SizeModel(width, height) : null;
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe((newSize: SizeModel) => {
            if (newSize) {
                this.formGroup.get('height').setValue(newSize.height, {emitEvent: false});
                this.formGroup.get('width').setValue(newSize.width, {emitEvent: false});
            }
        });
    }

    public onDownloadButtonClicked(): void {
        this.downloadButton.loading = true;

        const jobData = {
            assetId: this.image._id,
            exportType: this.selectedType,
            exportSize: {
                width: this.formGroup.value.width,
                height: this.formGroup.value.height
            }
        };

        this.assetService.postJob(EAssetJobType.EXPORT_IMAGE_JOB, jobData).subscribe({
            next: (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.downloadButton.loading = false;
                this.fullModalService.close();
            },
            error: (err: ARApiError) => {
                Toaster.handleApiError(err);
                this.downloadButton.loading = false;
                this.fullModalService.close();
            }
        });
    }

    // TODO: Update this function when radiobutton without text is possible in design system;
    public onFileTypeClicked(type: string): void {
        if (type === EFileType.PNG) {
            this.pngType = ' ';
            this.jpegType = null;
            this.selectedType = EFileType.PNG;
        }

        if (type === EFileType.JPEG) {
            this.jpegType = ' ';
            this.pngType = null;
            this.selectedType = EFileType.JPEG;
        }

        this.downloadButton.disabled = !this.selectedSize || !this.selectedType;
    }

    // TODO: Update this function when radiobutton without text is possible in design system;
    public onSizeTypeClicked(size: string): void {
        if (size === ESizeType.ORIGINAL) {
            this.originalSize = ' ';
            this.customSize = null;
            this.selectedSize = ESizeType.ORIGINAL;
            this.formGroup.patchValue({
                width: this.image.width,
                height: this.image.height
            });

            this.formGroup.controls['width'].disable();
            this.formGroup.controls['height'].disable();
        }

        if (size === ESizeType.CUSTOM) {
            this.customSize = ' ';
            this.originalSize = null;
            this.selectedSize = ESizeType.CUSTOM;

            this.formGroup.controls['width'].enable();
            this.formGroup.controls['height'].enable();
        }

        this.downloadButton.disabled = !this.selectedSize || !this.selectedType;
    }
}
