import {Component, Input, OnInit} from '@angular/core';
import {IndicatorComponent} from '../indicator/indicator.component';
import {ETransitionType} from '../indicator/indicator-transitions.model';

@Component({
    selector: 'rl-transition',
    templateUrl: './transition.component.html',
    styleUrls: ['./transition.component.scss'],
    standalone: false
})
export class TransitionComponent extends IndicatorComponent implements OnInit {
    @Input() public hasBackwardsTransition: boolean = false;
    @Input() public hasNext: boolean = false;
    @Input() public backwardsTransitionName: string;

    protected readonly ETransitionType = ETransitionType;
    protected supportedTransitionTypes = [ETransitionType.FORWARD, ETransitionType.BACKWARD];
}
