import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    WorkflowConfigurationActionModel,
    EDownloadActionName
} from '../../../../../../../../models/api/workflow-configuration-action.model';
import {IListBoxItem} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../../../../../app.constants';
import {Subject} from 'rxjs';
import {
    WorkflowConfigurationOptionModel,
    EWorkflowConfigurationActionOptionName
} from '../../../../../../../../models/api/workflow-configuration-option.model';

export enum EPackageType {
    DESIGN_PACKAGE = 'DESIGN_PACKAGE',
    SOURCE = 'SOURCE',
    LINKS_PACKAGE = 'LINKS_PACKAGE',
    EXPORT_PACKAGE = 'EXPORT_PACKAGE',
    MERGED_PDF = 'MERGED_PDF',
    MERGED_PDF_IN_LAYOUT = 'MERGED_PDF_IN_LAYOUT',
    CUSTOM_EXPORT_PACKAGE = 'CUSTOM_EXPORT_PACKAGE'
}

export enum EPackageOptions {
    SIZE = 'SIZE',
    FORMAT = 'FORMAT',
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION'
}

export interface IPackageType extends IListBoxItem {
    name: string;
    action: EDownloadActionName;
    packageType: EPackageType;
    description: string;
    icon: string;
    size?: { width: number; height: number };
    format?: string;
}

export interface IPackageValue {
    type: EPackageType;
    options: WorkflowConfigurationOptionModel[];
}

export interface IDownloadActionOptions {
    packages: { name: EWorkflowConfigurationActionOptionName.PACKAGES; value: IPackageValue[] };
}

@Component({
    selector: 'files-download-package-type-component',
    templateUrl: './files-download-package-type.component.html',
    styleUrls: ['./files-download-package-type.component.scss'],
    standalone: false
})
export class FilesDownloadPackageTypeComponent implements OnInit, OnDestroy {
    private static allPackageTypes: IPackageType[] = [
        {
            name: 'Design Package',
            action: EDownloadActionName.DOWNLOAD_DESIGN_PACKAGE,
            packageType: EPackageType.DESIGN_PACKAGE,
            description: 'Contains the design file(s) and the linked asset(s).',
            icon: '/assets/images/icon_design_package.svg',
        },
        {
            name: 'Source Package',
            action: EDownloadActionName.DOWNLOAD_SOURCE_FILES_PACKAGE,
            packageType: EPackageType.SOURCE,
            description: 'Contains the (design) source file(s) of the item(s).',
            icon: AppConstants.ICONS.CUSTOM
        },
        {
            name: 'Link Package',
            action: EDownloadActionName.DOWNLOAD_LINK_PACKAGE,
            packageType: EPackageType.LINKS_PACKAGE,
            description: 'Contains the linked asset(s).',
            icon: '/assets/images/icon_link.svg',
        },
        {
            name: 'Export Package',
            action: EDownloadActionName.DOWNLOAD_EXPORT_PACKAGE,
            packageType: EPackageType.EXPORT_PACKAGE,
            description: 'Contains the export document(s) for (p)review purposes.',
            icon: '/assets/images/icon_export_package.svg'
        },
        {
            name: 'Merged PDF Document',
            action: EDownloadActionName.DOWNLOAD_MERGED_PDF,
            packageType: EPackageType.MERGED_PDF,
            description: 'Contains all the PDF files merged into one PDF document.',
            icon: AppConstants.ICONS.PDF
        },
        {
            name: 'Merged PDF Document in publication layout',
            action: EDownloadActionName.DOWNLOAD_MERGED_PDF_IN_LAYOUT,
            packageType: EPackageType.MERGED_PDF_IN_LAYOUT,
            description: 'Contains all the PDF files merged conform publication Layout (e.g. spreads).',
            icon: AppConstants.ICONS.PDF
        }];

    private _selectedPackageType: IPackageType;
    public get selectedPackageType() {
        return this._selectedPackageType;
    }
    @Input() public set selectedPackageType(packageType: IPackageType) {
        this._selectedPackageType = packageType;
        this.selectedPackageTypeChange.emit(this._selectedPackageType);
    }
    @Input() public actions: WorkflowConfigurationActionModel[];
    @Input() public downloadAction: WorkflowConfigurationActionModel;
    @Output() public selectedPackageTypeChange = new EventEmitter<IListBoxItem>();
    @Input() public allowedPackageTypes: EPackageType[] = [];

    public packageTypes: IPackageType[] = [];
    private onDestroySubject = new Subject<void>();

    public ngOnInit(): void {
        this.getPackageTypes();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getPackageTypes(): void {
        if (this.downloadAction) {
            const downloadActionOptions = {
                packages: this.downloadAction.options.find((option) => option.name === EWorkflowConfigurationActionOptionName.PACKAGES)
            } as IDownloadActionOptions;

            const packagesValue = downloadActionOptions.packages?.value;
            this.packageTypes = packagesValue?.reduce((acc, item) => {
                if (item.type === EPackageType.CUSTOM_EXPORT_PACKAGE) {
                    const packageType = {
                        name: item.options.find((option) => option.name === EPackageOptions.NAME)?.value,
                        action: EDownloadActionName.DOWNLOAD_CUSTOM_EXPORT_PACKAGE,
                        packageType: EPackageType.CUSTOM_EXPORT_PACKAGE,
                        description: item.options.find((option) => option.name === EPackageOptions.DESCRIPTION)?.value,
                        icon: AppConstants.ICONS.CUSTOM,
                        size: item.options.find(option => option.name === EPackageOptions.SIZE)?.value,
                        format: item.options.find(option => option.name === EPackageOptions.FORMAT)?.value
                    };
                    acc.push(packageType);
                } else {
                    const foundPackageType =
                        FilesDownloadPackageTypeComponent.allPackageTypes.find((packageType) => packageType.packageType === item.type);
                    if (foundPackageType) acc.push(foundPackageType);
                }
                return acc;
            }, []);
        } else {
            this.packageTypes =
                FilesDownloadPackageTypeComponent.allPackageTypes.filter(
                    (packageType) => this.actions.some((action) => action.name === packageType.action));

            this.actions.filter(action => action.name === EDownloadActionName.DOWNLOAD_CUSTOM_EXPORT_PACKAGE).forEach((action) => {
                const packageType = {
                    name: action.options.find((option) => option.name === EPackageOptions.NAME)?.value,
                    action: EDownloadActionName.DOWNLOAD_CUSTOM_EXPORT_PACKAGE,
                    packageType: EPackageType.CUSTOM_EXPORT_PACKAGE,
                    description: action.options.find((option) => option.name === EPackageOptions.DESCRIPTION)?.value,
                    icon: AppConstants.ICONS.CUSTOM,
                    size: action.options.find(option => option.name === EPackageOptions.SIZE)?.value,
                    format: action.options.find(option => option.name === EPackageOptions.FORMAT)?.value
                };
                this.packageTypes.push(packageType);
            });
        }

        if (this.allowedPackageTypes.length) {
            this.packageTypes = this.packageTypes.filter(packageType => this.allowedPackageTypes.includes(packageType.packageType));
        }
    }
}
