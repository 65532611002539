import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DataFilterDropdownComponent} from '../data-filter-dropdown/data-filter-dropdown.component';
import {RDModule} from '@relayter/rubber-duck';
import {LowerCasePipe} from '@angular/common';

@Component({
    selector: 'rl-data-filter-suggestive',
    templateUrl: './data-filter-suggestive.component.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DataFilterSuggestiveComponent),
            multi: true
        }],
    imports: [
        RDModule,
        LowerCasePipe
    ]
})
export class DataFilterSuggestiveComponent extends DataFilterDropdownComponent implements ControlValueAccessor {
    @Input() public placeholder: string;

    public onSearchTermChanged(search: string): void {
        this.search = search;

        this.getDataValues(0);
    }
}
