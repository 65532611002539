import {autoserialize} from 'cerialize';
import {
    EPackageOptions
// eslint-disable-next-line max-len
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {IComponentOptionValue} from './workflow-configuration-component.model';
import {IActionOptionValue} from './workflow-configuration-action.model';

export enum EWorkflowConfigurationActionOptionName {
    ADD_PACKAGE_DATA = 'ADD_PACKAGE_DATA',
    ADD_NOTES = 'ADD_NOTES',
    ENGINE_TYPES = 'ENGINE_TYPES',
    CHANNEL = 'CHANNEL',
    CAMPAIGN_ITEM_FIELDS = 'CAMPAIGN_ITEM_FIELDS',
    TEMPLATE_FILTER_CAMPAIGN_TAGS = 'TEMPLATE_FILTER_CAMPAIGN_TAGS',
    TEMPLATE_FILTER_TEMPLATE_TAGS = 'TEMPLATE_FILTER_TEMPLATE_TAGS',
    TEMPLATE_FILTER_TEMPLATE_TYPES = 'TEMPLATE_FILTER_TEMPLATE_TYPES',
    AUTO_ASSIGN = 'AUTO_ASSIGN',
    DISPLAY = 'DISPLAY',
    NOTIFICATION = 'NOTIFICATION',
    PACKAGES = 'PACKAGES',
    ADD_FOR_RELATED_PUBLICATION_ITEMS = 'ADD_FOR_RELATED_PUBLICATION_ITEMS',
    REMOVE_SIGN_OFFS = 'REMOVE_SIGN_OFFS',
    REQUIRED_FILES = 'REQUIRED_FILES'
}

export enum EWorkflowConfigurationActionOptionNotificationType {
    NOTE_UPDATE = 'NOTE_UPDATE',
    NOTE_COMMENT = 'NOTE_COMMENT'
}

export enum EWorkflowConfigurationActionOptionDisplayType {
    SINGLE = 'SINGLE',
    MULTI = 'MULTI'
}

export enum EWorkflowConfigurationActionOptionNotificationReceiver {
    NOTE_CHANGELOG = 'NOTE_CHANGELOG',
    NOTE_CREATOR = 'NOTE_CREATOR',
    ALL_NOTE_COMMENTERS = 'ALL_NOTE_COMMENTERS',
    USER_IDS = 'USER_IDS'
}

export enum EWorkflowConfigurationActionOptionSignOffs {
    KEEP = 'KEEP',
    REMOVE = 'REMOVE',
    SHOW_CONFIRM = 'SHOW_CONFIRM',
}

export enum EWorkflowConfigurationComponentOptionName {
    COLUMN_CONFIG = 'COLUMN_CONFIG',
    ASSETS_FILTER = 'ASSETS_FILTER'
}

export class WorkflowConfigurationOptionModel {
    // TODO: remove EPackageOptions here when we migrate all download actions to DOWNLOAD action with PACKAGES option
    @autoserialize public name: EWorkflowConfigurationActionOptionName | EWorkflowConfigurationComponentOptionName | EPackageOptions;
    @autoserialize public value: IActionOptionValue | IComponentOptionValue;
}
