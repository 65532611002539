import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {ARApiError, ARLogger} from '@relayter/core';
import {TIME_OUT_TYPES, Toaster} from '../../classes/toaster.class';
import {SortDirection} from '@angular/material/sort';
import {JobModel} from '../../models/api/job.model';
import {
    BUTTON_TYPE,
    ButtonConfig,
    EColumnDataType,
    FullModalActionModel,
    FullModalService,
    IListBoxItem,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {ErrorConstants} from '../../api/error.constants';
import {VariantModel} from '../../models/api/variant.model';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {VariantService} from '../../api/services/variant.service';
import {forkJoin, Observable} from 'rxjs';
import {EDataFieldCollectionName, EExportType, EFormStatus} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {EProductJobTypes, ProductService} from '../../api/services/products.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignService, ECampaignJobTypes} from '../../api/services/campaigns.service';
import {IExportDataFieldCollectionJobData} from '../../api/services/data-fields.service';

export interface IExportDataFieldCollectionData {
    contextId?: string;
    sortProperty: string[];
    sortOrder: SortDirection;
    cursorDuplicates: boolean;
    dataTypes: EColumnDataType[];
    dataFieldCollection: EDataFieldCollectionName
}

interface IExportType extends IListBoxItem {
    name: string;
    exportType: EExportType;
    description: string;
    icon: string;
}

class ExportForm {
    exportType: FormControl<string>;
    variant?: FormControl<string>;
}

@Component({
    selector: 'export-data-field-collection-component',
    templateUrl: 'export-data-field-collection.component.html',
    styleUrls: ['export-data-field-collection.component.scss'],
    standalone: false
})

export class ExportDataFieldCollectionComponent implements OnInit {
    private static readonly DATA_FIELD_COLLECTION_DISPLAY_NAME = {
        [EDataFieldCollectionName.CAMPAIGN_ITEM]: 'briefing item',
        [EDataFieldCollectionName.PRODUCT]: 'products'
    };

    public exportFileTypes: IExportType[] = [
        {
            name: 'EXCEL',
            exportType: EExportType.XLS,
            description: `Export ${this.displayDataFieldCollectionName} to the Excel file format`,
            icon: '/assets/images/icon_xls.svg'
        },
        {
            name: 'CSV',
            exportType: EExportType.CSV,
            description: `Export ${this.displayDataFieldCollectionName} to a CSV file format`,
            icon: '/assets/images/icon_csv.svg'
        },
        {
            name: 'XLIFF',
            exportType: EExportType.XLIFF,
            description: `Export ${this.displayDataFieldCollectionName} to a XLIFF file format`,
            icon: '/assets/images/icon_xliff.svg'
        }
    ];

    public isLoading: boolean = false;

    protected downloadButton: ButtonConfig;

    public variantEnabled: boolean;
    public variants: VariantModel[];
    public selectedVariant: VariantModel;
    public formGroup: UntypedFormGroup;

    protected destroyRef = inject(DestroyRef);

    get displayDataFieldCollectionName (): string {
        return ExportDataFieldCollectionComponent.DATA_FIELD_COLLECTION_DISPLAY_NAME[this.modalData.dataFieldCollection];
    }

    constructor(private campaignService: CampaignService,
                private productService: ProductService,
                private fullModalService: FullModalService,
                private variantService: VariantService,
                private dataFieldsService: DataFieldsApiService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IExportDataFieldCollectionData) {
    }

    public ngOnInit(): void {
        this.getData();
    }

    private getData(): void {
        forkJoin({
            variantsData: this.variantService.getVariants(),
            dataFields: this.dataFieldsService.getAllDataFields(this.modalData.dataFieldCollection)
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ({dataFields, variantsData}) => {
                    this.variants = variantsData.items;
                    this.variantEnabled = dataFields.some(field => field.enableVariants);
                    if (this.variants?.length > 0) {
                        this.selectedVariant = this.variants[0];
                    }
                    if (this.variantEnabled && this.variants.length > 0) this.selectedVariant = this.variants[0];

                    this.initForm();
                    this.initButtons();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        this.formGroup = new FormGroup<ExportForm>({
            exportType: new FormControl<string>(null, Validators.required)
        });

        if (this.variantEnabled) {
            this.formGroup.addControl('variant', new FormControl<string>(this.selectedVariant?.getValue(), Validators.required));
        }
    }

    private initButtons(): void {
        this.downloadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Export', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const download = new FullModalActionModel(this.downloadButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        download.observable.subscribe(() => this.onDownloadButtonClicked());

        const actions = [cancel, download];
        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((isValid: boolean) => this.downloadButton.disabled = !isValid);
    }

    public onDownloadButtonClicked(): void {
        this.downloadButton.loading = true;

        const selectedExportType = this.formGroup.value.exportType;
        const selectedVariantKey = this.formGroup.value.variant;

        const jobData = {
            exportType: selectedExportType.exportType,
            variantKey: selectedVariantKey,
            sort: this.modalData.sortProperty,
            sortType: this.modalData.sortOrder ? this.modalData.sortOrder.toUpperCase() : undefined,
            cursorDuplicates: this.modalData.cursorDuplicates,
            dataTypes: this.modalData.dataTypes,
            ...(this.modalData.contextId && { contextId: this.modalData.contextId })
        } as IExportDataFieldCollectionJobData;

        let job: Observable<JobModel>;
        switch (this.modalData.dataFieldCollection) {
            case EDataFieldCollectionName.CAMPAIGN_ITEM:
                job = this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_EXPORT_BRIEFING_JOB, jobData);
                break;
            case EDataFieldCollectionName.PRODUCT:
                job = this.productService.postJob(EProductJobTypes.PRODUCT_EXPORT_JOB, jobData);
                break;
            default:
                throw new Error(`Data field collection '${this.displayDataFieldCollectionName}' is not supported`);
        }

        job
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (scheduleJob: JobModel) => {
                    ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                    this.downloadButton.loading = false;
                    this.fullModalService.close();
                },
                error: (err: ARApiError) => {
                    err.code !== ErrorConstants.API_ERROR_CODES.NOT_FOUND_DEFAULT ?
                        Toaster.handleApiError(err) :
                        // As an exception override time out to make it clearer to the user that there are no briefing items found to export
                        Toaster.warn(err.message, null, {timeout: TIME_OUT_TYPES.LONG});

                    this.downloadButton.loading = false;
                    this.fullModalService.close();
                }
            });
    }

    public onVariantChanged(variant: VariantModel): void {
        this.selectedVariant = variant;
    }
}
