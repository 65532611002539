import {autoserialize, autoserializeAs} from 'cerialize';
import {EDataFieldTypes} from '../../app.enums';
import {ITableItem} from '@relayter/rubber-duck';
import {RuleConditionModel} from './rule-condition.model';

export enum EWorkflowConfigurationFilterType {
    ITEM_FIELD_FILTER =  'ITEM_FIELD_FILTER',
    CONDITIONS_FILTER = 'CONDITIONS_FILTER',
    EXTERNAL_PUBLICATION_ITEMS = 'EXTERNAL_PUBLICATION_ITEMS'
}

export class FilterItem {
    @autoserialize public title: string;
    @autoserialize public value: string;
    @autoserializeAs(RuleConditionModel) public conditions: RuleConditionModel[];
}

export class Filters {
    @autoserialize public tags: string[];
}

export class WorkflowConfigurationFilterModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public queryParam: string;
    @autoserialize public filterName: EWorkflowConfigurationFilterType;
    @autoserialize public custom: boolean;
    @autoserialize public dataType: EDataFieldTypes;
    @autoserializeAs(FilterItem) public items: FilterItem[] = [];
    @autoserializeAs(Filters) public filters: Filters;

    constructor(name?: string, queryParams?: string, custom?: boolean) {
        this.name = name;
        this.queryParam = queryParams;
        this.custom = !!custom;
    }
}
