import {Component, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig, DialogCustomContentActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';

export interface IListGroup {
    title?: string;
    list: string[];
}

export interface IListDialogData {
    listGroups: IListGroup[];
    actionButton?: IActionButton | null;
}

export interface IActionButton {
    name: string;
    type: BUTTON_TYPE;
}

@Component({
    selector: 'list-dialog',
    templateUrl: 'list-dialog.component.html',
    styleUrls: ['list-dialog.component.scss'],
    standalone: false
})

export class ListDialogComponent implements OnInit {
    public listGroups: IListGroup[];
    public actionButton: IActionButton | null;

    constructor(private dialogService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) protected modalData: IListDialogData) {
        this.listGroups = modalData.listGroups;
        this.actionButton = modalData.actionButton;
    }

    public ngOnInit(): void {
        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancelAction = new DialogCustomContentActionModel(cancelButton);
        cancelAction.observable.subscribe(() => this.dialogService.close(false));
        const buttons = [cancelAction];

        if (this.actionButton) {
            const customButton = new DialogButtonConfig(this.actionButton.type, this.actionButton.name);
            const customAction = new DialogCustomContentActionModel(customButton);
            customAction.observable.subscribe(() => this.dialogService.close(true));
            buttons.push(customAction);
        }

        this.dialogService.setDialogActions(buttons);
    }
}
