<ng-container>
    <div class="list-view">
        <div class="content-wrapper">
            <div class="header">
                <nuc-input class="search"
                           [(data)]="searchValue"
                           suffix="nucicon_search"
                           [textPosition]=""
                           (debounceValueChanged)="refreshData()"
                           placeholder="Search...">
                </nuc-input>

                <div class="sort-container">
                    <div class="popout-button">
                        <nuc-button-secondary text="Sort notes"
                                              icon="nucicon_selector"
                                              [disabled]="false"
                                              [nucTooltip]="sortValue.getTitle()"
                                              iconPosition="start"
                                              [matMenuTriggerFor]="menu">
                        </nuc-button-secondary>

                        <mat-menu #menu="matMenu" class="menu-items-container">
                            <button mat-menu-item
                                    *ngFor="let sortOption of sortOptions"
                                    (click)="onSortChanged(sortOption, sortAsc)"
                                    [ngClass]="sortValue === sortOption && 'selected-option'"
                                    [disableRipple]="true">
                                {{sortOption.getTitle()}}
                            </button>
                        </mat-menu>
                    </div>

                    <nuc-button-secondary
                        [icon]="sortAsc ? 'nucicon_sort_asc' : 'nucicon_sort_dsc'"
                        [nucTooltip]="sortAsc ? 'Sort ascending' : 'Sort descending'"
                        [disabled]="loading || sortValue.getValue() === EPreviewStickyListSortProperty.PUBLICATION_ITEM"
                        (click)="onSortChanged(sortValue, !sortAsc)">
                    </nuc-button-secondary>
                </div>

                <sticky-list-data-filter [filters]="dataFilters"></sticky-list-data-filter>
            </div>
            <ng-container *ngIf="stickies.length > 0 else emptyState">
                <div *ngFor="let note of stickies; first as first" class="note-item"
                     (click)="selectStickyNote(note)"
                     [class.border-top]="first">
                    <div class="note-header">
                        <div class="user-info-date">
                            <img src="/assets/images/icon_profile.svg"/>
                            <div class="info-container">
                                <p class="body-strong black text-ellipsis"
                                    [nucTooltip]="note.createdBy?.fullName">{{note.createdBy?.fullName | emptyValueFormatter}}</p>
                                <span class="body-strong">{{note.createdAt | RLDatePipe:dateFormats.STICKY_DATE}}</span>
                            </div>
                        </div>
                        <img class="pin"
                             [src]="note.status | getNotePinImagePipe"
                             nucTooltipPosition="left"
                             [nucTooltip]="note.status"
                             (click)="selectPubItem(note, $event)"/>
                    </div>
                    <p class="message text-ellipsis">{{note.message}}</p>
                    @if (note.timeRange) {
                        <div class="sticky-note-time-range">
                            <div class="start-time">
                                <span>Starts:</span>
                                <nuc-input [disabled]="true" [data]="note.timeRange.start | durationMedia"></nuc-input>
                            </div>
                            <div class="duration-time">
                                <span>Duration:</span>
                                <nuc-input [disabled]="true" [data]="(note.timeRange.end - note.timeRange.start) | durationMedia"></nuc-input>
                            </div>
                        </div>
                    }
                    @if (note.campaignItem) {
                        <div class="briefing-info">
                            <div class="briefing-property" *ngFor="let prop of briefingItemFields">
                                <p class="body-strong text-ellipsis">{{prop.label}}</p>
                                <p class="text-ellipsis">{{note.campaignItem.dataFields | getProperty:prop.property:dataFieldFormatter | emptyValueFormatter}}</p>
                            </div>
                        </div>
                    } @else {
                        <p class="no-linked-item">No linked briefing item.</p>
                    }
                    <hr/>
                </div>
            </ng-container>
            <div class="footer" *ngIf="hasNext">
                <nuc-button-secondary (click)="loadMore()" text="Show more"></nuc-button-secondary>
            </div>
        </div>

    </div>
</ng-container>
<ng-template #emptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/sticky_list_small.svg"
                               title="There are no notes for this item."
                               [small]="true">
    </nuc-empty-state-component>
</ng-template>
