import { Component } from '@angular/core';

@Component({
    selector: 'no-permission-state-component',
    templateUrl: './no-permission-state.component.html',
    styleUrls: [],
    standalone: false
})
export class NoPermissionStateComponent {

}
