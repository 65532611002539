import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges, viewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {take} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {ActivatedRoute} from '@angular/router';
import {DataFieldModel} from '../../models/api/data-field.model';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {DataFilterUtil, EDataFilterContext} from './data-filter.util';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DataFilterTemplateComponent} from './data-filter-template/data-filter-template.component';
import {OverlayButtonComponent, RDModule} from '@relayter/rubber-duck';
import {EDataFieldCollectionName} from '../../app.enums';

@Component({
    selector: 'data-filter',
    templateUrl: './data-filter.component.html',
    styleUrls: ['./data-filter.component.scss'],
    imports: [
        DataFilterTemplateComponent,
        RDModule
    ]
})
export class DataFilterComponent implements OnInit, OnChanges {
    public overlayButton = viewChild<OverlayButtonComponent>(OverlayButtonComponent);

    protected dataFieldsService = inject(DataFieldsApiService);
    protected advancedFiltersDataService = inject(AdvancedFiltersDataService);
    protected route = inject(ActivatedRoute);
    protected destroyRef = inject(DestroyRef);

    public activeFilterCount: number = 0;
    public filtersForm: FormGroup<Record<string, any>>;

    @Input() public filters: DataFilterModel[] = [];
    @Input() public disabledFilters: string[] = [];
    @Input() public initialValues: Record<string, any>;
    @Input() public dataFilterContext: EDataFilterContext;
    @Input() public requestOptions: IDataFieldFilterOptions = {};
    @Input() public variantKey: string;

    protected dataFields: DataFieldModel[];

    public ngOnInit(): void {
        let collectionName: EDataFieldCollectionName;
        switch (this.dataFilterContext) {
            case EDataFilterContext.PRODUCT:
                collectionName = EDataFieldCollectionName.PRODUCT
                break;
            case EDataFilterContext.ASSET:
                collectionName = EDataFieldCollectionName.ASSET
                break;
            case EDataFilterContext.CAMPAIGN_ITEM:
                collectionName = EDataFieldCollectionName.CAMPAIGN_ITEM
                break;
        }
        // Create inputs for data fields
        if (collectionName) {
            this.dataFieldsService.getAllDataFields(collectionName)
                .pipe(take(1), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: dataFields => {
                        // Only showInFilter
                        this.dataFields = dataFields.filter(dataField => dataField.showInFilter);
                        this.createFilterInputs();
                        this.onApply();
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.dataFields = [];
            this.createFilterInputs();
            this.onApply();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.filters && !changes.filters.firstChange) || (changes.variantKey && !changes.variantKey.firstChange && this.dataFields?.length)) {
            this.createFilterInputs();
            this.onApply();
        }
    }

    public createFilterInputs(): void {
        this.filters = DataFilterUtil.createDataFilters(this.filters, this.dataFields, this.disabledFilters, this.variantKey, this.requestOptions);

        const initialFormValues = {...this.initialValues, ...this.route.snapshot.params};
        this.filtersForm = DataFilterUtil.createFilterFormGroup(this.filters, this.filtersForm?.value, initialFormValues);
    }

    public onApply(): void {
        this.filtersForm.markAsPristine();

        const filterValues = DataFilterUtil.getFilterValues(this.filtersForm.value);
        this.activeFilterCount = DataFilterUtil.countActiveFilters(filterValues);

        // update the values in AdvancedFiltersDataService so listeners get updates
        this.advancedFiltersDataService.setFilterValues(filterValues);

        this.overlayButton().detachOverlay();
    }

    // On reset, also call onApply() to trigger the emission of the empty form
    public onReset(): void {
        this.filtersForm.reset();
        this.filtersForm = DataFilterUtil.createFilterFormGroup(this.filters, this.filtersForm?.value, this.initialValues);
        this.onApply();
    }

}
