import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PropertySettingsModel} from './property-settings.model';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';

export enum EPropertySettingsContext {
    PRODUCT = 'product',
    BRIEFING = 'briefing',
    ASSET = 'asset',
    PROJECT = 'project'
}

@Injectable({
    providedIn: 'root'
})
export class PropertySettingsService {
    private settingsSubject: BehaviorSubject<PropertySettingsModel> =  new BehaviorSubject<PropertySettingsModel>(null);

    constructor(private userSettingsStorageService: UserSettingsStorageService) {}

    private static getSettingsKey(propertySettingsContext: EPropertySettingsContext): string {
        // BRIEFING was the first property, with key 'property-settings' instead of 'briefing-property-settings'
        // We cannot change this because users will lose their current settings
        // TODO remove this when all properties are also stored as 'briefing-property-settings'
        if (propertySettingsContext === EPropertySettingsContext.BRIEFING) return 'property-settings';

        return `${propertySettingsContext}-property-settings`;
    }

    public getSettings(propertySettingsContext: EPropertySettingsContext): Observable<PropertySettingsModel> {
        const settings = this.getSettingsFromStorage(propertySettingsContext);
        this.settingsSubject.next(settings);

        // TODO remove this when all properties are also stored as 'briefing-property-settings'
        if (propertySettingsContext === EPropertySettingsContext.BRIEFING) {
            this.userSettingsStorageService.storeSettings<PropertySettingsModel>(
                `${propertySettingsContext}-property-settings`, settings, PropertySettingsModel
            );
        }

        return this.settingsSubject.asObservable();
    }

    public storeSettings(settings: PropertySettingsModel, propertySettingsContext: EPropertySettingsContext): void {
        const key = PropertySettingsService.getSettingsKey(propertySettingsContext);
        this.userSettingsStorageService.storeSettings<PropertySettingsModel>(key, settings, PropertySettingsModel);
        this.settingsSubject.next(settings);
    }

    private getSettingsFromStorage(propertySettingsContext: EPropertySettingsContext): PropertySettingsModel {
        const key = PropertySettingsService.getSettingsKey(propertySettingsContext);
        const settings = this.userSettingsStorageService.loadSettings(key, PropertySettingsModel);
        return settings ? settings : new PropertySettingsModel();
    }
}
