/**
 * Created by nandamhuis on 09/01/2017.
 */

import {Component, Input} from '@angular/core';

@Component({
    selector: 'rl-loading-indicator',
    templateUrl: 'loading-indicator.component.html',
    styleUrls: ['loading-indicator.component.scss'],
    host: {
        '[class.loaded]': 'contain',
        '[class.hide]': 'hide'
    },
    standalone: false
})

export class LoadingIndicatorComponent {
    @Input() public hide: boolean;
    @Input() public inverse: boolean = false;
    @Input() public contain: boolean = false;
    @Input() public size: string = 'regular';
}
