import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
    DesignLibraryDetailModel
} from '../../../../modules/static-content-rulesets/models/api/design-library.model';
import {ActivatedRoute} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {Subscription} from 'rxjs';
import {DesignLibraryApiService} from '../../../../api/services/design-library.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-library-detail',
    templateUrl: 'library-detail.component.html',
    styleUrls: ['library-detail.component.scss'],
    standalone: false
})

export class LibraryDetailComponent implements OnInit {
    public librarySubscription: Subscription;
    public library: DesignLibraryDetailModel;
    protected destroyRef: DestroyRef = inject(DestroyRef);

    constructor(private designLibraryApiService: DesignLibraryApiService,
                private route: ActivatedRoute) {}

    public ngOnInit(): void {
        const libraryId = this.route.snapshot.params['libraryId'];

        this.librarySubscription = this.designLibraryApiService
            .getDesignLibraryDetails(libraryId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (library: DesignLibraryDetailModel) => this.library = library,
                    error: Toaster.handleApiError
                }
            );
    }
}
