import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'conditionalValuePipe',
    standalone: false
})
export class ConditionalValuePipe implements PipeTransform {
    public transform(condition: boolean, trueValue = '', falseValue = ''): string {
        return condition ? trueValue : falseValue;
    }
}
