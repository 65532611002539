import {
    AfterViewInit,
    ComponentRef,
    Directive,
    ElementRef,
    inject,
    input, NgZone,
    OnInit,
    Renderer2,
    ViewContainerRef
} from '@angular/core';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {ResizableBorderComponent} from '../../components/resizable-border/resizable-border.component';

export enum EResizableOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical'
}

@Directive({
    selector: '[resizable]',
    standalone: false
})
export class ResizableDirective implements OnInit, AfterViewInit {
    private static readonly STORAGE_KEY = 'resizable';

    private unListenMouseMove: () => void;
    private unListenMouseUp: () => void;

    // Directive optional input
    public resizableId = input<string>(null); // Unique id to store position of the slider
    public resizableOrientation = input<EResizableOrientation>(EResizableOrientation.HORIZONTAL);

    private startPosition: number = 0;
    private startSize: number = 0;
    private resizing: boolean = false;

    // Injectables
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);
    private viewContainerRef = inject(ViewContainerRef);
    private settingsStorageService = inject(UserSettingsStorageService);
    private ngZone = inject(NgZone);
    private divider: ComponentRef<ResizableBorderComponent>;
    private style: string;

    public ngOnInit(): void {
        this.divider = this.viewContainerRef.createComponent(ResizableBorderComponent);
        this.divider.instance.isHorizontal = this.isHorizontal();
        this.style = this.isHorizontal() ? 'width' : 'height';

        if (this.resizableId()) {
            const size = this.loadResizableData(this.resizableId());
            if (size) this.renderer.setStyle(this.el.nativeElement, this.style, `${size}px`);
        }
    }

    ngAfterViewInit() {
        this.renderer.listen(this.divider.location.nativeElement, 'mousedown', (event) => {
            this.onMouseDown(event)
        })
    }

    private isHorizontal(): boolean {
        return this.resizableOrientation() === EResizableOrientation.HORIZONTAL;
    }

    public onMouseDown(event: MouseEvent) {
        this.ngZone.runOutsideAngular(() => {
            this.unListenMouseUp = this.renderer.listen('document', 'mouseup', () => {
                this.onMouseUp()
            });
            this.unListenMouseMove = this.renderer.listen('document', 'mousemove', (event) => {
                this.onMouseMove(event)
            });

            this.resizing = true;
            this.startPosition = this.isHorizontal() ? event.clientX : event.clientY;
            this.startSize = this.isHorizontal() ? this.el.nativeElement.clientWidth : this.el.nativeElement.clientHeight;

            this.renderer.setStyle(document.body, '-webkit-user-select', 'none');
            this.renderer.setStyle(document.body, 'user-select', 'none');
        });
    }

    private onMouseUp() {
        this.resizing = false;

        if (this.resizableId()) {
            this.storeResizableData(this.resizableId(), this.isHorizontal() ? this.el.nativeElement.clientWidth : this.el.nativeElement.clientHeight);
        }

        this.renderer.removeStyle(document.body, '-webkit-user-select');
        this.renderer.removeStyle(document.body, 'user-select');

        this.unListenMouseUp();
        this.unListenMouseMove();
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.resizing) {
            return;
        }

        const dx = this.isHorizontal() ? event.clientX - this.startPosition : event.clientY - this.startPosition;

        this.renderer.setStyle(this.el.nativeElement, this.style, `${this.startSize + dx}px`);
    }

    private loadResizableData(id: string): number {
        const resizableSettings = this.settingsStorageService.loadSettings(ResizableDirective.STORAGE_KEY) ?? {};

        return resizableSettings[id];
    }

    private storeResizableData(id: string, width: number): void {
        const resizableSettings = this.settingsStorageService.loadSettings(ResizableDirective.STORAGE_KEY) ?? {};

        resizableSettings[id] = width;
        this.settingsStorageService.storeSettings(ResizableDirective.STORAGE_KEY, resizableSettings);
    }
}
