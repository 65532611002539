import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EColumnType, ESelectionMode, IActionClickEvent, ITableColumn, ITableItem, TableDataProvider} from '@relayter/rubber-duck';
import {AppConstants} from '../../app.constants';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';

@Component({
    selector: 'multi-item-selection-new-component',
    templateUrl: './multi-item-selection-new.component.html',
    styleUrls: ['./multi-item-selection-new.component.scss'],
    standalone: false
})
export class MultiItemSelectionNewComponent extends RLTableComponent implements OnInit, OnChanges {
    @Input() public tableId: string;
    @Input() public viewId: string;
    @Input() columns: ITableColumn[];
    @Input() public tableDataProvider: TableDataProvider;
    @Input() public selectionMode: ESelectionMode.EXPAND|ESelectionMode.MULTI|ESelectionMode.MULTI_ALL = ESelectionMode.MULTI;
    @Input() public selectedItems: ITableItem[];
    @Input() public selectionTitle = 'items';
    @Input() public noSelectedItemsTitle: string;
    @Input() public noResultsTitle: string;
    @Input() public noTabBar = false;
    @Input() public readOnly = false;
    @Input() public disableSort = false;
    @Input() public disablePageSizeOptions = false;
    @Input() public actions = [];

    @Output() public actionClickedEvent: EventEmitter<IActionClickEvent> = new EventEmitter<IActionClickEvent>();
    @Output() public tableSortOptionsChange = new EventEmitter<void>();

    public iconColumn: ITableColumn = {
        title: 'Selected',
        key: 'selected',
        type: EColumnType.ICON,
        iconClass: () => 'nucicon_check_round_fill',
        color: AppConstants.FIRST_BRAND_COLOR
    };
    public selectedColumns: ITableColumn[];
    private defaultActions = [AppConstants.TABLE_ACTION_TYPES.DELETE];

    public tableActions = [];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private changeDetectorRef: ChangeDetectorRef) {
        super(userSettingsStorageService);

        if (!this.tableDataProvider) {
            this.tableDataProvider = new TableDataProvider();
        }
    }

    public ngOnInit(): void {
        if (this.readOnly) {
            this.tableActions = this.actions;
            this.selectionMode = ESelectionMode.EXPAND;
        } else {
            this.tableActions = this.actions.concat(this.defaultActions);
        }

        if (!this.viewId) this.viewId = this.tableId;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            this.selectedColumns = [this.iconColumn].concat(this.columns).map(column => {
                return {...column, sortProperty: undefined} as ITableColumn;
            });
        }
    }

    public handleTableAction(actionClickEvent: IActionClickEvent): void {
        // Keep old behaviour and handle delete action, this actions is also always added
        if (actionClickEvent.action.title === AppConstants.TABLE_ACTION_TYPES.DELETE.title) {
            this.tableDataProvider.deselect(actionClickEvent.item);
        } else {
            this.actionClickedEvent.emit(actionClickEvent);
        }
    }

    public handleClearSelection(): void {
        this.tableDataProvider.clear();
    }

    public onColumnsChanged(selectedColumnsChanged = false): void {
        if (selectedColumnsChanged) {
            // set column size, column visibility and order accordingly
            this.columns = this.selectedColumns
                .filter((selectedCol) => selectedCol.title !== this.iconColumn.title)
                .map((selectedCol) => {
                    const foundCol = this.columns.find((col) => col.title === selectedCol.title);
                    if (foundCol) {
                        foundCol.size = selectedCol.size;
                        foundCol.visible = selectedCol.visible;
                    }
                    return foundCol || selectedCol;
                });
        } else {
            const iconColumn = this.selectedColumns.find((col) => col.title === this.iconColumn.title);
            this.selectedColumns = this.columns.concat(iconColumn).map(column => {
                return {...column, sortProperty: undefined} as ITableColumn;
            });
        }
        this.changeDetectorRef.detectChanges();
    }
}
