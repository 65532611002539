import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {PublicationItemModel} from '../../models/api/publication-item.model';
import {LayoutNoteModel} from '../../models/api/layout-note.model';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';
import {WorkflowConfigurationFilterModel} from '../../models/api/workflow-configuration-filter.model';
import {WorkflowConfigurationFilterOptionModel} from '../../models/api/workflow-configuration-filter-option.model';
import {AppConstants} from '../../app.constants';
import {ARPagedResponseDataModel} from '@relayter/core';
import {RLCountsModel} from '../response/rl-counts.model';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {PublicationsService} from './publications.service';
import {NewCursor} from '../new-api-cursor';
import {TableSortOptions} from '../table-sort-options';

@Injectable({
    providedIn: 'root'
})
export class PublicationItemsApiService extends ApiService<PublicationItemModel> {
    constructor() {
        super([ApiConstants.API_GROUP_PUBLICATIONS], PublicationItemModel);
    }

    public getItemsForPublication(publicationId: string,
                                  workflowConfigurationStepId?: string,
                                  filterMap?: Map<WorkflowConfigurationFilterModel, WorkflowConfigurationFilterOptionModel[]>,
                                  limit = AppConstants.PAGE_SIZE_MAX,
                                  offset = 0,
                                  cursor?: NewCursor,
                                  tableSortOptions?: TableSortOptions,
                                  includeTotals?: boolean,
                                  populatePaths?: string[]): Observable<ARPagedResponseDataModel<PublicationItemModel, RLCountsModel>> {

        const request = new ApiPagedRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS
        ], this.model, limit, offset, tableSortOptions, cursor);
        request.additionalQueryParams
            .addParam('workflowConfigurationStepId', workflowConfigurationStepId)
            .addParam('includeTotals', !!includeTotals)
            .addParam('populatePaths', populatePaths);

        PublicationsService.addFiltersToParams(request.additionalQueryParams, filterMap);

        return this.apiRequestService.find<PublicationItemModel, RLCountsModel>(request);
    }

    public getAllItemsForPublication(
        publicationId: string,
        workflowConfigurationStepId?: string,
        filterMap?: Map<WorkflowConfigurationFilterModel, WorkflowConfigurationFilterOptionModel[]>,
        cursor?: NewCursor,
        tableSortOptions?: TableSortOptions,
        populatePaths?: string[]): Observable<PublicationItemModel[]> {
        const request = new ApiPagedRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS
        ], this.model, null, 0, tableSortOptions, cursor);
        request.additionalQueryParams
            .addParam('workflowConfigurationStepId', workflowConfigurationStepId)
            .addParam('populatePaths', populatePaths);

        PublicationsService.addFiltersToParams(request.additionalQueryParams, filterMap);

        return this.apiRequestService.findAll<PublicationItemModel>(request);
    }

    /**
     * Create new layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {object} layoutNote
     */
    public postLayoutNote(publicationId: string, publicationItemId: string, layoutNote: LayoutNoteModel): Observable<LayoutNoteModel> {
        const request = new ApiPostRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_LAYOUT_NOTES
        ], LayoutNoteModel, layoutNote)

        return this.apiRequestService.post(request);
    }

    /**
     * Update layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {object} layoutNote
     * @returns {Observable<boolean>}
     */
    public updateLayoutNote(publicationId: string, publicationItemId: string, layoutNote: LayoutNoteModel): Observable<PublicationItemModel> {
        const request = new ApiPatchRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_LAYOUT_NOTES
        ], PublicationItemModel, layoutNote._id, layoutNote)

        return this.apiRequestService.patch(request);
    }

    /**
     * Delete layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {string} layoutNoteId
     * @returns {Observable<boolean>}
     */
    public deleteLayoutNote(publicationId: string, publicationItemId: string, layoutNoteId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([
                ...this.groups,
                publicationId,
                ApiConstants.API_METHOD_ITEMS,
                publicationItemId,
                ApiConstants.API_METHOD_LAYOUT_NOTES
            ], layoutNoteId
        )

        return this.apiRequestService.delete(request);
    }
}
