import {UserModel} from '../../../../../models/api/user.model';
import {StickyNoteModel} from '../../../../../models/api/sticky-note.model';
import {WorkflowConfigurationActionModel, EStickyNoteActionName} from '../../../../../models/api/workflow-configuration-action.model';
import {AppConstants} from '../../../../../app.constants';
import {PublicationItemModel} from '../../../../../models/api/publication-item.model';
import {ContentAreaModel} from '../../../../../models/api/publication-item-content.model';
import {TemplateAreaModel} from '../../../../../models/api/template-area.model';
import {PointModel} from '../../../../../models/api/point.model';
import {CampaignItemModel} from '../../../../../models/api/campaign-item.model';
import {SizeModel} from '../../../../../models/api/size.model';
import {EStickyNoteStatus} from '../../../../../app.enums';

export class StickyNoteUtil {
    /**
     * Check if an action is possible from the current sticky note status
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel} action
     * @returns {boolean}
     */
    public static hasCorrectStatus(stickyNote: StickyNoteModel, action: WorkflowConfigurationActionModel): boolean {
        // handle NEW status the same as CREATED status
        const status = stickyNote?.status === EStickyNoteStatus.NEW ? EStickyNoteStatus.CREATED : stickyNote?.status;
        return action.from.includes(status);
    }

    /**
     * Returns a sticky note action if it exists and can be performed
     * @param {string} actionName
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel[]} actions
     * @returns {WorkflowConfigurationActionModel}
     */
    public static findAction(actionName: string, stickyNote: StickyNoteModel,
                             actions: WorkflowConfigurationActionModel[]): WorkflowConfigurationActionModel {
        return actions.find((action) =>
            action.name === actionName &&
            StickyNoteUtil.hasCorrectStatus(stickyNote, action));
    }

    /**
     * Returns an own sticky note action if it exists and can be performed
     * @param {string} actionName
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel[]} actions
     * @param {UserModel} user
     * @returns {WorkflowConfigurationActionModel}
     */
    public static findEditOwnAction(actionName: string,
                                    stickyNote: StickyNoteModel,
                                    actions: WorkflowConfigurationActionModel[],
                                    user: UserModel): WorkflowConfigurationActionModel {
        if (!StickyNoteUtil.isOwnStickyNote(stickyNote, user)) {
            return null;
        }

        return actions.find((action) =>
            action.name === EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_MESSAGE &&
            StickyNoteUtil.hasCorrectStatus(stickyNote, action));
    }

    /**
     * Returns an edit sticky note status action if it exists and can be performed
     * @param {EStickyNoteStatus} status
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel[]} actions
     * @returns {WorkflowConfigurationActionModel}
     */
    public static findEditStatusAction(status: EStickyNoteStatus,
                                       stickyNote: StickyNoteModel,
                                       actions: WorkflowConfigurationActionModel[]): WorkflowConfigurationActionModel {
        return actions.find((action) =>
            action.name === EStickyNoteActionName.EDIT_STICKY_NOTE_STATUS &&
            action.to === status &&
            StickyNoteUtil.hasCorrectStatus(stickyNote, action));
    }

    /**
     * Returns an edit own sticky note status action if it exists and can be performed
     * @param {EStickyNoteStatus} status
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel[]} actions
     * @param {UserModel} user
     * @returns {WorkflowConfigurationActionModel}
     */
    public static findEditOwnStatusAction(status: EStickyNoteStatus,
                                          stickyNote: StickyNoteModel,
                                          actions: WorkflowConfigurationActionModel[],
                                          user: UserModel): WorkflowConfigurationActionModel {
        if (!StickyNoteUtil.isOwnStickyNote(stickyNote, user)) {
            return null;
        }

        return actions.find((action) =>
            action.name === EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_STATUS &&
            action.to === status &&
            StickyNoteUtil.hasCorrectStatus(stickyNote, action));
    }

    /**
     * Returns an edit sticky note status action if it exists and can be performed
     * @param {StickyNoteModel} stickyNote
     * @param {WorkflowConfigurationActionModel[]} actions
     * @returns {WorkflowConfigurationActionModel[]}
     */
    public static findEditStatusActions(stickyNote: StickyNoteModel,
                                        actions: WorkflowConfigurationActionModel[]): WorkflowConfigurationActionModel[] {
        return actions.filter((action) =>
            action.name === EStickyNoteActionName.EDIT_STICKY_NOTE_STATUS &&
            StickyNoteUtil.hasCorrectStatus(stickyNote, action));
    }

    /**
     * check if a sticky note belongs to the user
     * @param {StickyNoteModel} stickyNote
     * @param {UserModel} user
     * @returns {boolean}
     */
    public static isOwnStickyNote(stickyNote: StickyNoteModel, user: UserModel): boolean {
        return stickyNote ? stickyNote.createdBy && stickyNote.createdBy?._id === user._id : false;
    }

    /**
     * Find the campaign item of the content area at the given position (positionX, positionY)
     */
    public static findCampaignItemForPosition(position: PointModel, publicationItem: PublicationItemModel): CampaignItemModel {
        // Get the dimensions for all the publication item content areas (when there is content, there is a template for the publication item)
        const campaignItemContent = publicationItem.content
            .filter(content => content.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM &&
                publicationItem.template.staticContent)
            .map((content) => {
                // transform content, add position and size
                const templateArea = publicationItem.template.staticContent.areas.find((item) => item._id === content.area._id);
                const contentArea = StickyNoteUtil.determineAreaFromContentWithTemplate(content.area, templateArea);
                return {...contentArea, ...content};
            });

        if (!campaignItemContent.length) return;

        // Calculate absolute position of the note inside the publication item
        const noteX = publicationItem.template.staticContent.pageSize.width * publicationItem.template.staticContent.numberOfPages * position.x / 100;
        const noteY = publicationItem.template.staticContent.pageSize.height * position.y / 100;

        return campaignItemContent.find(content =>
            content.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM
                && noteX >= content.position.x && noteX <= content.position.x + content.size.width
                && noteY >= content.position.y && noteY <= content.position.y + content.size.height
        )?.campaignItem;
    };

    /**
     * Pass in a 'content.area' object, which holds all the area information where a content item should be placed in
     * Transform column row information to size and position for the single area
     */
    public static determineAreaFromContentWithTemplate(area: ContentAreaModel,
                                                       templateArea: TemplateAreaModel): {size: SizeModel; position: PointModel} {
        // calculate the area item width and height for later calculation
        const itemWidth = (templateArea.size.width + templateArea.columnGutter) / templateArea.columns - templateArea.columnGutter;
        const itemHeight = (templateArea.size.height + templateArea.rowGutter) / templateArea.rows - templateArea.rowGutter;

        return {
            size: {
                width: area.columnSpan * (itemWidth + templateArea.columnGutter) - templateArea.columnGutter,
                height: area.rowSpan * (itemHeight + templateArea.rowGutter) - templateArea.rowGutter
            },
            position: {
                x: templateArea.position.x + area.startColumn * (itemWidth + templateArea.columnGutter),
                y: templateArea.position.y + area.startRow * (itemHeight + templateArea.rowGutter)
            }
        };
    }

}
