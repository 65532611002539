import {Component, Input, OnDestroy} from '@angular/core';
import {ContentChangeModel, EContentChangeAction, PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {AppConstants} from '../../../../../../../app.constants';
import {EmptyValuePipe} from '../../../../../../../pipes/empty-value.pipe';
import {PropertySettingsModel} from '../../../../../../../components/property-settings/property-settings.model';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {WorkflowConfigurationFilterModel} from '../../../../../../../models/api/workflow-configuration-filter.model';
import {WorkflowConfigurationFilterOptionModel} from '../../../../../../../models/api/workflow-configuration-filter-option.model';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {CampaignModel} from '../../../../../../../models/api/campaign.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {PublicationItemsApiService} from '../../../../../../../api/services/publication-items.api.service';

@Component({
    selector: 'rl-layout-change-indicator-component',
    templateUrl: './layout-change-indicator.component.html',
    styleUrls: ['./layout-change-indicator.component.scss'],
    providers: [],
    standalone: false
})
export class LayoutChangeIndicatorComponent implements OnDestroy {
    @Input() public contentChange: ContentChangeModel;
    @Input() public propertySettings: PropertySettingsModel;
    @Input() public publication: PublicationModel;
    @Input() public campaign: CampaignModel;
    @Input() public activeVariant: VariantModel;

    public readonly EContentChangeAction = EContentChangeAction;
    public readonly SPREAD_CONTENT_TYPES = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES;
    public readonly DELETED_ASSET = EmptyValuePipe.defaultValues.DELETED_ASSET;
    public readonly DELETED_BRIEFING_ITEM = EmptyValuePipe.defaultValues.DELETED_BRIEFING_ITEM;

    public loading: boolean; // search layout button loading state
    public foundItems: PublicationItemModel[];
    private onDestroySubject = new Subject<void>();

    constructor(private publicationItemsApiService: PublicationItemsApiService) {
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public findPublicationItems(campaignItemId: string): void {
        this.loading = true;
        const filterMap = new Map<WorkflowConfigurationFilterModel, WorkflowConfigurationFilterOptionModel[]>();
        const filter = new WorkflowConfigurationFilterModel('content.campaignItem', 'content.campaignItem');
        filterMap.set(filter, [new WorkflowConfigurationFilterOptionModel(campaignItemId, campaignItemId)]);
        this.publicationItemsApiService.getItemsForPublication(this.publication._id, undefined, filterMap)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (result) => {
                    this.foundItems = result.items;
                },
                error: Toaster.handleApiError
            });
    }
}
