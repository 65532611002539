import {computed, DestroyRef, Directive, inject, Input, OnInit, Signal} from '@angular/core';
import {FileTypeUtil} from '../../../../../../../classes/file-type.util';
import {EPublicationItemContext, PublicationsService} from '../../../../../../../api/services/publications.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel} from '@relayter/rubber-duck';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {combineLatest, forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {EUploadStatus} from '../../../../../../../components/upload-file-component/upload.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {AmazonService} from '../../../../../../../api/services/amazon.service';
import {
    KeyItemMapModel
} from '../../custom-workflow-files/custom-workflow-upload/custom-workflow-files-upload.component';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {DataFieldModel} from '../../../../../../../models/api/data-field.model';
import {EUploadCategory, UploadGroupModel} from '../../custom-workflow-files/custom-workflow-upload/upload-group.model';
import {DataFieldsApiService} from '../../../../../../../api/services/data-fields.api.service';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {EEngineType} from '../../../../../../../models/api/template.model';
import {EChannel} from '../../../../../../../app.enums';

enum EUploadState {
    INIT,
    EMPTY,
    UPLOADING,
    READY
}

export interface IEventData {
    itemIds: string[];
    uploads: KeyItemMapModel[];
}

interface IUpdateItemFileType {
    uploadCategory: EUploadCategory;
    title: string;
    dragTitle: string;
    subtitle: string;
    multiple?: boolean;
    active?: false; // if publicationItem already has specific files
    newFileUploaded?: boolean;
    required?: false;
    requiredFileTypes?: string[];
}

@Directive()
export abstract class UploadItemFilesFormComponent implements OnInit {
    protected readonly amazonService: AmazonService = inject(AmazonService);
    protected readonly VALIDATION_STATUS = UploadGroupModel.VALIDATION_STATUS;
    protected readonly EUploadStatus = EUploadStatus;
    protected readonly EUploadCategory = EUploadCategory;
    protected readonly destroyRef = inject(DestroyRef);

    public isInline: boolean;

    public allowedFileExtensions = {
        [EUploadCategory.EXPORT]: FileTypeUtil.getExtensionsFromFileCategories([
            FileTypeUtil.CATEGORIES.PDF,
            FileTypeUtil.CATEGORIES.IMAGE,
            FileTypeUtil.CATEGORIES.VIDEO]),
        [EUploadCategory.SOURCE]: FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES),
        [EUploadCategory.FILE]: FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES)
    };

    public publication: PublicationModel;
    public activeVariant: VariantModel;
    public workflowConfigurationLayoutId: string;
    public stepId: string;

    // Modal data
    public publicationItemId: string;
    public publicationItems: PublicationItemModel[];
    @Input() public requiredUploadCategories: EUploadCategory[];

    public publicationItem$ = new ReplaySubject<PublicationItemModel>(1);
    public publicationItem: Signal<PublicationItemModel> = toSignal(this.publicationItem$);

    public uploadItemFiles$ = new ReplaySubject<UploadGroupModel[]>(1);
    public uploadItemFiles: Signal<UploadGroupModel[]> = toSignal(this.uploadItemFiles$, {initialValue: []});

    private findFilesFunc = (category: EUploadCategory) =>
        this.uploadItemFiles().filter(item => item.uploadCategory == category);

    public exportFile: Signal<UploadGroupModel> = computed(() => {
        return this.findFilesFunc(EUploadCategory.EXPORT)[0];
    });
    public sourceFile: Signal<UploadGroupModel> = computed(() => {
        return this.findFilesFunc(EUploadCategory.SOURCE)[0];
    });
    public files: Signal<UploadGroupModel[]> = computed(() => {
        return this.findFilesFunc(EUploadCategory.FILE);
    });

    public dataFields: DataFieldModel[];
    protected campaignId: string;
    public saveClicked = false;

    public updateItemFileTypesConfig: Signal<IUpdateItemFileType[]> = computed(() => {
        if (!this.publicationItem()) return [];
        const result = UploadItemFilesFormComponent.getSubtitlesAndRequiredFileTypes(this.publicationItem());
        const publicationItemFiles = this.publicationItem().getVariantFiles(this.activeVariant?._id);
        return [
            {
                uploadCategory: EUploadCategory.EXPORT,
                title: 'Export file',
                dragTitle: 'export file',
                subtitle: result.exportFileSubtitle,
                active: !!publicationItemFiles?.preview,
                newFileUploaded: !!this.exportFile(),
                required: this.requiredUploadCategories?.includes(EUploadCategory.EXPORT),
                requiredFileTypes: result.exportFileTypes
            } as IUpdateItemFileType,
            {
                uploadCategory: EUploadCategory.SOURCE,
                title: 'Source file',
                dragTitle: 'source file',
                subtitle: result.sourceFileSubtitle,
                active: !!publicationItemFiles?.source,
                newFileUploaded: !!this.sourceFile(),
                required: this.requiredUploadCategories?.includes(EUploadCategory.SOURCE),
                requiredFileTypes: result.sourceFileTypes
            } as IUpdateItemFileType,
            {
                uploadCategory: EUploadCategory.FILE,
                title: 'Files',
                dragTitle: 'files',
                subtitle: 'Any files used in the source file.',
                multiple: true,
                active: !!this.publicationItem()?.attachments.length,
                newFileUploaded: !!this.files().length,
            } as IUpdateItemFileType
        ]
    });

    public uploadsReady$: Observable<EUploadState> = this.uploadItemFiles$.pipe(
        switchMap((uploadGroups: UploadGroupModel[]) => {
            if (uploadGroups.length === 0) {
                return of(EUploadState.EMPTY);
            }

            if (uploadGroups.some((uploadGroup) => !uploadGroup.uploads.length)) {
                return of(EUploadState.UPLOADING);
            }

            const progressDone = uploadGroups
                .map((uploadGroup) => uploadGroup.uploads)
                .reduce((acc, uploads) => [...acc, ...uploads])
                .map((upload) => upload.progress$.pipe(map((progress) => progress === EUploadStatus.Done)));

            const itemsValid = uploadGroups.map((uploadGroup) => uploadGroup.validationStatus$.pipe(
                map((status) => status === this.VALIDATION_STATUS.VALID)));

            return combineLatest([...progressDone, ...itemsValid])
                .pipe(map((results) => results.every((result) => !!result) ? EUploadState.READY : EUploadState.UPLOADING));
        }),
        startWith(EUploadState.INIT),
        shareReplay(1)
    );

    protected publicationsService: PublicationsService = inject(PublicationsService);
    protected customWorkflowService: CustomWorkflowService = inject(CustomWorkflowService);
    protected dataFieldsService: DataFieldsApiService = inject(DataFieldsApiService);

    // Buttons
    protected confirmAction: FullModalActionModel;
    protected confirmButton: ButtonConfig;
    protected secondaryAction: FullModalActionModel;
    protected actions: FullModalActionModel[] = [];

    public ngOnInit(): void {
        this.initButtons();

        this.initFormData();

        this.customWorkflowService.activeVariant$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(variant => this.activeVariant = variant);

        this.uploadsReady$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((uploadState) => {
                this.confirmButton.disabled = uploadState === EUploadState.UPLOADING;
            });
    }

    protected initButtons(): void {
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Confirm Upload');
        this.confirmAction = new FullModalActionModel(this.confirmButton);
        this.confirmAction.observable.subscribe(() => this.onSaveButtonClicked());

        // Used for modal
        const secondaryButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.secondaryAction = new FullModalActionModel(secondaryButton);
        this.secondaryAction.observable.subscribe(() => this.handleUploadData(null, true));
    }

    /**
     * Responds to file drops
     * @param {File[]} files
     * @param {EUploadCategory} itemUploadCategory
     */
    public onFilesChanged(files: File[], itemUploadCategory: EUploadCategory): void {
        if (files.length > 0) {
            let filteredFiles = files.filter(
                (file) => this.allowedFileExtensions[itemUploadCategory].some(fileExtensionGroup =>
                    fileExtensionGroup.includes(FileTypeUtil.getExtensionFromFileName(file.name))));

            if (filteredFiles.length !== files.length) {
                Toaster.warn('One or more files do not have the correct filetype');
            }

            if (itemUploadCategory === EUploadCategory.FILE) {
                const attachmentFileNames = this.publicationItem().attachments.map((attachment) => attachment.fileName);
                const result = files.reduce((acc, file) => {
                    attachmentFileNames.includes(file.name)
                        ? acc.existingFileNames.push(file.name)
                        : acc.newFiles.push(file);
                    return acc;
                }, {existingFileNames: [], newFiles: []});

                if (result.existingFileNames.length > 0) {
                    Toaster.warn(`Publication item already contains attachments with name: ${result.existingFileNames}`);
                }

                filteredFiles = result.newFiles;
            }

            const newItemGroups = this.createAndStartUploads(filteredFiles, itemUploadCategory);

            const uploadItemsFiles = [...this.uploadItemFiles()]; // we need to make a copy to trigger signal update

            for (const newItemGroup of newItemGroups) {
                const uploadItemFile = uploadItemsFiles.find(upload =>
                    upload.variant?._id === newItemGroup.variant?._id && upload.uploadCategory === itemUploadCategory &&
                    ((itemUploadCategory === EUploadCategory.FILE && upload.identifier === newItemGroup.identifier) ||
                        itemUploadCategory !== EUploadCategory.FILE));

                if (!uploadItemFile) {
                    uploadItemsFiles.push(newItemGroup);
                } else {
                    uploadItemFile.uploads = newItemGroup.uploads;
                }
            }
            this.uploadItemFiles$.next(uploadItemsFiles);
        }
    }

    /**
     * Groups files together based on filename and starts the validation & upload
     * @param {File[]} files
     * @param {EUploadCategory} uploadCategory
     * @returns {UploadGroupModel[]}
     */
    private createAndStartUploads(files: File[], uploadCategory: EUploadCategory): UploadGroupModel[] {
        const uploadGroups: UploadGroupModel[] = [];

        files.forEach((file) => {
            // Normalize the file name, because it could have another code page (selected files with the file system versus dnd)
            const uploadFile = file.name.normalize();
            let uploadGroup = uploadGroups.find((foundGroup) => foundGroup.identifier === uploadFile &&
                foundGroup.variant?._id === this.activeVariant?._id);
            if (!uploadGroup) {
                uploadGroup = new UploadGroupModel(uploadFile, this.publicationItem(), this.activeVariant, uploadCategory);
                uploadGroups.push(uploadGroup);
            }

            uploadGroup.uploads.push(this.amazonService.createUpload(file));
        });

        return uploadGroups;
    }

    public onSaveButtonClicked(): void {
        this.saveClicked = true;
        const missingUploads= this.updateItemFileTypesConfig().filter((config) => {
            return config.required && !config.newFileUploaded;
        });

        if (missingUploads.length > 0) {
            Toaster.warn(`Missing uploads: ${missingUploads.map((v) => v.title).join(', ')}`);
            return;
        }

        // Map the upload groups to a list of s3Keys, the itemId and variantId (optional)
        const groupedS3Keys$: Observable<KeyItemMapModel>[] =
            this.uploadItemFiles()
                .map((uploadGroup) =>
                    uploadGroup.uploads
                        .map((upload) =>
                            upload.s3Key$.pipe(
                                map((s3Key) =>
                                    new KeyItemMapModel(s3Key, this.publicationItem()._id, this.activeVariant?._id, uploadGroup.uploadCategory)),
                                take(1))
                        )
                )
                .reduce((acc, items) => [...acc, ...items], []);

        if (groupedS3Keys$.length) {
            forkJoin(groupedS3Keys$)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(groupedS3Keys => {
                    const data = {
                        itemIds: [this.publicationItem()._id],
                        uploads: groupedS3Keys
                    };
                    this.handleUploadData(data);
                });
        } else {
            Toaster.warn('No file is uploaded');
            return;
        }
    }

    public getPublicationItem(): void {
        this.publicationsService.getPublicationItem(this.publication._id, this.publicationItemId, EPublicationItemContext.SIDEPANEL)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (publicationItem) => this.publicationItem$.next(publicationItem),
                error: Toaster.handleApiError
            });
    }

    public onDeleteUploadItemClicked(uploadGroup: UploadGroupModel): void {
        // just remove from the array
        const uploadItemFiles = this.uploadItemFiles().filter((uploadItemFile => uploadItemFile !== uploadGroup));
        this.uploadItemFiles$.next(uploadItemFiles);
    }

    protected initFormData(): void {
        this.campaignId = this.customWorkflowService.campaign?._id;

        combineLatest([
            this.customWorkflowService.publication$,
            this.customWorkflowService.workflowConfiguration$,
            this.customWorkflowService.activeStep$,
            this.dataFieldsService.getCampaignItemDataFields()
        ]).pipe(takeUntilDestroyed(this.destroyRef)
        ).subscribe(([publication, workflowConfiguration, step, dataFields]) => {
            this.publication = publication;
            this.workflowConfigurationLayoutId = workflowConfiguration.layout?._id;
            this.stepId = step._id;
            this.dataFields = dataFields;

            this.getPublicationItem();
        });
    }

    protected abstract handleUploadData(result?: IEventData, confirmClose?: boolean);

    private static getSubtitlesAndRequiredFileTypes(publicationItem: PublicationItemModel):
        {exportFileSubtitle: string, exportFileTypes: string[], sourceFileSubtitle: string, sourceFileTypes: string[]} {
        let exportFileSubtitle: string, sourceFileSubtitle: string, exportFileTypes: string[], sourceFileTypes: string[];

        // aligned with backend
        if (!publicationItem.template) {
            exportFileSubtitle = 'To generate a preview. For instance .pdf or .mp4 file.'
            sourceFileSubtitle = 'Open source file. For instance .idml or .ai file.'
        } else if (publicationItem.template.channel === EChannel.DIGITAL && publicationItem.template.engineType === EEngineType.INDESIGN) {
            // indesign digital
            sourceFileSubtitle = 'InDesign .idml file.';
            exportFileSubtitle = 'Exported .png file.';
            sourceFileTypes = [FileTypeUtil.EXTENSIONS.IDML];
            exportFileTypes = [FileTypeUtil.EXTENSIONS.PNG];
        } else if (publicationItem.template.channel === EChannel.PRINT && publicationItem.template.engineType === EEngineType.INDESIGN) {
            // indesign print
            sourceFileSubtitle = 'InDesign .idml file.';
            exportFileSubtitle = 'Exported .pdf file.';
            sourceFileTypes = [FileTypeUtil.EXTENSIONS.IDML];
            exportFileTypes = [FileTypeUtil.EXTENSIONS.PDF];
        } else if (publicationItem.template.channel === EChannel.DIGITAL && publicationItem.template.engineType === EEngineType.SVG) {
            // digital svg
            sourceFileSubtitle = '.svg file.';
            exportFileSubtitle = '.svg file.';
            sourceFileTypes = [FileTypeUtil.EXTENSIONS.SVG];
            exportFileTypes = [FileTypeUtil.EXTENSIONS.SVG];
        } else if (publicationItem.template.channel === EChannel.PRINT && publicationItem.template.engineType === EEngineType.SVG) {
            // print svg
            sourceFileSubtitle = '.svg file.';
            exportFileSubtitle = 'Exported .pdf file';
            sourceFileTypes = [FileTypeUtil.EXTENSIONS.SVG];
            exportFileTypes = [FileTypeUtil.EXTENSIONS.PDF];
        } else if (publicationItem.template.engineType === EEngineType.AFTER_EFFECTS) {
            // after effects
            sourceFileSubtitle = 'After Effects .aep file.'
            exportFileSubtitle = 'Exported .mp4 file'
            sourceFileTypes = [FileTypeUtil.EXTENSIONS.AEP];
            exportFileTypes = [FileTypeUtil.EXTENSIONS.MP4];
        }
        return {exportFileSubtitle, exportFileTypes, sourceFileSubtitle, sourceFileTypes};
    }
}
