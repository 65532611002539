<div class="header" [class.padding-bottom]="!campaignItem?._id">
    <div class="header-grid">
        <div class="left"></div>
        <div class="center"><h1 class="title">{{title}}</h1></div>
        <div class="right">
            @if (pinButtonEnabled() && !isFullscreen()) {
                <nuc-button-secondary
                    [nucTooltip]="isPinned ? 'Unlock panel from linked briefing item' : 'Lock panel with linked briefing item'"
                    [icon]="isPinned ? 'nucicon_lock_fill' : 'nucicon_unlock'"
                    (click)="pinForm()">
                </nuc-button-secondary>
            }
            <nuc-button-secondary icon="nucicon_close" class="close-button" (click)="closeForm(true)">
            </nuc-button-secondary>
        </div>
    </div>
    @if (campaignItem?._id) {
        <nuc-tab-bar *ngIf="tabBarItems?.length > 0"
                     [tabs]="tabBarItems"
                     [(activeTab)]="selectedTab">
        </nuc-tab-bar>
    }
</div>
<div class="content" [class.overlay-active]="!campaignItem?._id">
    @if (!firstLoad && !isLoading) {
            <form [formGroup]="form">
                <ng-container [ngSwitch]="selectedTab.index">
                    <data-fields-information-form
                        *ngSwitchCase="TAB_INFORMATION"
                        [form]="form"
                        [formConfig]="formConfig"
                        [dataFields]="dataFields"
                        [variants]="variants"
                        [readonly]="readonly">
                    </data-fields-information-form>
                    <campaign-item-products-form
                        *ngSwitchCase="TAB_PRODUCTS"
                        [form]="form"
                        [(selectedProducts)]="selectedProducts"
                        [readonly]="readonly">
                    </campaign-item-products-form>
                    <campaign-item-assets-form
                        *ngSwitchCase="TAB_ASSETS"
                        [selectedAssets]="campaignItem?.assets"
                        [selectedProducts]="selectedProducts"
                        [form]="form"
                        [readonly]="readonly">
                    </campaign-item-assets-form>
                    <campaign-item-changelog
                        *ngSwitchCase="TAB_CHANGES"
                        [campaignItem]="campaignItem"
                        [showTimeLine]="true">
                    </campaign-item-changelog>
                </ng-container>
            </form>
        @if (!campaignItem?._id) {
            <div class="opacity-overlay-container">
                <div class="opacity-overlay"></div>
            </div>
            <nuc-empty-state-component [small]="true"
                                       imageUrl="assets/images/empty_states/default.svg"
                                       title="No linked briefing item">
            </nuc-empty-state-component>
        }
    } @else {
        <rl-loading-indicator></rl-loading-indicator>
    }
</div>
<div class="footer">
    <div class="buttons">
        <ng-container *ngFor="let action of actions" [ngSwitch]="action.button.buttonType">
            <nuc-button-primary *ngSwitchCase="buttonTypes.PRIMARY"
                                [loading]="action.button.loading"
                                [icon]="action.button.icon"
                                [text]="action.button.text"
                                [disabled]="action.button.disabled"
                                [nucTooltip]="action.button.tooltip?.text"
                                [nucTooltipPosition]="action.button.tooltip?.position"
                                (click)="buttonClicked(action.observable)">
            </nuc-button-primary>
            <nuc-button-secondary *ngSwitchCase="buttonTypes.SECONDARY"
                                  [loading]="action.button.loading"
                                  [icon]="action.button.icon"
                                  [text]="action.button.text"
                                  [disabled]="action.button.disabled"
                                  [nucTooltip]="action.button.tooltip?.text"
                                  [nucTooltipPosition]="action.button.tooltip?.position"
                                  (click)="buttonClicked(action.observable)"></nuc-button-secondary>
            <nuc-button-destructive *ngSwitchCase="buttonTypes.DESTRUCTIVE"
                                    [loading]="action.button.loading"
                                    [icon]="action.button.icon"
                                    [text]="action.button.text"
                                    [disabled]="action.button.disabled"
                                    [nucTooltip]="action.button.tooltip?.text"
                                    [nucTooltipPosition]="action.button.tooltip?.position"
                                    (click)="buttonClicked(action.observable)"></nuc-button-destructive>
        </ng-container>
    </div>
</div>
