import { Pipe, PipeTransform } from '@angular/core';
import {FileTypeUtil} from '../classes/file-type.util';
import {ARLogger} from '@relayter/core';
import {AppConstants} from '../app.constants';

@Pipe({
    name: 'fileTypeIcon',
    standalone: false
})
export class FileTypeIconPipe implements PipeTransform {

  public transform(fileType: string): string {
      switch (fileType) {
          case FileTypeUtil.EXTENSIONS.IDML:
              return AppConstants.ICONS.IDML;
          case FileTypeUtil.EXTENSIONS.PDF:
              return AppConstants.ICONS.PDF;
          case FileTypeUtil.EXTENSIONS.PNG:
              return AppConstants.ICONS.PNG;
          case FileTypeUtil.EXTENSIONS.SVG:
              return AppConstants.ICONS.SVG;
          case FileTypeUtil.EXTENSIONS.AEP:
              return AppConstants.ICONS.AEP;
          case FileTypeUtil.EXTENSIONS.MP4:
              return AppConstants.ICONS.MP4;
          default:
              ARLogger.warn(`Unknown Filetype: ${fileType}`);
              return null;
      }
  }

}
