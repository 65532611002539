import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataFieldModel} from '../../../../../models/api/data-field.model';
import {EDataFieldTypes} from '../../../../../app.enums';
import {UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'rl-form-field',
    templateUrl: 'form-field.component.html',
    styleUrls: ['form-field.component.scss'],
    standalone: false
})
export class FormFieldComponent {

    @Output() onDeleteButtonClick = new EventEmitter<void>();

    @Input() public formGroup: UntypedFormGroup;
    @Input() public dataField: DataFieldModel;
    @Input() public open: boolean;

    public EDataFieldTypes = EDataFieldTypes;

}
