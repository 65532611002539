import {ApiConstants} from '../api.constant';
import {inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {Observable} from 'rxjs';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {IPatchBodyModel} from '../../models/interfaces/patch-body-model.interface';
import {ApiPostRequest} from '../requests/api-post.request';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';

export enum EWorkflowConfigurationJobType {
    DELETE_WORKFLOW_CONFIGURATION_STEP_JOB = 'DELETE_WORKFLOW_CONFIGURATION_STEP_JOB',
    COPY_WORKFLOW_CONFIGURATION_JOB = 'COPY_WORKFLOW_CONFIGURATION_JOB'
}

export interface IDeleteWorkflowConfigurationStepJobData {
    workflowConfigurationId: string;
    stepId: string;
    destinationStepId: string;
}

export interface ICopyWorkflowConfigurationJobData {
    workflowConfigurationId: string;
}

type IWorkflowConfigurationJobData = IDeleteWorkflowConfigurationStepJobData | ICopyWorkflowConfigurationJobData;

@Injectable({
    providedIn: 'root'
})
export class WorkflowConfigurationsService extends ApiService<WorkflowConfigurationModel> {
    private monitoredJobsService = inject(MonitoredJobsService);

    constructor() {
        super([ApiConstants.API_GROUP_WORKFLOW_CONFIGURATIONS], WorkflowConfigurationModel);
    }

    public createWorkflowConfigurationComponent(workflowConfigurationId: string,
                                                body: IPatchBodyModel,
                                                stepId?: string): Observable<WorkflowConfigurationModel> {
        const request = new ApiPostRequest(
            [...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_COMPONENTS], this.model, body);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.create(request);
    }

    public patchWorkflowConfigurationComponent(workflowConfigurationId: string,
                                               componentId: string,
                                               body: IPatchBodyModel,
                                               stepId?: string): Observable<WorkflowConfigurationModel> {
        const request = new ApiPatchRequest(
            [...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_COMPONENTS],
            this.model, componentId, body);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.patch(request);
    }

    public deleteWorkflowConfigurationComponent(workflowConfigurationId: string,
                                                componentId: string,
                                                stepId?: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_COMPONENTS],
            componentId);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.deleteOne(request);
    }

    public createWorkflowConfigurationStep(workflowConfigurationId: string, body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPostRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_STEPS],
            this.model, body);
        return this.apiRequestService.create(request);
    }

    public patchWorkflowConfigurationStep(workflowConfigurationId: string, stepId: string,
                                          body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPatchRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_STEPS],
            this.model, stepId, body);
        return this.apiRequestService.patch(request);
    }

    public createWorkflowConfigurationTransition(workflowConfigurationId: string, body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPostRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_TRANSITIONS],
            this.model, body);
        return this.apiRequestService.create(request);
    }

    public patchWorkflowConfigurationTransition(workflowConfigurationId: string, transitionId: string,
                                                body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPatchRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_TRANSITIONS],
            this.model, transitionId, body);
        return this.apiRequestService.patch(request);
    }

    public deleteWorkflowConfigurationTransition(workflowConfigurationId: string, transitionId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_TRANSITIONS],
            transitionId);
        return this.apiRequestService.deleteOne(request);
    }

    public createWorkflowConfigurationAction(workflowConfigurationId: string,
                                             body: IPatchBodyModel,
                                             componentId?: string,
                                             stepId?: string): Observable<WorkflowConfigurationModel> {
        const request = new ApiPostRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_ACTIONS],
            this.model, body);
        request.additionalQueryParams.addParam('workflowConfigurationComponentId', componentId);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.create(request);
    }

    public patchWorkflowConfigurationAction(workflowConfigurationId: string,
                                            actionId: string,
                                            body: IPatchBodyModel,
                                            componentId?: string,
                                            stepId?: string): Observable<WorkflowConfigurationModel> {
        const request = new ApiPatchRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_ACTIONS],
            this.model, actionId, body);

        request.additionalQueryParams.addParam('workflowConfigurationComponentId', componentId);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.patch(request);
    }

    public deleteWorkflowConfigurationAction(workflowConfigurationId: string,
                                             actionId: string,
                                             componentId?: string,
                                             stepId?: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_ACTIONS],
            actionId);
        request.additionalQueryParams.addParam('workflowConfigurationComponentId', componentId);
        request.additionalQueryParams.addParam('workflowConfigurationStepId', stepId);
        return this.apiRequestService.deleteOne(request);
    }

    public createWorkflowConfigurationFilter(workflowConfigurationId: string,
                                             body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPostRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_FILTERS],
            this.model, body);
        return this.apiRequestService.create(request);
    }

    public patchWorkflowConfigurationFilter(workflowConfigurationId: string,
                                            filterId: string,
                                            body: IPatchBodyModel): Observable<WorkflowConfigurationModel> {
        const request = new ApiPatchRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_FILTERS],
            this.model, filterId, body);

        return this.apiRequestService.patch(request);
    }

    public deleteWorkflowConfigurationFilter(workflowConfigurationId: string,
                                             filterId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([...this.groups, workflowConfigurationId, ApiConstants.API_METHOD_FILTERS],
            filterId);
        return this.apiRequestService.deleteOne(request);
    }

    public postJob(jobType: EWorkflowConfigurationJobType, jobData: IWorkflowConfigurationJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);

        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }

}
