import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {map} from 'rxjs/operators';
import {DataFieldModel, DataFieldPatchModel} from '../../models/api/data-field.model';
import {DataFieldsService} from '../../api/services/data-fields.service';
import {DataFieldDataTypeEnumerationModel} from '../../models/api/data-field-data-type-enumeration.model';
import {EDataFieldCollectionName, EDataFieldFormatter, EDataFieldTypes, EFormStatus} from '../../app.enums';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {DataFieldDataTypeModel} from '../../models/api/data-field-data-type.model';
import {Toaster} from '../../classes/toaster.class';
import {forkJoin, of} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IDataFieldFormData {
    collectionName: string;
    dataField?: DataFieldModel;
}

interface IDataFieldFormGroup {
    name: FormControl<string>;
    type: FormControl<EDataFieldTypes>;
    formatter: FormControl<boolean>;
    items: FormControl<DropdownItem<string>[]>;
    multiSelect: FormControl<boolean>;
    enableVariants: FormControl<boolean>;
    showInFilter: FormControl<boolean>;
    enableAutocomplete: FormControl<boolean>;
}

@Component({
    selector: 'rl-data-field-form-component',
    templateUrl: 'data-field-form.component.html',
    styleUrls: ['data-field-form.component.scss'],
    standalone: false
})

/**
 * DataFieldFormComponent
 * Form that manages the creation and edit of a data field
 */
export class DataFieldFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);

    public DATA_FIELD_TYPES = EDataFieldTypes;
    public VALIDATOR_MESSAGES = RLValidatorConstants.MESSAGES;

    public dataTypeOptions = [
        new DropdownItem('String', EDataFieldTypes.STRING),
        new DropdownItem('Number', EDataFieldTypes.NUMBER),
        new DropdownItem('Date', EDataFieldTypes.DATE),
        new DropdownItem('Boolean', EDataFieldTypes.BOOLEAN),
        new DropdownItem('Numeration (item list)', EDataFieldTypes.ENUM),
        new DropdownItem('List', EDataFieldTypes.LIST)
    ];

    public saveConfig: ButtonConfig;

    public formGroup: FormGroup<IDataFieldFormGroup>;

    constructor(private dataFieldsService: DataFieldsService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IDataFieldFormData) {
    }

    public ngOnInit(): void {
        this.setupFormGroup();
        this.initForm();
        this.initButtons();
    }

    private setupFormGroup(): void {
        const dataField = this.modalData.dataField;
        if (dataField) {
            const items = dataField.dataType.enumeration?.items
                ? dataField.dataType.enumeration.items.map((item) => new DropdownItem<string>(item, item, true))
                : [];
            this.formGroup = new FormGroup<IDataFieldFormGroup>({
                name: new FormControl(dataField.name, RLValidatorConstants.VALIDATOR_SETS.DATA_FIELD_NAME),
                type: new FormControl({value: dataField.dataType.type, disabled: true}),
                formatter: new FormControl({value: dataField.dataType.formatter === EDataFieldFormatter.CURRENCY, disabled: true}),
                items: new FormControl(items, Validators.required),
                multiSelect: new FormControl({
                    value: dataField.dataType.enumeration?.multiSelect,
                    disabled: true
                }),
                // disable this for existing data field for now
                enableVariants: new FormControl({
                    value: dataField.enableVariants,
                    disabled: dataField.enableVariants || this.modalData.collectionName !== EDataFieldCollectionName.CAMPAIGN_ITEM
                }),
                showInFilter: new FormControl(dataField.showInFilter),
                enableAutocomplete: new FormControl(dataField.enableAutocomplete)
            });
        } else {
            this.formGroup = new FormGroup<IDataFieldFormGroup>({
                name: new FormControl('', RLValidatorConstants.VALIDATOR_SETS.DATA_FIELD_NAME),
                type: new FormControl(this.dataTypeOptions[0].getValue(), Validators.required),
                formatter: new FormControl(false),
                items: new FormControl([], Validators.required),
                multiSelect: new FormControl(false),
                enableVariants: new FormControl({
                    value: false,
                    disabled: this.modalData.collectionName !== EDataFieldCollectionName.CAMPAIGN_ITEM
                }),
                showInFilter: new FormControl(false),
                enableAutocomplete: new FormControl(false)
            });
        }
    }

    private initButtons(): void {
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, this.formGroup.status !== EFormStatus.VALID);
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => {
            this.fullModalService.close(null, true);
        });
        saveAction.observable.subscribe(() => {
            this.saveConfig.loading = true;
            this.modalData.dataField ? this.editDataField() : this.saveDataField();
        });

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);
    }

    private initForm(): void {
        // Disable items conditionally when initialize the form
        if (this.formGroup.controls.type.value !== 'enum') {
            this.formGroup.controls.items.disable();
        }

        this.formGroup.controls.type.valueChanges.subscribe((type) => {
            if (!!type && type === 'enum') {
                // Enables also validation on items
                this.formGroup.controls.items.enable();
            } else {
                // Disables also validation on items
                this.formGroup.controls.items.disable();
            }
        });

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
    }

    public editDataField(): void {
        const newDataFieldName = this.formGroup.value.name;
        const scheduleRenameJob = newDataFieldName !== this.modalData.dataField.name;
        let patchDataField = true;
        const items = this.formGroup.value.items?.map((item: DropdownItem<string>) => item.getValue()) || [];
        const showInFilter = this.formGroup.value.showInFilter;
        const enableVariants = this.formGroup.value.enableVariants;
        const enableAutocomplete = this.formGroup.value.enableAutocomplete;
        let scheduleEnableVariantJob: boolean;

        const patchBody = new DataFieldPatchModel();
        switch (this.modalData.dataField.dataType.type) {
            case EDataFieldTypes.STRING:
            case EDataFieldTypes.NUMBER:
            case EDataFieldTypes.BOOLEAN:
            case EDataFieldTypes.DATE:
            case EDataFieldTypes.LIST:
                patchBody.showInFilter = showInFilter;
                scheduleEnableVariantJob = enableVariants && !this.modalData.dataField.enableVariants;
                patchBody.enableAutocomplete = enableAutocomplete;
                break;
            case EDataFieldTypes.ENUM:
                patchBody.dataType = new DataFieldDataTypeModel();
                patchBody.dataType.enumeration = new DataFieldDataTypeEnumerationModel();
                patchBody.dataType.enumeration.items = items; // currently, only supports items edit
                patchBody.showInFilter = showInFilter;
                scheduleEnableVariantJob = enableVariants && !this.modalData.dataField.enableVariants;
                break;
            default:
                patchDataField = false;
                break;
        }

        // DO NOT do a patch api call when there is nothing to save or schedule
        if (!patchDataField && !scheduleRenameJob && !scheduleEnableVariantJob) {
            this.fullModalService.close();
            return;
        }

        const patch$ = patchDataField
            ? this.dataFieldsService.patchDataField(this.modalData.dataField._id, patchBody)
            : of(null);

        const renameJob$ = scheduleRenameJob
            ? this.dataFieldsService.postJob(AppConstants.JOBS.RENAME_DATA_FIELD_NAME_JOB.name,
                {dataFieldId: this.modalData.dataField._id, newDataFieldName})
            : of(null);

        const enableVariantJob$ = scheduleEnableVariantJob
            ? this.dataFieldsService.postJob(AppConstants.JOBS.ENABLE_VARIANT_DATA_FIELD_JOB.name,
                {dataFieldId: this.modalData.dataField._id})
            : of(null);

        forkJoin([patch$, renameJob$, enableVariantJob$])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result);
                    if (!result[1] && !result[2]) Toaster.success('Data field edited successfully');
                },
                error: error => {
                    this.saveConfig.loading = false;
                    Toaster.handleApiError(error);
                }
            });
    }

    public saveDataField(): void {
        const name = this.formGroup.value.name;
        const items = this.formGroup.value.items?.map((item: DropdownItem<string>) => item.getValue()) || [];
        const type = this.formGroup.value.type;
        const multiSelect = this.formGroup.value.multiSelect;
        const showInFilter = this.formGroup.value.showInFilter;
        const enableVariants = this.formGroup.value.enableVariants;
        const enableAutocomplete = this.formGroup.value.enableAutocomplete;

        const dataField = new DataFieldModel();
        dataField.dataType = new DataFieldDataTypeModel();
        dataField.name = name;
        dataField.dataType.type = type;
        dataField.collectionName = this.modalData.collectionName;

        switch (type) {
            case EDataFieldTypes.STRING:
            case EDataFieldTypes.BOOLEAN:
            case EDataFieldTypes.DATE:
                dataField.showInFilter = showInFilter;
                dataField.enableVariants = enableVariants;
                dataField.enableAutocomplete = enableAutocomplete;
                break;
            case EDataFieldTypes.LIST:
                dataField.showInFilter = showInFilter;
                dataField.enableVariants = enableVariants;
                break;
            case EDataFieldTypes.NUMBER:
                const formatter = this.formGroup.value.formatter ? EDataFieldFormatter.CURRENCY : EDataFieldFormatter.NONE;
                dataField.dataType.formatter = formatter;
                dataField.showInFilter = showInFilter;
                dataField.enableVariants = enableVariants;
                break;
            case EDataFieldTypes.ENUM:
                dataField.dataType.enumeration = new DataFieldDataTypeEnumerationModel();
                dataField.dataType.enumeration.items = items;
                dataField.dataType.enumeration.multiSelect = multiSelect;
                dataField.showInFilter = showInFilter;
                dataField.enableVariants = enableVariants;
                break;
            default:
                // TODO: ERROR
                break;
        }
        this.dataFieldsService.postDataField(dataField)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.fullModalService.close([result]);
                    Toaster.success('Data field created successfully');
                },
                error: (error) => {
                    this.saveConfig.loading = false;
                    Toaster.handleApiError(error);
                }
            });
    }
}
