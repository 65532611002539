import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../api/services/last-viewed-items.service';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: ' last-viewed-items-checkbox',
    templateUrl: './last-viewed-items-checkbox.component.html',
    styleUrls: ['./last-viewed-items-checkbox.component.scss'],
    standalone: false
})
export class LastViewedItemsCheckboxComponent implements OnInit {
    public destroyRef = inject(DestroyRef);
    @Input() public context: ELastViewedItemsContext;
    @Input() public text: string;
    public formControl = new UntypedFormControl();
    public formGroup = new FormGroup({applied: this.formControl});

    constructor(private lastViewedItemsService: LastViewedItemsService) {}

    public ngOnInit(): void {
        this.lastViewedItemsService.getState(this.context)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => this.formControl.patchValue(result?.applied));
    }

    public setApplied(applied: boolean): void {
        this.lastViewedItemsService.setApplied(this.context, applied);
    }
}
