import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MonitoredTransitionsService} from '../../../api/services/monitored-updates/monitored-transitions.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TransitionItemModel} from '../../../models/api/transition-item.model';
import {WorkflowConfigurationTransitionModel, EWorkflowConfigurationTransitionRecipeTaskName}
    from '../../../models/api/workflow-configuration-transition.model';
import {ETransitionType, IndicatorTransitionsModel} from './indicator-transitions.model';
import {WorkflowConfigurationStepModel} from '../../../models/api/workflow-configuration-step.model';
import {filter, map} from 'rxjs/operators';

@Component({
    template: '',
    standalone: false
})
export abstract class IndicatorComponent implements OnInit, OnChanges {
    @Input() public indicatorTransitions: IndicatorTransitionsModel[] = [];
    @Input() public step: WorkflowConfigurationStepModel;
    protected destroyRef = inject(DestroyRef);

    protected typedIndicatorTransition: Partial<Record<ETransitionType, IndicatorTransitionsModel>> = {};
    protected monitoredTransitionsService = inject(MonitoredTransitionsService);
    protected supportedTransitionTypes = [];
    protected transitionProgress: Partial<Record<ETransitionType, number>> = {};
    protected transitionCountOverview: Partial<Record<ETransitionType, string>> = {};

    public ngOnInit(): void {
        this.monitoredTransitionsService.getAllMonitoredItems()
            .pipe(
                map((transitionItems: TransitionItemModel[]) => {
                    return transitionItems.filter(transitionItem => {
                        for (const type in this.typedIndicatorTransition) {
                            if (this.typedIndicatorTransition[type].transitions.find((transition: WorkflowConfigurationTransitionModel) =>
                                transitionItem.transition === transition._id)) {
                                return true;
                            }
                        }
                        return false;
                    });
                }),
                filter(transitionItems => !!transitionItems.length),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((transitionItems: TransitionItemModel[]) => this.updateProgress(transitionItems));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.indicatorTransitions) {
            this.typedIndicatorTransition = this.indicatorTransitions?.filter(indicatorTransition =>
                indicatorTransition.stepId === this.step._id && this.supportedTransitionTypes.includes(indicatorTransition.transitionType))
                .reduce((indicatorTransition, item) => {
                    indicatorTransition[item.transitionType] = item;
                    // Reset progress when there are no longer items for this step and transition type
                    if (item.count === 0 && this.transitionProgress[item.transitionType] !== 0) {
                        this.transitionProgress[item.transitionType] = 0;
                        this.transitionCountOverview[item.transitionType] = '';
                    }
                    return indicatorTransition;
                }, {}) || [];

        }
    }

    /**
     * Update the progress on the transitions
     *
     * @param transitionItems
     * @private
     */
    private updateProgress(transitionItems: TransitionItemModel[]): void {
        for (const transitionType of this.supportedTransitionTypes) {
            const indicatorTransition = this.typedIndicatorTransition[transitionType];

            if (!indicatorTransition || !indicatorTransition.count) return;

            const transitions = indicatorTransition.transitions;

            // find the transitionItems for this transition
            const typedTransitionItems = transitionItems.filter(
                item => transitions.find((transition: WorkflowConfigurationTransitionModel) => transition._id === item.transition));

            if (indicatorTransition.count > 0) {
                this.transitionProgress[transitionType] = this.calculateProgress(typedTransitionItems, indicatorTransition.count);
                this.transitionCountOverview[transitionType] = this.getCountOverviewPerTask(typedTransitionItems);
            }
        }
    }

    /**
     * Calculate the item total progress of all the active transition items.
     * @param transitionItems
     * @param totalItemCount
     * @private
     */
    private calculateProgress(transitionItems: TransitionItemModel[], totalItemCount: number): number {
        let totalProgress = 0;
        for (const transitionItem of transitionItems) {
            if (Object.keys(transitionItem.progress).length) {
                totalProgress += Object.values(transitionItem.progress).reduce((a, b) => a + b, 0) / Object.keys(transitionItem.progress).length;
            }
        }
        return totalItemCount === 0 ? 0 : totalProgress / totalItemCount * 100;
    }

    /**
     * Totals all the transition recipe tasks into a string for the tooltip.
     * @param transitionItems
     * @private
     */
    private getCountOverviewPerTask(transitionItems: TransitionItemModel[]): string {
        const recipeTaskTotals = {};
        for (const transitionItem of transitionItems) {
            for (const key in transitionItem.progress) {
                if (recipeTaskTotals[key]) {
                    recipeTaskTotals[key] += transitionItem.progress[key];
                } else {
                    recipeTaskTotals[key] = transitionItem.progress[key];
                }
            }
        }

        let overview = '';
        for (const key of Object.keys(recipeTaskTotals)) {
            if (overview !== '') overview += ', ';
            overview += `${WorkflowConfigurationTransitionModel.getRecipeTaskProgressTitle(key as EWorkflowConfigurationTransitionRecipeTaskName)}:
                            ${recipeTaskTotals[key]} `;
        }
        return overview;
    }
}
