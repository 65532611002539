import {Pipe, PipeTransform} from '@angular/core';
import {DataFieldModel} from '../../models/api/data-field.model';

@Pipe({
    name: 'getDataFieldValue',
    standalone: false
})
export class GetDataFieldValuePipe implements PipeTransform {
    private static privatePipe = new GetDataFieldValuePipe();

    transform(itemDataFields: Record<string, any>, dataField: DataFieldModel): any {
        return itemDataFields[`${Object.keys(itemDataFields).find((fieldName) => fieldName === dataField.fieldName)}`];
    }

    public static getDataFieldValuePipe(itemDataFields: Record<string, any>, dataField: DataFieldModel): any {
        return GetDataFieldValuePipe.privatePipe.transform(itemDataFields, dataField);
    }
}
