import {Component, Input, OnInit} from '@angular/core';
import {IUploadUpdate, UploadFileComponent} from '../upload-file-component/upload-file.component';

@Component({
    selector: 'upload-asset-component',
    templateUrl: 'upload-asset.component.html',
    styleUrls: ['upload-asset.component.scss'],
    standalone: false
})

export class UploadAssetComponent extends UploadFileComponent implements OnInit {
    @Input() public previousUploadAsset: IUploadUpdate;
}
