import {Pipe, PipeTransform} from '@angular/core';
import {PublicationItemModel} from '../models/api/publication-item.model';

export enum EPublicationDisplayProperties {
    TITLE,
    SUBTITLE,
    THUMBNAIL_URL,
    UPLOAD_DISPLAY_NAME}

@Pipe({
    name: 'publicationItemDisplay',
    standalone: false
})
export class PublicationItemDisplayPipe implements PipeTransform {

    public transform(publicationItem: PublicationItemModel,
                     property: EPublicationDisplayProperties | string,
                     variant?: string,
                     sequentialNumbering = false): string {
        switch (property) {
            case EPublicationDisplayProperties.TITLE:
                return publicationItem.getNavigationItemTitle(sequentialNumbering);
            case EPublicationDisplayProperties.SUBTITLE:
                return publicationItem.getNavigationItemSubtitle(sequentialNumbering);
            case EPublicationDisplayProperties.THUMBNAIL_URL:
                return publicationItem.getThumbnailURL(variant);
            case EPublicationDisplayProperties.UPLOAD_DISPLAY_NAME:
                return publicationItem.getUploadDisplayName(sequentialNumbering);
            default:
                throw new Error(`PublicationItemDisplayPipe: Unhandled property ${property} on publicationItem ${publicationItem}`);
        }
    }
}
