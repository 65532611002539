import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, PRIMARY_OUTLET, RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {ISegmentService, SEGMENT_SERVICE} from '../services/segment/segment.service.interface';

@Injectable({providedIn: 'root'})
export class RelayterPageTitleStrategy extends TitleStrategy {

    constructor(@Inject(SEGMENT_SERVICE) protected segmentService: ISegmentService) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const {pageTitle, data} = this.getTitleAndData(routerState.root);
        document.title = data.length > 0 ? data.join(' - ') : (pageTitle);
        this.segmentService.page(pageTitle); // title from router config, for segment tracking
    };

    public getTitleAndData(snapshot: ActivatedRouteSnapshot): Record<string, any> {
        // most part of the code is from angular this.buildTitle(snapshot: RouterStateSnapshot): string | undefined;
        let pageTitle = 'Relayter';
        let route: ActivatedRouteSnapshot = snapshot;
        let campaignName: string | undefined;
        let publicationName: string | undefined;
        let projectName: string | undefined;
        const data: string[] = [];

        while (route !== undefined) {
            if (route.data?.campaign) campaignName = route.data?.campaign?.name;
            if (route.data?.publication) publicationName = route.data?.publication?.name;
            if (route.data?.project) projectName = route.data?.project?.name;

            pageTitle = this.getResolvedTitleForRoute(route) ?? pageTitle;
            route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
        }

        if (campaignName) data.push(campaignName);
        if (publicationName) data.push(publicationName);
        if (projectName) data.push(projectName);

        return {pageTitle, data};
    }

}
