import {Component, Input} from '@angular/core';
import {IndicatorComponent} from '../indicator/indicator.component';
import {ETransitionType} from '../indicator/indicator-transitions.model';

@Component({
    selector: 'rl-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss'],
    standalone: false
})
export class StepComponent extends IndicatorComponent {
    @Input() public active: boolean = false;
    @Input() public hasNext: boolean = false;
    @Input() public hasPrevious: boolean = false;

    @Input() public hasBackwardsTransition: boolean = false;
    @Input() public hasIncomingBackwardsTransition: boolean = false;
    @Input() public hasFlyByTransition: boolean = false;

    @Input() public itemCount: number;

    protected supportedTransitionTypes = [ETransitionType.SELF];

    public rippleColor = 'rgba(0,0,0,0.03)';
    protected readonly ETransitionType = ETransitionType;
}
