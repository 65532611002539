import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {ECardSize} from '@relayter/rubber-duck';
import {
    EUploadStatus,
    UploadModel
} from '../upload-file-component/upload.model';

@Component({
    selector: 'file-upload-card-view',
    templateUrl: 'file-upload-card-view.component.html',
    styleUrls: ['file-upload-card-view.component.scss'],
    standalone: false
})
export class FileUploadCardViewComponent implements OnChanges, OnDestroy {
    @Input() public uploadFile: UploadModel;
    @Input() public fileIndex = 0;
    @Input() public badgeText: string;
    @Input() public cardSize = ECardSize.MEDIUM;
    @Input() public itemWidth: number;

    @Output() public deleteAssetClicked = new EventEmitter<UploadModel>();

    public readonly DONE = AppConstants.UPLOADED_FILE_STATUS.DONE.name;
    public readonly EUploadStatus = EUploadStatus;

    private defaultCardSize = ECardSize.MEDIUM;

    private onDestroySubject = new Subject<void>();

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.cardSize && changes.cardSize.isFirstChange()) {
            this.defaultCardSize = this.cardSize;
        }

        if (changes.itemWidth) {
            this.cardSize = null;
            if (!this.itemWidth) {
                this.cardSize = this.defaultCardSize;
            }
        }

        if (!this.cardSize) this.cardSize = this.defaultCardSize;
    }
}
