import { Component } from '@angular/core';
import packageInfo from '../../../../package.json';

@Component({
    selector: 'rl-relayter-version',
    templateUrl: './relayter-version.component.html',
    styleUrls: ['./relayter-version.component.scss'],
    standalone: false
})
export class RelayterVersionComponent {
    public relayterVersion = packageInfo.version;
}
